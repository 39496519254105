import React, { useState } from "react";
import gif from "../../assets/SpotAnimation.gif"; // Import the SVG image
import Search from "../../assets/search.svg"; // Import the SVG image
import Job from "../../assets/job.svg"; // Import the SVG image
import Linear from "../../assets/linear.svg"; // Import the SVG image
import AiLogo from "../../assets/AiLogo.svg"; // Import the SVG image
import AiLogo2 from "../../assets/AiLogo2.svg"; // Import the SVG image
import Searchbar from "../../assets/searchbar.svg"; // Import the SVG image
import Searchbar2 from "../../assets/searchbar2.svg"; // Import the SVG image
import spotlogo from "../../assets/spotlogo.svg"; // Import the SVG image
import CreateJd from "../../assets/system-uicons_create.svg"; // Import the SVG image
import UploadJd from "../../assets/hugeicons_file-upload.svg"; // Import the SVG image

function SpotPlus() {
  const [isSearchActive, setIsSearchActive] = useState(false);

  const handleToggle = () => {
    setIsSearchActive(!isSearchActive);
  };
  return (
    <div style={styles.box}>
      <div style={styles.header}>
        <div style={styles.container}>
          {/* SVG Card */}
          <div style={styles.breadmain}>
            <svg
              style={styles.svg}
              viewBox="0 0 165 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.5381 47.4547C40.2072 46.9562 50.325 36.528 50.325 23.7367C50.325 10.9454 40.2072 0.517216 27.5381 0.0186562V0H164.261V20.8883C164.261 35.5708 152.359 47.4734 137.676 47.4734H27.5381V47.4547Z"
                fill="#DDC8FF"
              />
              <rect
                x="0.25"
                y="0.25"
                width="46.9734"
                height="46.9734"
                rx="23.4867"
                fill="url(#paint0_linear_125_1385)"
              />
              <rect
                x="0.25"
                y="0.25"
                width="46.9734"
                height="46.9734"
                rx="23.4867"
                stroke="#DDC8FF"
                strokeWidth="0.5"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_125_1385"
                  x1="4.5"
                  y1="7"
                  x2="41.5"
                  y2="42"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#667EEA" />
                  <stop offset="1" stopColor="#8859B8" />
                </linearGradient>
              </defs>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="6"
              viewBox="0 0 22 6"
              fill="none"
            >
              <path
                d="M0.415372 3L3.15625 5.74088L5.89713 3L3.15625 0.259122L0.415372 3ZM21.0886 3L18.3477 0.259122L15.6069 3L18.3477 5.74088L21.0886 3ZM3.15625 3.47473H5.05519V2.52527H3.15625V3.47473ZM8.85306 3.47473H12.6509V2.52527H8.85306V3.47473ZM16.4488 3.47473H18.3477V2.52527H16.4488V3.47473Z"
                fill="#D9D9D9"
              />
            </svg>
            {/* Centered Text */}
            <div style={styles.text}>Breadcrumb</div>
            {/* SVG Card */}
          </div>
          <div style={styles.breadmain}>
            <svg
              style={styles.svg}
              viewBox="0 0 165 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.5381 47.4547C40.2072 46.9562 50.325 36.528 50.325 23.7367C50.325 10.9454 40.2072 0.517216 27.5381 0.0186562V0H164.261V20.8883C164.261 35.5708 152.359 47.4734 137.676 47.4734H27.5381V47.4547Z"
                fill="rgba(217, 217, 217, 0.45)"
                filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08))"
              />
              <rect
                x="0.25"
                y="0.25"
                width="46.9734"
                height="46.9734"
                rx="23.4867"
                fill="rgba(217, 217, 217, 0.24)"
              />
              <rect
                x="0.25"
                y="0.25"
                width="46.9734"
                height="46.9734"
                rx="23.4867"
                stroke="#DDC8FF"
                strokeWidth="0.5"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_125_1385"
                  x1="4.5"
                  y1="7"
                  x2="41.5"
                  y2="42"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#667EEA" />
                  <stop offset="1" stopColor="#8859B8" />
                </linearGradient>
              </defs>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="6"
              viewBox="0 0 22 6"
              fill="none"
            >
              <path
                d="M0.415372 3L3.15625 5.74088L5.89713 3L3.15625 0.259122L0.415372 3ZM21.0886 3L18.3477 0.259122L15.6069 3L18.3477 5.74088L21.0886 3ZM3.15625 3.47473H5.05519V2.52527H3.15625V3.47473ZM8.85306 3.47473H12.6509V2.52527H8.85306V3.47473ZM16.4488 3.47473H18.3477V2.52527H16.4488V3.47473Z"
                fill="#D9D9D9"
              />
            </svg>
            {/* Centered Text */}
            <div style={styles.text}>Breadcrumb</div>
            {/* SVG Card */}
          </div>
          <div style={styles.breadmain}>
            <svg
              style={styles.svg}
              viewBox="0 0 165 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.5381 47.4547C40.2072 46.9562 50.325 36.528 50.325 23.7367C50.325 10.9454 40.2072 0.517216 27.5381 0.0186562V0H164.261V20.8883C164.261 35.5708 152.359 47.4734 137.676 47.4734H27.5381V47.4547Z"
                fill="rgba(217, 217, 217, 0.45)"
                filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08))"
              />
              <rect
                x="0.25"
                y="0.25"
                width="46.9734"
                height="46.9734"
                rx="23.4867"
                fill="rgba(217, 217, 217, 0.24)"
              />
              <rect
                x="0.25"
                y="0.25"
                width="46.9734"
                height="46.9734"
                rx="23.4867"
                stroke="#DDC8FF"
                strokeWidth="0.5"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_125_1385"
                  x1="4.5"
                  y1="7"
                  x2="41.5"
                  y2="42"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#667EEA" />
                  <stop offset="1" stopColor="#8859B8" />
                </linearGradient>
              </defs>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="6"
              viewBox="0 0 22 6"
              fill="none"
            >
              <path
                d="M0.415372 3L3.15625 5.74088L5.89713 3L3.15625 0.259122L0.415372 3ZM21.0886 3L18.3477 0.259122L15.6069 3L18.3477 5.74088L21.0886 3ZM3.15625 3.47473H5.05519V2.52527H3.15625V3.47473ZM8.85306 3.47473H12.6509V2.52527H8.85306V3.47473ZM16.4488 3.47473H18.3477V2.52527H16.4488V3.47473Z"
                fill="#D9D9D9"
              />
            </svg>

            {/* Centered Text */}
            <div style={styles.text}>Breadcrumb</div>
            {/* SVG Card */}
          </div>
          <div style={styles.breadmain}>
            <svg
              style={styles.svg}
              viewBox="0 0 165 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.5381 47.4547C40.2072 46.9562 50.325 36.528 50.325 23.7367C50.325 10.9454 40.2072 0.517216 27.5381 0.0186562V0H164.261V20.8883C164.261 35.5708 152.359 47.4734 137.676 47.4734H27.5381V47.4547Z"
                fill="rgba(217, 217, 217, 0.45)"
                filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08))"
              />
              <rect
                x="0.25"
                y="0.25"
                width="46.9734"
                height="46.9734"
                rx="23.4867"
                fill="rgba(217, 217, 217, 0.24)"
              />
              <rect
                x="0.25"
                y="0.25"
                width="46.9734"
                height="46.9734"
                rx="23.4867"
                stroke="#DDC8FF"
                strokeWidth="0.5"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_125_1385"
                  x1="4.5"
                  y1="7"
                  x2="41.5"
                  y2="42"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#667EEA" />
                  <stop offset="1" stopColor="#8859B8" />
                </linearGradient>
              </defs>
            </svg>

            {/* Centered Text */}
            <div style={styles.text}>Breadcrumb</div>
            {/* SVG Card */}
          </div>
        </div>
        <div style={styles.leftcontainer}>
          <img src={spotlogo} style={styles.spotlogo} />
        </div>
      </div>
      <div style={styles.rectangle}>
        <div style={styles.internalheader}>
          <h3 style={styles.jobtitle}>Job Title</h3>
          <div style={styles.sidebar}>
            <div>
              <img src={CreateJd} alt="AI Logo" style={styles.createjd} />
            </div>

            <div style={styles.box2}>
              <img src={UploadJd} alt="AI Logo" style={styles.uploadjd} />
            </div>
            <div style={styles.createjdbutton}>Create JD</div>
          </div>
        </div>

        <hr style={styles.hr} />
        <div style={styles.miidlebox}>
          <div style={styles.leftmidbox}>
            {/* Conditionally render the toggle divs */}
            {isSearchActive ? (
              <div className="toggle1" style={styles.toggle}>
                <img
                  src={AiLogo2}
                  style={styles.searchbar}
                  onClick={handleToggle}
                />
                <img src={Searchbar2} style={styles.ailogo} />
              </div>
            ) : (
              <div className="toggle2" style={styles.toggle}>
                <img
                  src={Searchbar}
                  style={styles.searchbar}
                  onClick={handleToggle}
                />
                <img src={AiLogo} style={styles.ailogo} />
              </div>
            )}

            <div style={styles.sidenav}>
              <img src={Search} />
              <img src={Job} />
              <img src={Linear} />
            </div>
          </div>
          <div style={styles.rightmidbox}>
            <img src={gif} style={styles.spotanimationlogo} />
          </div>
        </div>
      </div>
    </div>
  );
}

// Internal CSS
const styles = {
  body: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
  },
  box: {
    height: " 100vh",
    width: "100%",
  },
  header: {
    background: "#000",
    width: "100%",
    // height: "12%",
    display: "flex",
    alignItems: "center",
  },
  //   hr: {
  //     width: "inherit",
  //   },
  rectangle: {
    backgroundColor: "#d9d9d91a",
    border: " 1px solid #d9d9d97a",
    borderRadius: "16px",
    boxShadow: "0px 4px 4px #00000040",
    // height: "80%",
    left: "0",

    top: " 0",
    width: "98%",
    margin: "14px",
  },
  internalheader: {
    // background: "#000",
    width: "100%",
    // height: "12%",
    display: "flex",
    justifyContent: "space-between",
  },
  jobtitle: {
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    width: "50%",
    paddingLeft: "10px",
  },
  miidlebox: {
    // background: "#000",
    // width: "100%",
    // height: "90%",
    // marginTop: "10px",
    display: "flex",
    margin: "2%",
  },
  leftmidbox: {
    backgroundColor: "transparent",

    // width: "10%",
    // height: "92%",
    display: "grid",
    // alignItems: "center",
    justifyContent: "center",
    // position: "absolute",
    gap: "82px",
  },
  sidenav: {
    backgroundColor: "#ffffff1a",
    border: "1px solid #ffffff33",
    borderRadius: "20px",
    width: "65px",
    height: "278px",
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
  },
  rightmidbox: {
    background: "transparent",
    width: "100%",
    height: "92%",
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  },
  toggle: {
    width: "100%",
    // height: "15vh",
    position: "relative",
  },
  icon: {
    width: "65px", // Default size for the big icon
    position: "absolute",
    cursor: "pointer",
  },
  iconHidden: {
    width: "32px", // Size for the small icon
    position: "absolute",
    top: "40px", // Adjust position to match your design
    left: "44px", // Adjust position to match your design
    cursor: "pointer",
  },

  ailogo: {
    width: "65px",
    position: "absolute",
  },
  searchbar: {
    width: "32px",
    position: "absolute",
    top: "40px",
    left: "44px",
    zIndex: "1",
  },
  container: {
    position: "relative",
    display: "inline-block",
    padding: "10px",
    // width: "10%",
    display: "flex",
    width: "50%",
    // gap: "16px",
  },
  leftcontainer: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  svg: {
    width: "100%",
  },
  text: {
    position: "absolute",
    // top: "45%",
    // left: "18%",

    // transform: "translate(-50%, -50%)",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#000",
    pointerEvents: "none", // Prevents text from interfering with SVG interactions
    paddingLeft: "40px",
  },
  breadmain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sidebar: {
    width: "98px",
    height: "73px",
    backgroundColor: "#ffffff1a",
    border: " 1px solid #ffffff33",
    position: "relative",
    borderRadius: "20px",
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
    marginTop: "20px",
    marginBottom: "0px",
    marginRight: "20px",
  },
  sidebarnavigation: {
    width: "98px",
    height: "179px",
    backgroundColor: "#ffffff1a",
    border: " 1px solid #ffffff33",
    borderRadius: "20px",
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
  },
  createjdbutton: {
    backgroundColor: "rgba(255, 255, 255, 1.0)",
    borderTop: " 1px solid rgba(255, 255, 255, 0.4)",

    width: "98px",
    height: "20px",
    borderRadius: "0px 0px 20px 20px",
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    fontWeight: "100",
    color: "#fff",
  },
  createjd: {
    top: "13px",
    position: "absolute",
    left: "28px",
  },
  uploadjd: {},
  main: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
  },
  createjd: {
    top: "13px",
    position: "absolute",
    left: "28px",
  },
  uploadjd: {},
  main: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
  },
  box2: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    border: "1px solid #ffffff7a",

    borderRadius: "18px",
    height: "24px",
    width: "24px",
    position: "absolute",
    top: "-13px",
    left: "81px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  spotanimationlogo: {
    width: "50%",
  },
};

export default SpotPlus;
