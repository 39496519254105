import React, { useEffect, useState } from 'react'
import { API, graphqlOperation, Auth } from "aws-amplify";
import { listCompanyProfiles } from '../../graphql/queries';
import CompanyHome from '../../pages/Company/CompanyHome';
import WhoAreYou from '../../pages/WhoAreYou';
import { useNavigate } from 'react-router-dom';
import CandidateDashboard from '../../pages/CandidateDashboard';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { listCandidateProfiles } from '../../graphql/queries';

const PrivateRoute = () => {
  const [email, setEmail] = useState("")
  const [companyList, setCompanyList] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState({})

  //from below code for candidate


  const [hasScheduledInterview, setHasScheduledInterview] = useState(false);
  const [hasCompletedProfile, setHasCompletedProfile] = useState(false);
  const [userFullName, setUserFullName] = useState("");
  const [candidate, setCandidate] = useState({})
  const navigate = useNavigate()

  // orking code start

  // const [loading, setLoading] = useState(true); // State to track loading status
  const [profilesFetched, setProfilesFetched] = useState(false);
  const [candidateProfiles, setCandidateProfiles] = useState([]);
  const [companyProfiles, setCompanyProfiles] = useState([]);
  const [candidateT_C, setCandidateT_C] = useState(false)
  const [companyT_C, setCompanyT_C] = useState(false)

  const fetchProfiles = async (email) => {

    const userInfo = await Auth.currentAuthenticatedUser();
    const userId = userInfo.attributes.sub;
    const userEmail = userInfo.attributes.email.toLowerCase();
    setEmail(userEmail)

    const fetchCandidateProfiles = async () => {
      let candidateProfileItemsList = [];
      let currentNextToken = null;

      try {
        do {
          const response = await API.graphql(
            graphqlOperation(listCandidateProfiles, {
              filter: { email: { eq: userEmail } },
              // limit: 2500,  if you want limit just uncomment and if want wuth out limit just commonet the limit
              nextToken: currentNextToken,
            })
          );

          console.log("GraphQL response for Candidate Profiles:", response.data.listCandidateProfiles.items);

          // Ensure we're accessing the correct data structure
          const candidateProfilesData = response.data.listCandidateProfiles || {};
          const items = candidateProfilesData.items || [];

          // Set currentNextToken for pagination
          currentNextToken = candidateProfilesData.nextToken;

          // Append the fetched items to the list
          candidateProfileItemsList = [
            ...candidateProfileItemsList,
            ...items,
          ];
        } while (currentNextToken);

        // Set state with the fetched candidate profiles
        setCandidateProfiles(candidateProfileItemsList[0]);
        console.log("Candidate Profiles State Set:", candidateProfileItemsList);
      } catch (error) {
        console.error('Error fetching Candidate Profiles:', error);
      }
    };


    const fetchCompanyProfiles = async () => {
      let companyProfileItemsList = [];
      let currentNextToken = null; // Initialize as needed

      try {
        do {
          const response = await API.graphql(
            graphqlOperation(listCompanyProfiles, {
              filter: {
                email: { eq: userEmail }, // Filter by email
              },
              // limit: 2500,       if you want limit just uncomment and if want wuth out limit just commonet the limit
              nextToken: currentNextToken,
            })
          );

          console.log("GraphQL response for Company Profiles:", response.data.listCompanyProfiles.items);

          // Ensure we're accessing the correct data structure
          const companyProfilesData = response.data.listCompanyProfiles || {};
          const items = companyProfilesData.items || [];

          // Set currentNextToken for pagination
          currentNextToken = companyProfilesData.nextToken;

          // Append the fetched items to the list
          companyProfileItemsList = [
            ...companyProfileItemsList,
            ...items,
          ];
        } while (currentNextToken);

        // Set state with the fetched company profiles
        setCompanyProfiles(companyProfileItemsList[0]);
        console.log("Company Profiles State Set:", companyProfileItemsList);
      } catch (error) {
        console.error('Error fetching Company Profiles:', error);
      }
    };

    // Fetch both profiles in parallel
    try {
      setLoading(true); // Set loading to true when starting to fetch
      await Promise.all([fetchCandidateProfiles(), fetchCompanyProfiles()]);
      // console.log("All profiles fetched successfully.");
      setProfilesFetched(true); // Set to true when fetching is done
    } catch (error) {
      console.error('Error fetching profiles:', error);
    } finally {
      setLoading(false); // Set loading to false when fetching is complete
    }
  };

  useEffect(() => {


    fetchProfiles('example@example.com'); // Replace with the actual email
  }, []); // Empty dependency array to run once when the component mounts

  // testing code end

  // console.log("vdajshvdjahsvdjhasvda",candidateProfiles.t_c)
  // console.log("vdajshvdjahsvdjhasvda", email)
  // console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", candidateProfiles)
  // console.log("bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb", companyProfiles)

  if (loading) {
    // return <div>Loading...</div>; // You can customize this loading indicator
    return <></>
  }

  // if (fetchError) {
  //   return <div>{fetchError}</div>; // Display error message if any
  // }

  if (candidateProfiles  &&  candidateProfiles.email !== "hr@varahitechnologies.com") {
    return <CandidateDashboard />; // Render CandidateHome if candidate profiles exist
  }

  if (companyProfiles) {
    return <CompanyHome />; // Render CompanyHome if company profiles exist
  }

  if (email && !candidateProfiles && !companyProfiles) {
    return <WhoAreYou />;
  }

  // return <WhoAreYou />; // Render WhoAreYou if neither profile exists
  return null;

}

export default PrivateRoute
