import React from 'react';
import SpotAnimation from "../../assets/SpotAnimation.gif";
import { useNavigate } from 'react-router-dom';


function SpotComingSoon() {

    const navigate = useNavigate();

    return (
        <div className="container-fluid" style={{ backgroundColor: '#1A1934', minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ padding: '50px' }}>
                {/* <h1 className="coming-soon" style={{ color: '#fff', textAlign: 'center', marginBottom: '51px', fontSize: '62px' }}>Coming Soon</h1> */}
                <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="col-lg-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> {/* Added flex container for centering */}
                        <div className="left-section" style={{ backgroundColor: '#101222', color: '#fff', padding: '20px', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                            <div
                                style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}
                                onClick={() => navigate('/')}
                                className='cursor-pointer'
                            >
                                X
                            </div>
                            <img src={SpotAnimation} alt="AI Interview Bot Logo" className="logo" style={{ maxWidth: '100%', height: 'auto', display: 'block', margin: '0 auto' }} />
                            {/* <h1 style={{ textAlign: 'center' }}>SPOT</h1> */}
                            <p style={{ textAlign: 'center' }}>
                                SPOT is an AI-powered interview bot designed to streamline your hiring process. It automates candidate screening, saving you time and resources, while ensuring a fair and consistent evaluation process.
                            </p>
                            <h1 style={{ textAlign: 'center' }}>What is SPOT?</h1>
                            <p style={{ textAlign: 'center' }}>
                                <b>S</b>creening: SPOT automates candidate screening, saving you time and resources.<br />
                                <b>P</b>redictive: SPOT uses AI to predict candidate success, ensuring a better fit for your team.<br />
                                <b>O</b>bjective: SPOT provides a fair and consistent evaluation process for all candidates.<br />
                                <b>T</b>ransformative: SPOT is a transformative solution that streamlines your hiring process from start to finish.
                            </p>
                            <p style={{ textAlign: 'center' }}>Stay tuned for the launch!</p>

                            <h1 className="coming-soon" style={{ color: '#fff', textAlign: 'center', marginBottom: '51px', fontSize: '62px' }}>Coming Soon...</h1>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SpotComingSoon;