import React, { useContext, useState } from "react";
import carbonCloseOutline from "../../assets/carbon_close-outline.svg";
import informationCircleOutlineSvgrepoCom1 from "../../assets/information-circle-outline-svgrepo-com 1.svg";
import line6 from "../../assets/Line 6.svg";
import checkbox from "../../assets/checkbox.svg";
import select from "../../assets/checkbox-select.svg";
import { SpotContext } from "../../context/SpotContext";

const dummyJdData = [
  {
    id: 1,
    jdName: "Software Engineer",
    experience: "3-5 years",
    workCategory: "Remote",
    summary:
      "Experienced software engineer with expertise in JavaScript, React, Node.js, and AWS.",
    skills: ["JavaScript", "React", "Node.js", "AWS"],
    total: 10,
    resumeBase: 3,
    filterCandidate: 7,
  },
  {
    id: 2,
    jdName: "Data Scientist",
    experience: "2+ years",
    workCategory: "On-site",
    summary:
      "Data scientist proficient in Python, Machine Learning algorithms, and various data analysis techniques.",
    skills: ["Python", "Machine Learning", "Data Analysis"],
    total: 5,
    resumeBase: 2,
    filterCandidate: 3,
  },
  {
    id: 3,
    jdName: "DevOps Engineer",
    experience: "4+ years",
    workCategory: "Hybrid", // Added "Hybrid" work category
    summary:
      "DevOps engineer with experience in CI/CD pipelines, Kubernetes, and cloud infrastructure management (AWS, Azure, GCP).", // Expanded summary
    skills: ["CI/CD", "Kubernetes", "Docker", "AWS", "Azure", "GCP"], // Added more skills
    total: 12,
    resumeBase: 5,
    filterCandidate: 7,
  },
  {
    id: 4,
    jdName: "UI/UX Designer",
    experience: "2-3 years",
    workCategory: "Remote",
    summary:
      "UI/UX Designer with a strong portfolio and experience in creating user-centered designs using Figma, Sketch, and Adobe Creative Suite.  Proficient in user research and usability testing.", // More detailed summary
    skills: [
      "Figma",
      "Sketch",
      "Adobe Creative Suite",
      "User Research",
      "Usability Testing",
    ], // Added a skill
    total: 8,
    resumeBase: 2,
    filterCandidate: 6,
  },
  {
    id: 5,
    jdName: "Project Manager",
    experience: "5+ years",
    workCategory: "On-site",
    summary:
      "Experienced project manager with a proven track record of successfully delivering projects on time and within budget using Agile methodologies.",
    skills: ["Agile", "Scrum", "Project Planning", "Risk Management"],
    total: 15,
    resumeBase: 7,
    filterCandidate: 8,
  },
];

const styles = {
  card: {
    backgroundColor: "#ddc8ff",
    borderRadius: "32px 12px 12px 12px",
    // height: "334px",
    width: "430px",
    padding: "16px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", // Added box shadow
    position: "relative", // To position the close button
    marginBottom: "8px",
  },
  title: {
    background:
      "linear-gradient(180deg, rgb(35, 29, 56) 0%, rgb(80, 61, 117) 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    color: "transparent",
    fontFamily: "Roboto-Medium, Helvetica",
    fontSize: "20px",
    fontWeight: 500,
    marginBottom: "16px",
  },
  subtitle: {
    background:
      "linear-gradient(180deg, rgb(35, 29, 56) 0%, rgb(80, 61, 117) 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    color: "transparent",
    fontFamily: "Roboto-Regular, Helvetica",
    fontSize: "1rem",
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },
  remote: {
    background:
      "linear-gradient(180deg, rgb(35, 29, 56) 0%, rgb(80, 61, 117) 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    color: "transparent",
    fontFamily: "Roboto-Regular, Helvetica",
    fontSize: "1rem",
    fontWeight: 400,
  },
  description: {
    background:
      "linear-gradient(180deg, rgb(35, 29, 56) 0%, rgb(80, 61, 117) 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    color: "transparent",
    fontFamily: "Roboto-Regular, Helvetica",
    fontSize: "14px",
    fontWeight: 400,
    marginBottom: "16px",
  },
  skillsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    marginBottom: "16px",
  },
  skill: {
    backgroundColor: "#ffffff47",
    borderRadius: "2.04px",
    padding: "4px 8px",
    fontFamily: "Roboto-Regular, Helvetica",
    fontSize: "12px",
    fontWeight: 400,
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    color: "transparent",
  },
  sourceContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
    justifyContent: "sourceContainer",
    justifyContent: "space-between",
  },
  source: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ffffff47",
    borderRadius: "4px",
    justifyContent: "center",
    padding: "0px 4px",
  },
  sourceTitle: {
    background:
      "linear-gradient(180deg, rgb(35, 29, 56) 0%, rgb(80, 61, 117) 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    color: "transparent",
    fontFamily: "Roboto-Light, Helvetica",
    fontSize: "16px",
    fontWeight: 300,
    marginRight: "8px",
  },
  sourceCount: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    borderRadius: "2.25px",
    padding: "2px 8px",
    fontFamily: "Roboto-Light, Helvetica",
    fontSize: "9px",
    fontWeight: 300,
    color: "#ffffff",
  },
  totalContainer: {
    backgroundColor: "#ffffff47",
    borderRadius: "4px",
    padding: "4px 8px",
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
  },
  totalTitle: {
    background:
      "linear-gradient(180deg, rgb(35, 29, 56) 0%, rgb(80, 61, 117) 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    color: "transparent",
    fontFamily: "Roboto-Regular, Helvetica",
    fontSize: "16px",
    fontWeight: 400,
    marginBottom: "4px",
    textAlign: "center",
  },
  totalCount: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    borderRadius: "2px",
    padding: "4px 20px",
    fontFamily: "Roboto-Light, Helvetica",
    fontSize: "16px",
    fontWeight: 300,
    color: "#ffffff",
  },
  closeButton: {
    position: "absolute",
    top: "16px",
    right: "16px",
    cursor: "pointer",
  },
  line: {
    height: "6px",
    width: "6px",
    objectFit: "cover",
    marginLeft: "8px",
  },
  button: {
    marginTop: "16px",
    padding: "12px",
    backgroundColor: "#6A0DAD",
    color: "white",
    border: "none",
    borderRadius: "10px",
    cursor: "pointer",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.3s",
  },
  buttonHover: {
    backgroundColor: "#5b0ca2",
  },
  checkbox: {
    marginLeft: "8px",
    backgroundColor: "transparent",
    border: "1px solid #fff",
    borderRadius: "4px",
  },
  details: {
    display: "flex",
    gap: "8px",
  },
  detailsSecound: {
    display: "grid",
    gap: "4px",
  },
  jdinfo: {
    display: "flex",
    gap: "8px",
  },
  selectedCard: {
    border: "2px solid #000", // Or any other highlighting style
  },
};

function SpotPlusJd() {
  const [hover, setHover] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [jds, setJds] = useState(dummyJdData); // Store JDs in state
  const [selectedJdId, setSelectedJdId] = useState(null); // State to store selected JD ID
  // spot ai code start

  const {
    companyjdlist,
    setCompanyjdlist,
    setSelectedjdid,
    selectedjdid,
    setSelectedjdname,
  } = useContext(SpotContext);

  // spot ai code end

  const handleCheckboxClick = () => {
    setIsChecked(!isChecked);
  };
  const handleClose = (jdId) => {
    // Filter out the JD with the matching ID
    const updatedJds = jds.filter((jd) => jd.id !== jdId);
    setJds(updatedJds);
    // Deselect the JD if it was the selected one
    if (selectedJdId === jdId) {
      setSelectedJdId(null);
    }
  };

  const handleJdClick = (jdId, jdName) => {
    // setSelectedJdId(jdId); // Update the selected JD ID
    // console.log(jdName);
    setSelectedjdid(jdId);
    setSelectedjdname(jdName);
  };

  console.log("::::::::::::::::::::", selectedjdid);

  return (
    <div>
      {companyjdlist.map((jd) => (
        <div
          key={jd.id}
          style={{
            ...styles.card,
            ...(selectedJdId === jd.id && styles.selectedCard),
          }} // Apply selected style
          onClick={() => handleJdClick(jd.id, jd.jdName)} // Call handleJdClick on click
        >
          <img
            src={carbonCloseOutline}
            alt="Close"
            style={styles.closeButton}
            onClick={(e) => {
              e.stopPropagation(); // Prevent click from propagating to card
              handleClose(jd.id);
            }}
          />
          <h2 style={styles.title}>{jd.jdName}</h2> {/* Use jdName */}
          <div style={styles.jdinfo}>
            <div style={styles.subtitle}>
              {jd.experience} experience required
              <img src={line6} alt="Line" style={styles.line} />
            </div>
            <div style={styles.remote}>{jd.workCategory}</div>{" "}
            {/* Use workCategory */}
          </div>
          <p style={styles.description}>{jd.summary}</p> {/* Use summary */}
          {/* <div style={styles.skillsContainer}>
            {jd.skills.map((skill, index) => (
              <span key={index} style={styles.skill}>
                {skill}
              </span>
            ))}
          </div> */}
          <div style={styles.sourceContainer}>
            <div style={styles.details}>
              <div style={styles.totalContainer}>
                <span style={styles.totalTitle}>Total</span>
                <span style={styles.totalCount}>{jd.total}</span>
              </div>
              <div style={styles.detailsSecound}>
                <div style={styles.source}>
                  <span style={styles.sourceTitle}>Resume Base</span>
                  <span style={styles.sourceCount}>{jd.resumeBase}</span>
                </div>
                <div style={styles.source}>
                  <span style={styles.sourceTitle}>Our Data Base</span>
                  <span style={styles.sourceCount}>
                    {/* {jd.filterCandidate} */}
                  </span>{" "}
                </div>
              </div>
            </div>

            <img
              src={informationCircleOutlineSvgrepoCom1}
              alt="Info"
              style={{ height: "24px", width: "24px" }}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default SpotPlusJd;
