import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import gif from "../../assets/SpotAnimation.gif"; // Import the SVG image
import Search from "../../assets/search.svg"; // Import the SVG image
import Job from "../../assets/job.svg"; // Import the SVG image
import Linear from "../../assets/linear.svg"; // Import the SVG image
import AiLogo from "../../assets/AiLogo.svg"; // Import the SVG image
import AiLogo2 from "../../assets/AiLogo2.svg"; // Import the SVG image
import Searchbar from "../../assets/searchbar.svg"; // Import the SVG image
import Searchbar2 from "../../assets/searchbar2.svg"; // Import the SVG image
import spotlogo from "../../assets/spotlogo.svg"; // Import the SVG image
import CreateJd from "../../assets/system-uicons_create.svg"; // Import the SVG image
import UploadJd from "../../assets/hugeicons_file-upload.svg"; // Import the SVG image

import JdPanal from "../../assets/Jd panal.svg"; // Import the SpotPlusCard component
import SpotPlusJd from "./SpotPlusJd"; // Import the SpotPlusJd component

import SpotPlusCard from "../Company/SpotPlusCard";
function SpotPlusStudentDetails() {
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [selectedWorkTypes, setSelectedWorkTypes] = useState([]);
  const [cardsToShow, setCardsToShow] = useState(6);
  const [isJdPanelOpen, setIsJdPanelOpen] = useState(false);
  const boxRef = useRef(null);
  // State to manage the currently displayed card
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  const [activeTab, setActiveTab] = useState("Video"); // New state for active tab
  const [showSelectedCard, setShowSelectedCard] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  // ... other functions

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const cards = [
    { id: 1 /* ... other card data ... */ },
    { id: 2 /* ... other card data ... */ },
    { id: 3 /* ... other card data ... */ },
    { id: 4 /* ... other card data ... */ },
    // ... more card data ...
  ];

  // ... other functions

  // Function to handle card selection
  // const handleCardSelect = (cardId) => {
  //   // Update the currentCardIndex to the selected card's index
  //   const selectedIndex = cards.findIndex((card) => card.id === cardId);
  //   setCurrentCardIndex(selectedIndex);
  // };
  // Function to handle "Next" button click
  // const handleNextClick = () => {
  //   // If we're not at the last card, increment the index
  //   if (currentCardIndex < cardsToShow - 1) {
  //     setCurrentCardIndex(currentCardIndex + 1);
  //   } else {
  //     // If we're at the last card, show the selected card view
  //     setShowSelectedCard(true);
  //   }
  // };
  // Function to handle "Next" button click

  const handleToggle = () => {
    setIsSearchActive(!isSearchActive);
  };

  const handleWorkTypeClick = (workType) => {
    setSelectedWorkTypes((prevTypes) => {
      if (prevTypes.includes(workType)) {
        return prevTypes.filter((type) => type !== workType);
      } else {
        return [...prevTypes, workType];
      }
    });
  };

  const handleFilterChange = (numCards) => {
    setCardsToShow(numCards);
  };
  const handleJdPanelToggle = () => {
    setIsJdPanelOpen(!isJdPanelOpen);

    if (boxRef.current) {
      boxRef.current.style.width = isJdPanelOpen ? "100%" : "100%"; // Adjust width based on panel state
    }
  };

  // Function to handle "Send Mail" button click
  const handleSendMailClick = () => {
    // Add your send mail logic here
    console.log("Send mail for card:", cards[currentCardIndex]);
  };

  const navigate = useNavigate(); // Initialize useNavigate

  const handleIconClick = (route) => {
    navigate(route);
  };

  return (
    <div style={styles.mainContainer}>
      <div
        ref={boxRef}
        style={{
          ...styles.box,
          width: isJdPanelOpen ? "100%" : "100%",
          transition: "width 0.3s ease",
        }}
      >
        {" "}
        {/* Add position: relative */}
        {/* ... (Your existing content within the box div) */}
        {/* Button on the right side, middle */}
        {/* <img src={JdPanal} style={styles.rightButton} /> */}
        <div style={styles.header}>
          <div style={styles.container}>
            {/* SVG Card */}
            <div style={styles.breadmain}>
              <svg
                style={styles.svg}
                viewBox="0 0 165 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M27.5381 47.4547C40.2072 46.9562 50.325 36.528 50.325 23.7367C50.325 10.9454 40.2072 0.517216 27.5381 0.0186562V0H164.261V20.8883C164.261 35.5708 152.359 47.4734 137.676 47.4734H27.5381V47.4547Z"
                  fill="#DDC8FF"
                />
                <rect
                  x="0.25"
                  y="0.25"
                  width="46.9734"
                  height="46.9734"
                  rx="23.4867"
                  fill="url(#paint0_linear_125_1385)"
                />
                <rect
                  x="0.25"
                  y="0.25"
                  width="46.9734"
                  height="46.9734"
                  rx="23.4867"
                  stroke="#DDC8FF"
                  strokeWidth="0.5"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_125_1385"
                    x1="4.5"
                    y1="7"
                    x2="41.5"
                    y2="42"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#667EEA" />
                    <stop offset="1" stopColor="#8859B8" />
                  </linearGradient>
                </defs>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="6"
                viewBox="0 0 22 6"
                fill="none"
              >
                <path
                  d="M0.415372 3L3.15625 5.74088L5.89713 3L3.15625 0.259122L0.415372 3ZM21.0886 3L18.3477 0.259122L15.6069 3L18.3477 5.74088L21.0886 3ZM3.15625 3.47473H5.05519V2.52527H3.15625V3.47473ZM8.85306 3.47473H12.6509V2.52527H8.85306V3.47473ZM16.4488 3.47473H18.3477V2.52527H16.4488V3.47473Z"
                  fill="#D9D9D9"
                />
              </svg>
              {/* Centered Text */}
              <div style={styles.text}>Breadcrumb</div>
              {/* SVG Card */}
            </div>
            <div style={styles.breadmain}>
              <svg
                style={styles.svg}
                viewBox="0 0 165 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M27.5381 47.4547C40.2072 46.9562 50.325 36.528 50.325 23.7367C50.325 10.9454 40.2072 0.517216 27.5381 0.0186562V0H164.261V20.8883C164.261 35.5708 152.359 47.4734 137.676 47.4734H27.5381V47.4547Z"
                  fill="rgba(217, 217, 217, 0.45)"
                  filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08))"
                />
                <rect
                  x="0.25"
                  y="0.25"
                  width="46.9734"
                  height="46.9734"
                  rx="23.4867"
                  fill="rgba(217, 217, 217, 0.24)"
                />
                <rect
                  x="0.25"
                  y="0.25"
                  width="46.9734"
                  height="46.9734"
                  rx="23.4867"
                  stroke="#DDC8FF"
                  strokeWidth="0.5"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_125_1385"
                    x1="4.5"
                    y1="7"
                    x2="41.5"
                    y2="42"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#667EEA" />
                    <stop offset="1" stopColor="#8859B8" />
                  </linearGradient>
                </defs>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="6"
                viewBox="0 0 22 6"
                fill="none"
              >
                <path
                  d="M0.415372 3L3.15625 5.74088L5.89713 3L3.15625 0.259122L0.415372 3ZM21.0886 3L18.3477 0.259122L15.6069 3L18.3477 5.74088L21.0886 3ZM3.15625 3.47473H5.05519V2.52527H3.15625V3.47473ZM8.85306 3.47473H12.6509V2.52527H8.85306V3.47473ZM16.4488 3.47473H18.3477V2.52527H16.4488V3.47473Z"
                  fill="#D9D9D9"
                />
              </svg>
              {/* Centered Text */}
              <div style={styles.text}>Breadcrumb</div>
              {/* SVG Card */}
            </div>
            <div style={styles.breadmain}>
              <svg
                style={styles.svg}
                viewBox="0 0 165 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M27.5381 47.4547C40.2072 46.9562 50.325 36.528 50.325 23.7367C50.325 10.9454 40.2072 0.517216 27.5381 0.0186562V0H164.261V20.8883C164.261 35.5708 152.359 47.4734 137.676 47.4734H27.5381V47.4547Z"
                  fill="rgba(217, 217, 217, 0.45)"
                  filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08))"
                />
                <rect
                  x="0.25"
                  y="0.25"
                  width="46.9734"
                  height="46.9734"
                  rx="23.4867"
                  fill="rgba(217, 217, 217, 0.24)"
                />
                <rect
                  x="0.25"
                  y="0.25"
                  width="46.9734"
                  height="46.9734"
                  rx="23.4867"
                  stroke="#DDC8FF"
                  strokeWidth="0.5"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_125_1385"
                    x1="4.5"
                    y1="7"
                    x2="41.5"
                    y2="42"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#667EEA" />
                    <stop offset="1" stopColor="#8859B8" />
                  </linearGradient>
                </defs>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="6"
                viewBox="0 0 22 6"
                fill="none"
              >
                <path
                  d="M0.415372 3L3.15625 5.74088L5.89713 3L3.15625 0.259122L0.415372 3ZM21.0886 3L18.3477 0.259122L15.6069 3L18.3477 5.74088L21.0886 3ZM3.15625 3.47473H5.05519V2.52527H3.15625V3.47473ZM8.85306 3.47473H12.6509V2.52527H8.85306V3.47473ZM16.4488 3.47473H18.3477V2.52527H16.4488V3.47473Z"
                  fill="#D9D9D9"
                />
              </svg>

              {/* Centered Text */}
              <div style={styles.text}>Breadcrumb</div>
              {/* SVG Card */}
            </div>
            <div style={styles.breadmain}>
              <svg
                style={styles.svg}
                viewBox="0 0 165 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M27.5381 47.4547C40.2072 46.9562 50.325 36.528 50.325 23.7367C50.325 10.9454 40.2072 0.517216 27.5381 0.0186562V0H164.261V20.8883C164.261 35.5708 152.359 47.4734 137.676 47.4734H27.5381V47.4547Z"
                  fill="rgba(217, 217, 217, 0.45)"
                  filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08))"
                />
                <rect
                  x="0.25"
                  y="0.25"
                  width="46.9734"
                  height="46.9734"
                  rx="23.4867"
                  fill="rgba(217, 217, 217, 0.24)"
                />
                <rect
                  x="0.25"
                  y="0.25"
                  width="46.9734"
                  height="46.9734"
                  rx="23.4867"
                  stroke="#DDC8FF"
                  strokeWidth="0.5"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_125_1385"
                    x1="4.5"
                    y1="7"
                    x2="41.5"
                    y2="42"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#667EEA" />
                    <stop offset="1" stopColor="#8859B8" />
                  </linearGradient>
                </defs>
              </svg>

              {/* Centered Text */}
              <div style={styles.text}>Breadcrumb</div>
              {/* SVG Card */}
            </div>
          </div>
          <div style={styles.leftcontainer}>
            <img src={spotlogo} style={styles.spotlogo} />
          </div>
        </div>
        <div style={styles.rectanglesplit}>
          <div style={styles.rectangle}>
            <div style={styles.internalheader}>
              <h3 style={styles.jobtitle}>Job Title</h3>
              <div style={styles.togglediv}>
                <div
                  style={
                    activeTab === "Video"
                      ? {
                          ...styles.toggleinterviewdetailsof,
                          ...styles.activeTab,
                        }
                      : styles.toggleinterviewdetailsof
                  }
                  onClick={() => handleTabClick("Video")}
                >
                  Video
                </div>
                <div
                  style={
                    activeTab === "Transcript"
                      ? {
                          ...styles.toggleinterviewdetailsof,
                          ...styles.activeTab,
                        }
                      : styles.toggleinterviewdetailsof
                  }
                  onClick={() => handleTabClick("Transcript")}
                >
                  Transcript
                </div>
                <div
                  style={
                    activeTab === "Evolution"
                      ? {
                          ...styles.toggleinterviewdetailsof,
                          ...styles.activeTab,
                        }
                      : styles.toggleinterviewdetailsof
                  }
                  onClick={() => handleTabClick("Evolution")}
                >
                  Evolution
                </div>
              </div>
            </div>

            <hr style={styles.hr} />
            <div style={styles.miidlebox}>
              <div style={styles.leftmidbox}>
                {/* Conditionally render the toggle divs */}
                {isSearchActive ? (
                  <div className="toggle1" style={styles.toggle}>
                    <img
                      src={AiLogo2}
                      style={styles.searchbar}
                      onClick={handleToggle}
                    />
                    <img src={Searchbar2} style={styles.ailogo} />
                  </div>
                ) : (
                  <div className="toggle2" style={styles.toggle}>
                    <img
                      src={Searchbar}
                      style={styles.searchbar}
                      onClick={handleToggle}
                    />
                    <img src={AiLogo} style={styles.ailogo} />
                  </div>
                )}

                <div style={styles.sidenav}>
                  <img
                    src={Search}
                    onClick={() => handleIconClick("/SpotPlusCampain")}
                    alt="Search"
                  />{" "}
                  {/* Add onClick and alt */}
                  <img
                    src={Job}
                    onClick={() => handleIconClick("/job")}
                    alt="Job"
                  />{" "}
                  {/* Add onClick and alt */}
                  <img
                    src={Linear}
                    onClick={() => handleIconClick("/linear")}
                    alt="Linear"
                  />{" "}
                  {/* Add onClick and alt */}
                </div>
              </div>
              <div style={styles.rightmidbox}>
                <div style={styles.cardstructure}>
                  {showSelectedCard ? ( // Show details if a card is selected
                    <div>
                      {activeTab === "Video" && (
                        <div style={styles.interviewvideo}>
                          {selectedCard && (
                            <video src={selectedCard.videoUrl} controls />
                          )}{" "}
                          {/* Conditionally render video */}
                        </div>
                      )}
                      {activeTab === "Transcript" && (
                        <div style={styles.transcript}>
                          {selectedCard && <p>{selectedCard.transcript}</p>}{" "}
                          {/* Conditionally render transcript */}
                        </div>
                      )}
                      {activeTab === "Evolution" && (
                        <div style={styles.evolution}>
                          {/* Conditionally render evolution content.  Adapt as needed. */}
                          {selectedCard && <p>{selectedCard.evolution}</p>}
                        </div>
                      )}
                    </div>
                  ) : (
                    // Show card list if no card is selected
                    cards.slice(0, cardsToShow).map((card) => (
                      // <SpotPlusCard
                      //     key={card.id}
                      //     card={card}
                      //     onClick={() => handleCardClick(card)}
                      // />
                      <div></div>
                    ))
                  )}
                </div>
                {/* ... other JSX ... */}
              </div>
            </div>
            {/* JD Card Panel */}
            <div
              style={{
                ...styles.jdPanel,
                width: isJdPanelOpen ? "43%" : "0%",
                transition: "width 0.3s ease",
              }}
            >
              {/* ... (Your JD card content here) */}
              {/* Import and render the SpotPlusJd component */}
              <SpotPlusJd />
              {/* ... (Add your JD card details) */}
            </div>
          </div>
          {/* Button to toggle JD Panel */}
        </div>
      </div>
    </div>
  );
}

// Internal CSS
const styles = {
  activeTab: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    color: "#fff",
  },
  rectanglesplit: {
    display: "flex",
  },
  // Main container to hold both box and JD panel
  mainContainer: {
    display: "flex",
    height: "100vh",
  },

  // JD Card Panel styles
  jdPanel: {
    backgroundColor: "#f0f0f0", // Example background color
    overflow: "hidden", // Hide content when panel is closed
    position: "relative", // Allow absolute positioning of children
    display: "flex",
    justifyContent: "center",
    paddingTop: "16px",
    // left: "32px",
  },
  body: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
  },
  box: {
    height: " 100vh",
    width: "100%",
    // Add position: relative to allow absolute positioning of the button
    position: "relative",
  },

  // Style for the right-side button
  rightButton: {
    position: "absolute",
    top: "50%", // Position at 50% from the top
    // right: "-16px",
    // Adjust distance from the right edge as needed
    transform: "translateY(-50%) rotate(0deg)", // Add rotation
    padding: "10px 20px",

    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    zIndex: "1",
  },
  header: {
    background: "#000",
    width: "100%",
    // height: "12%",
    display: "flex",
    alignItems: "center",
  },
  //   hr: {
  //     width: "inherit",
  //   },
  rectangle: {
    backgroundColor: "#d9d9d91a",
    border: " 1px solid #d9d9d97a",
    borderRadius: "16px",
    boxShadow: "0px 4px 4px #00000040",
    // height: "80%",
    left: "0",

    top: " 0",
    width: "98%",
    margin: "14px",
    height: "80vh",
  },
  internalheader: {
    // background: "#000",
    width: "100%",
    // height: "12%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  jobtitle: {
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    // width: "50%",
    paddingLeft: "10px",
  },
  miidlebox: {
    // background: "#000",
    // width: "100%",
    // height: "90%",
    // marginTop: "10px",
    display: "flex",
    margin: "1% 2%",
    gap: "56px",
  },
  leftmidbox: {
    backgroundColor: "transarant",

    // width: "10%",
    // height: "92%",
    display: "grid",
    // alignItems: "center",
    justifyContent: "center",
    // position: "absolute",
    // gap: "82px",
  },
  sidenav: {
    backgroundColor: "#ffffff1a",
    border: "1px solid #ffffff33",
    borderRadius: "20px",
    width: "65px",
    height: "278px",
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer", // Make the whole area look clickable
  },
  rightmidbox: {
    background: "transarant",
    width: "100%",
    alignItems: "start",
    justifyContent: "start",
    position: "relative",
    borderRadius: "8px",
    height: "63vh",
    overflowX: "hidden",
  },
  cardstructure: {
    padding: "10px", // Add padding for better spacing
  },
  toggle: {
    width: "100%",
    // height: "15vh",
    position: "relative",
  },
  icon: {
    width: "65px", // Default size for the big icon
    position: "absolute",
    cursor: "pointer",
  },
  iconHidden: {
    width: "32px", // Size for the small icon
    position: "absolute",
    top: "40px", // Adjust position to match your design
    left: "44px", // Adjust position to match your design
    cursor: "pointer",
  },

  ailogo: {
    width: "65px",
    position: "absolute",
  },
  searchbar: {
    width: "32px",
    position: "absolute",
    top: "40px",
    left: "44px",
    zIndex: "1",
  },
  container: {
    position: "relative",
    display: "inline-block",
    padding: "10px",
    // width: "10%",
    display: "flex",
    width: "50%",
    // gap: "16px",
  },
  leftcontainer: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  svg: {
    width: "100%",
  },
  text: {
    position: "absolute",
    // top: "45%",
    // left: "18%",

    // transform: "translate(-50%, -50%)",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#000",
    pointerEvents: "none", // Prevents text from interfering with SVG interactions
    paddingLeft: "40px",
  },
  breadmain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sidebar: {
    width: "98px",
    height: "73px",
    backgroundColor: "#ffffff1a",
    border: " 1px solid #ffffff33",
    position: "relative",
    borderRadius: "20px",
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
    marginTop: "20px",
    marginBottom: "0px",
    marginRight: "20px",
  },
  sidebarnavigation: {
    width: "98px",
    height: "179px",
    backgroundColor: "#ffffff1a",
    border: " 1px solid #ffffff33",
    borderRadius: "20px",
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
  },
  createjdbutton: {
    backgroundColor: "rgba(255, 255, 255, 1.0)",
    borderTop: " 1px solid rgba(255, 255, 255, 0.4)",

    width: "98px",
    height: "20px",
    borderRadius: "0px 0px 20px 20px",
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    fontWeight: "100",
    color: "#fff",
  },
  createjd: {
    top: "13px",
    position: "absolute",
    left: "28px",
  },
  uploadjd: {},
  main: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
  },
  createjd: {
    top: "13px",
    position: "absolute",
    left: "28px",
  },
  uploadjd: {},
  main: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
  },
  box2: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    border: "1px solid #ffffff7a",

    borderRadius: "18px",
    height: "24px",
    width: "24px",
    position: "absolute",
    top: "-13px",
    left: "81px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  spotanimationlogo: {
    width: "50%",
  },
  inputdiv: {
    width: "98%",

    // position: "absolute",
    margin: "4px 12px",
  },
  formdiv: {
    border: "1px solid #fff",
    width: "98%",
    height: "5vh",
    // position: "absolute",
    margin: "8px 0px",
    borderRadius: "4px",

    background: "transparent",
  },

  description: {
    border: "1px solid #fff",
    width: "98%",
    height: "80px",
    // position: "absolute",
    margin: "8px 0px",
    borderRadius: "4px",
    background: "transparent",
  },

  Experincetitle: {
    appearance: "none", // Hide default arrow
    padding: "8px 12px", // Adjust padding to give room for the icon
    borderRadius: "4px",
    border: "1px solid #ccc",
    backgroundColor: "#fffefe", // Solid background for dropdown
    backgroundImage: `
      linear-gradient(45deg, transparent 50%, rgb(102, 126, 234) 50%), 
      linear-gradient(135deg, rgb(136, 89, 184) 50%, transparent 50%)
    `, // Gradient-colored arrow
    backgroundPosition: `
      calc(100% - 15px) calc(1em + 2px), 
      calc(100% - 10px) calc(1em + 2px)
    `,
    backgroundSize: "5px 5px, 5px 5px",
    backgroundRepeat: "no-repeat",
    color: "#333", // Optional text color for dropdown text
    width: "34%",
  },
  form: {
    position: "absolute",
    width: "98%",
    display: "flex",
    flexDirection: "column", // Align items in a column
    alignItems: "flex-end",
  },
  Experince: {
    display: "flex",
    alignItems: "center",
    marginTop: "12px",
    justifyContent: "space-around",
  },
  underExperince: {
    width: "61%",
  },
  title: {
    margin: "10px",
  },
  worktype: {
    display: "flex",
    margin: "12px",
    alignItems: "center",
    gap: "12px",
    marginTop: "12px",
  },
  worktypename: {
    border: "1px solid #fff",
    // width: "260px",
    // height: "10px",
    padding: "5px 20px",
    borderRadius: "4px",
  },

  // Style for selected work type
  selectedWorkType: {
    backgroundColor: "#667EEA", // Example: Highlight selected type
    color: "#fff",
  },
  submit: {
    border: "1px solid #fff",
    padding: "5px 20px",
    borderRadius: "4px",
    margin: "10px 40px",
  },
  frame: {
    alignItems: "center",
    backgroundColor: "#ddc8ff",
    border: "0.5px solid",
    borderColor: "#d9d9d952",
    borderRadius: "32px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    height: "50px",
    justifyContent: "center",
    padding: "11px 18px",
    position: "relative",
    width: "50px",
    cursor: "pointer", // Add cursor pointer to indicate clickability
  },

  // Style for the selected frame
  selectedFrame: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)", // Example: Highlight selected frame
  },
  // Nested style for .frame .text-wrapper
  textWrapper: {
    color: "#ffffff",
    fontFamily: '"Roboto-Regular", Helvetica',
    fontSize: "22.8px",
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: "normal",
    position: "relative",
    whiteSpace: "nowrap",
    width: "fit-content",
  },
  filter: {
    display: "flex",
    gap: "12px",
  },

  interviewvideo: {
    background: "#000",
    width: "100%",
    height: "56vh",
  },
  evolution: {
    background: "#000",
    width: "100%",
    height: "56vh",
  },
  transcript: {
    background: "#000",
    width: "100%",
    height: "56vh",
  },
  togglediv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "32px",
    margin: "16px",
    background: "#DDC8FF",
    padding: "6px 16px",
    borderRadius: "24px",
  },
  toggleinterviewdetails: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    color: " black",
    padding: "8px 32px",
    borderRadius: "22px",
  },
  toggleinterviewdetailsof: {
    padding: "4px 32px",
    borderRadius: "22px",
    color: "#fff",
  },
};

export default SpotPlusStudentDetails;
