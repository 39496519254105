import React, { useState, useEffect } from "react";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";
import { listCompanyProfiles, listProfileVisits } from "../../graphql/queries";
import { listCandidateProfiles } from "../../graphql/queries";
import { IoIosNotifications } from "react-icons/io";
import profilephoto from "../../assets/profile.png";
import { MdCancel } from "react-icons/md";
import { updateProfileVisit } from "../../graphql/mutations";

const ProfileModal = ({
  isOpen,
  onClose,
  onHome,
  onLogoutClick,
  onAdminaddcontext,
  onProfileClickUpdate,
}) => {
  const candidateProfileEmailID = localStorage.getItem(
    "candidateProfileEmailID"
  );
  const [iscompany, setIscompany] = useState();

  const [candidateProfileExists, setCandidateProfileExists] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let nextToken = null;
        let userProfileList = [];
        do {
          const CompanyResponse = await API.graphql(
            graphqlOperation(listCompanyProfiles, {
              filter: { email: { eq: candidateProfileEmailID } },
              limit: 100,
              nextToken: nextToken,
            })
          );

          setIscompany(CompanyResponse.data.listCompanyProfiles.items[0]);
          userProfileList.push(
            ...CompanyResponse.data.listCompanyProfiles.items
          );
          nextToken = CompanyResponse.data.listCompanyProfiles.nextToken;
        } while (nextToken);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    // checking candidate profile id
    async function checkcandidateProfile() {
      try {
        let nextToken = null;
        let candidateProfile = null;
        const userInfo = await Auth.currentAuthenticatedUser();
        const userEmail = userInfo.attributes.email.toLowerCase();

        do {
          const candidateProfileData = await API.graphql(
            graphqlOperation(listCandidateProfiles, {
              filter: {
                // originnal code start
                // userID: {
                //   eq: localStorage.getItem("candidateProfileAuthID"),
                // },
                // original code end
                email: {
                  eq: userEmail,
                },
              },
              limit: 100, // Adjust the limit as per your requirement
              nextToken: nextToken,
            })
          );

          const items = candidateProfileData.data.listCandidateProfiles.items;
          if (items.length > 0) {
            candidateProfile = items[0];
            setCandidateProfileExists(true);
            localStorage.setItem("candidateProfileId", candidateProfile.id);
            localStorage.setItem(
              "candidateProfile",
              JSON.stringify(candidateProfile)
            );
            break; // Exit the loop since a profile is found
          } else {
            // Continue to next iteration if there are more records to fetch
            nextToken =
              candidateProfileData.data.listCandidateProfiles.nextToken;
          }
        } while (nextToken);

        // If no profile found after exhausting all pages
        if (!candidateProfile) {
          console.log("No profile found for this candidate ID.");
          setCandidateProfileExists(false);
        }
      } catch (error) {
        console.error("Error fetching candidate profile", error);
        setCandidateProfileExists(false);
      }
    }

    checkcandidateProfile();
  }, []);

  //test code start local null

  const handleLogout = () => {
    // localStorage.clear();

    const keysToRemove = [
      "candidateProfileEmailID",
      "candidateProfileAuthID",
      "candidateProfileId",
      "candidateProfile",
      "CompanyProfileID",
      "CompanyEmailID",
      "CompanyProfile",
      "RecentJDIdAfterJDupload"
    ];
    keysToRemove.forEach((key) => {
      localStorage.removeItem(key);
    });

    // Call the logout function passed from props
    onLogoutClick();
  };

  //test code end local null

  // console.log("@@@@@@@@@@@@@@@@@@@@@@",candidateProfileExists)

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
      <div className="relative w-auto max-w-md mx-auto my-6">
        <div className="relative flex flex-col w-full bg-white border-2 border-gray-300 rounded-md shadow-md outline-none focus:outline-none">
          <div className="flex flex-col items-center justify-between p-5 border-b border-solid border-gray-300 rounded-t">
            <h3 className="text-lg font-semibold  text-black">
              {candidateProfileEmailID}
            </h3>
            <button
              // className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              className="py-1 px-3 border-1 text-black"
              onClick={onClose}
            >
              {/* <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span> */}
              x
            </button>
          </div>
          <div className="relative p-6 flex-auto">
            <button
              className="w-full py-2 my-2 text-center text-gray-700 border border-gray-300 rounded hover:bg-gray-200"
              onClick={onHome}
            >
              Home
            </button>
            {
              //   (candidateProfileEmailID && iscompany) ? (
              //     <h1></h1>
              //   ) : (

              //     <button
              //   className="w-full py-2 my-2 text-center text-gray-700 border border-gray-300 rounded hover:bg-gray-200"
              //   onClick={onProfileClickUpdate}
              // >
              //   Edit User Profile
              // </button>
              //   )

              candidateProfileExists ? (
                <button
                  className="w-full py-2 my-2 text-center text-gray-700 border border-gray-300 rounded hover:bg-gray-200"
                  onClick={onProfileClickUpdate}
                >
                  Edit User Profile
                </button>
              ) : iscompany ? (
                <button className="w-full py-2 my-2 text-center text-gray-700 border border-gray-300 rounded hover:bg-gray-200">
                  Company Profile
                </button>
              ) : (
                <h1></h1>
              )
            }
            {/* <button
              className="w-full py-2 my-2 text-center text-gray-700 border border-gray-300 rounded hover:bg-gray-200"
              onClick={onProfileClickUpdate}
            >
              Edit User Profile
            </button> */}
            {candidateProfileEmailID ===
              "getscreened@varahitechnologies.com" && (
              <button
                className="w-full py-2 my-2 text-center text-green-700 border border-green-300 rounded hover:bg-green-200"
                onClick={onAdminaddcontext}
              >
                Admin
              </button>
            )}
            <Link to={"/"}>
              <button
                className="w-full py-2 my-2 text-center text-red-700 border border-red-300 rounded hover:bg-red-200"
                // onClick={onLogoutClick}  original code
                //test code start
                onClick={handleLogout}
                // test code end
              >
                signOut
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

function Header({ signOut }) {
  const [showEmail, setShowEmail] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [candidateEmail, setCandidateEmail] = useState("");
  const navigate = useNavigate();

  // view/seen code start

  const [profilevisits, setProfileVisits] = useState({});
  const [arrayofObjectProfile, setArrayofObjectProfile] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const [notificationIsNew, setNotificationIsNew] = useState(false);
  const [iscandidate, setIscandidate] = useState(false);

  const location = useLocation();

  // Check if we're on the /profileedit page
  const showmyskill = location.pathname === "/ShowMySkills";

  // view/seen code end

  useEffect(() => {
    const candidateProfileEmailID = localStorage.getItem(
      "candidateProfileEmailID"
    );
    setCandidateEmail(candidateProfileEmailID);
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleWindowResize = () => {
    if (window.innerWidth <= 500) {
      setShowEmail(false);
    } else {
      setShowEmail(true);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    console.log("Modal toggled");
  };

  // seen/view Notication code start

  useEffect(() => {
    const visitedEmailfromlocal = localStorage.getItem(
      "candidateProfileEmailID"
    );

    const fetchViewandSeenData = async (visitedEmailfromlocal) => {
      const user = localStorage.getItem("candidateProfile");

      if (user) {
        setIscandidate(true);
      }

      let profileVisitItemsList = [];
      let currentNextToken = null;

      try {
        do {
          const response = await API.graphql(
            graphqlOperation(listProfileVisits, {
              // Updated query name to listProfileVisits
              filter: { visitedEmail: { eq: visitedEmailfromlocal } }, // Filter by visitedEmail
              // If you want to add a limit, uncomment the next line:
              // limit: 2500,
              nextToken: currentNextToken,
            })
          );

          // Ensure we're accessing the correct data structure
          const profileVisitData = response.data.listProfileVisits || {};
          const items = profileVisitData.items || [];

          // Set currentNextToken for pagination
          currentNextToken = profileVisitData.nextToken;

          // Append the fetched items to the list
          profileVisitItemsList = [...profileVisitItemsList, ...items];
        } while (currentNextToken);

        // Set state with the fetched profile visit data
        setProfileVisits(profileVisitItemsList[0]); // or adjust based on what you need
        setArrayofObjectProfile(profileVisitItemsList);

        // filter code start

        const currentDate = new Date(); // Current date and time
        const fifteenDaysAgo = new Date(currentDate);
        fifteenDaysAgo.setDate(currentDate.getDate() - 15); // Date 15 days ago

        // Filter and sort the profile visits
        const filteredSortedProfileVisits = profileVisitItemsList
          .filter((item) => {
            // Convert visitedTime to a Date object for comparison
            const visitedDate = new Date(item.visitedTime);
            // Filter by visits within the last 15 days
            return visitedDate >= fifteenDaysAgo && visitedDate <= currentDate;
          })
          .sort((a, b) => {
            // Sort by visitedTime in descending order (most recent first)
            return new Date(b.visitedTime) - new Date(a.visitedTime);
          });

        setSortedData(filteredSortedProfileVisits);

        if (filteredSortedProfileVisits.length === 0) {
          setNotificationIsNew(true);
        }

        if (
          filteredSortedProfileVisits.length > 0 &&
          filteredSortedProfileVisits
        ) {
          const { id, isViewed } = filteredSortedProfileVisits[0];

          if (isViewed === false) {
            setNotificationIsNew(false);
          }

          if (isViewed === true) {
            setNotificationIsNew(true);
          }
        }

        // filter code end
      } catch (error) {
        console.error("Error fetching Profile Visits:", error);
      }
    };

    fetchViewandSeenData(visitedEmailfromlocal);
  }, []);

  const notViewedItems = arrayofObjectProfile.filter((item) => !item.isViewed);

  const getTimeDifference = (visitedTime) => {
    const now = new Date();
    const visitedDate = new Date(visitedTime);
    const differenceInMs = now - visitedDate;

    const seconds = Math.floor(differenceInMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days}d ago`;
    if (hours > 0) return `${hours}h ago`;
    if (minutes > 0) return `${minutes}m ago`;
    return `${seconds}s ago`;
  };

  const changeShowModel = async () => {
    setShowPopup(!showPopup);

    if (sortedData && sortedData.length > 0) {
      const { id, isViewed } = sortedData[0];

      if (
        id !== undefined &&
        id !== null &&
        isViewed !== undefined &&
        isViewed !== null
      ) {
        if (isViewed === false && notificationIsNew === false) {
          try {
            setNotificationIsNew(true);

            const input = {
              id: id,
              isViewed: true,
            };

            const response = await API.graphql(
              graphqlOperation(updateProfileVisit, { input })
            );
          } catch (error) {
            console.log("updateProfileVisit", error);
          }
        }
      }
    }
  };

  // seen/view Notication code end
  // console.log("kkkkkkkkkkkkkkkk",sortedData)

  return (
    <div>
      <div
        className="d-flex justify-content-between align-items-center dashboard-header border-bottom pb-2 w-100"
        style={{
          background:
            "linear-gradient(180deg, rgb(39, 44, 88) 0%, rgb(65, 36, 94) 100%)",
          border: "0.5px solid #fff0.5px solid rgb(255 255 255 / 0%)",
        }}
      >
        <Link to={"/"}>
          <div>
            <img src={logo} alt="Company Logo" style={{ height: "64px" }} />
          </div>
        </Link>
        <div className="d-flex align-items-center">
          {/* seen view code start */}

          {iscandidate && !showmyskill && (
            <button
              onClick={changeShowModel}
              style={{
                fontSize: "24px",
                position: "relative",
                padding: "10px",
                cursor: "pointer",
              }}
            >
              <IoIosNotifications style={{ color: "white" }} />{" "}
              {/* Eye icon for unviewed */}
              {!notificationIsNew && (
                <span
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "13px",
                    backgroundColor: "red",
                    color: "white",
                    borderRadius: "50%",
                    padding: "5px",
                    fontSize: "12px",
                  }}
                >
                  {/* {notViewedItems.length} */}
                </span>
              )}
            </button>
          )}

          {/* Popup for unviewed profiles */}

          {/* with design and recently view fully working code start */}

          {/* {showPopup && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end items-start z-50">
              <div className="bg-white rounded-lg shadow-lg mt-24 mr-4 w-full sm:w-80 max-h-screen overflow-y-auto sm:mt-[100px]">
                <h3 className="text-lg font-semibold text-center mb-4 text-gray-800">Unviewed Profile Visits</h3>

                
                {sortedData.length > 0 ? (
                  <ul className="space-y-4 max-h-[60vh] overflow-y-auto px-4">
                    {sortedData
                      .sort((a, b) => new Date(b.visitedTime) - new Date(a.visitedTime)) 
                      .map((item, index) => {
                        let dataObject;
                        try {
                          dataObject = item.visitorProfile ? JSON.parse(item.visitorProfile) : {};
                        } catch (error) {
                          console.error('Error parsing visitorProfile:', error);
                        }

                        const handleRedirect = () => {
                          const encodedEmail = encodeURIComponent(dataObject?.email || '');
                          const shareableURL = `${window.location.origin}/ShowMySkills?email=${encodedEmail}`;
                          window.open(shareableURL, '_blank'); // Opens URL in a new tab
                        };

                        return (
                          <li
                            key={index}
                            onClick={handleRedirect}
                            className="flex items-center p-4 rounded-lg bg-gray-100 shadow-md cursor-pointer hover:bg-gray-200 transition"
                          >
                            <img
                              src={dataObject?.photoURL || profilephoto}
                              alt="Visitor"
                              className="w-10 h-10 rounded-full mr-3"
                            />
                            <div className="flex-1 text-black">
                              <p className="text-sm font-medium m-0">
                                {dataObject?.fullName ? `${dataObject.fullName} viewed your profile` : 'Someone viewed your profile'}
                              </p>
                              <p className="text-xs text-gray-500 mt-1">
                                {getTimeDifference(item.visitedTime)}
                              </p>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                ) : (
                  <p className="text-gray-500 text-center">No unviewed visits.</p>
                )}

                <button
                  onClick={() => setShowPopup(false)}
                  className="mt-4 w-full py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg text-sm font-medium transition"
                >
                  Close
                </button>
              </div>
            </div>
          )} */}

          {/* with design and recently view fully working code end */}

          {showPopup && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end items-start z-50">
              <div className=" bg-black border border-[#9900ff] rounded-lg shadow-lg mt-24 mr-4 w-full sm:w-[26rem] max-h-screen overflow-y-auto sm:mt-[100px]">
                <div className="flex items-center justify-between mx-2">
                  <div></div>
                  <h3 className="text-lg font-semibold text-center mt-2 mb-2 text-white">
                    Recent Profile Visitors
                  </h3>
                  <div
                    className="text-center "
                    onClick={() => setShowPopup(false)}
                  >
                    <MdCancel />
                  </div>
                </div>
                {sortedData.length > 0 ? (
                  <ul className="space-y-1 max-h-[60vh] overflow-y-auto pl-0 p-2">
                    {sortedData
                      .sort(
                        (a, b) =>
                          new Date(b.visitedTime) - new Date(a.visitedTime)
                      )
                      .map((item, index) => {
                        let dataObject;
                        try {
                          dataObject = item.visitorProfile
                            ? JSON.parse(item.visitorProfile)
                            : {};
                        } catch (error) {
                          console.error("Error parsing visitorProfile:", error);
                        }

                        const handleRedirect = () => {
                          const encodedEmail = encodeURIComponent(
                            dataObject?.email || ""
                          );
                          const shareableURL = `${window.location.origin}/ShowMySkills?email=${encodedEmail}`;
                          window.open(shareableURL, "_blank");
                        };

                        return (
                          <li
                            key={index}
                            onClick={handleRedirect}
                            className="flex items-center p-2 bg-gray-100 cursor-pointer hover:bg-gray-200 transition rounded-md whitespace-nowrap overflow-hidden"
                          >
                            <img
                              src={
                                dataObject?.photoURL?.includes("staging")
                                  ? profilephoto
                                  : dataObject?.photoURL
                              }
                              alt="Visitor"
                              className="w-8 h-8 rounded-full mr-3 flex-shrink-0"
                            />
                            <div className="flex items-center text-black text-sm space-x-2 overflow-hidden">
                              <b>
                                {dataObject?.fullName ||
                                  dataObject?.companyName ||
                                  "Someone"}
                              </b>
                              &nbsp;is viewed your profile
                              <span className="text-gray-500 text-xs">
                                {getTimeDifference(item.visitedTime)}
                              </span>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                ) : (
                  <p className="text-gray-500 text-center">
                    No one has viewed this profile yet.
                  </p>
                )}
                {/* 
                <button
                  onClick={() => setShowPopup(false)}
                  className="w-full py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg text-sm font-medium transition"
                >
                  Close
                </button> */}
              </div>
            </div>
          )}

          {/* seen view code end */}

          {showEmail && <span className="mr-2">{candidateEmail}</span>}
          <FontAwesomeIcon
            icon={faUserCircle}
            style={{ fontSize: "1.5rem", cursor: "pointer" }}
            onClick={toggleModal}
            id="userId"
          />
          <div>
            {isModalOpen && (
              <ProfileModal
                isOpen={isModalOpen}
                onClose={toggleModal}
                onHome={() => {
                  navigate("/CandidateDashboard");
                }}
                onProfileClickUpdate={() => {
                  navigate("/EditCandidateProfileReg");
                }}
                onAdminaddcontext={() => {
                  navigate("/AdminAddcontext");
                }}
                onLogoutClick={signOut}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAuthenticator(Header);
