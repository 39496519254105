import React, { useState } from "react";
import sourcelink from "../../assets/sourcelink.svg";
import checkbox from "../../assets/checkbox.svg";
import select from "../../assets/checkbox-select.svg";
SpotPlusCardGpt.defaultProps = {
  candidates: [
    {
      id: 1,
      name: "Aarav Mehta",
      experience: "5 Years of Experience",
      location: "Remote",
      description:
        "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in 45 BC, making it over 2000 years old.",
      skills: ["Html", "css", "js", "ai/ml"],
      sourceLink: "www.sourcelink.com",
      isSelected: false,
    },
    {
      id: 2,
      name: "Priya Sharma",
      experience: "3 Years of Experience",
      location: "On-Site",
      description:
        "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
      skills: ["Python", "Django", "React", "AI"],
      sourceLink: "www.sourcelink.com",
      isSelected: false,
    },
    {
      id: 3,
      name: "Rajesh Kumar",
      experience: "7 Years of Experience",
      location: "Hybrid",
      description:
        "It is a long-established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      skills: ["Node.js", "Express", "MongoDB", "DevOps"],
      sourceLink: "www.sourcelink.com",
      isSelected: false,
    },
  ],
};
function SpotPlusCardGpt({ candidates, onCheckChange }) {
  const [hover, setHover] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [candidate, setCandidate] = useState(candidates); // Store candidte in state
  const [selectedCandidate, setselectedCandidate] = useState(null); // State to store selected candidte
  const [isCheckedState, setIsCheckedState] = useState({}); // Store checked state for each candidate
  const [selectedCandidateIds, setSelectedCandidateIds] = useState([]); // Local state for selected IDs
  const handleCheckboxClick = (candidateId) => {
    let updatedSelectedIds;

    if (selectedCandidateIds.includes(candidateId)) {
      // Candidate is already selected, so remove it
      updatedSelectedIds = selectedCandidateIds.filter(
        (id) => id !== candidateId
      );
    } else {
      // Candidate is not selected, so add it
      updatedSelectedIds = [...selectedCandidateIds, candidateId];
    }

    setSelectedCandidateIds(updatedSelectedIds); // Update local state
    console.log(updatedSelectedIds);
    onCheckChange && onCheckChange(updatedSelectedIds); // Notify parent
  };
  return (
    <div className="main">
      {candidates && candidates.length > 0 ? ( // Check if candidates exists and is not empty
        candidates.map(
          (
            candidate // Map over the candidates prop
          ) => (
            <div key={candidate.id} className="spot-plus-card-container">
              <style jsx>{`
                .spot-plus-card-container {
                  width: 100%;
                  max-width: 190px; /* Reduced width */
                  margin: 0 auto;
                }

                .card-wrapper {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  position: relative;
                  border-radius: 6px; /* Slightly smaller border radius */
                  overflow: hidden;
                  // box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
                }

                .card-svg {
                  width: 100%;
                  height: auto;
                  aspect-ratio: 374 / 358;
                }

                .card-content {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  padding: 0.3rem; /* Reduced padding */
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                }

                .card-header {
                  color: #231d38;
                  background: transparent;
                }

                .card-header h6 {
                  margin: 0;
                  font-size: 0.9rem; /* Reduced font size */
                }

                .card-details {
                  font-size: 0.6rem; /* Smaller font size */
                  color: #231d38;
                  display: flex;
                  align-items: center;
                  gap: 0.3rem; /* Reduced gap */
                }

                .card-description {
                  font-size: 0.6rem; /* Smaller font size */
                  color: #231d38;
                  margin: 0.3rem 0; /* Reduced margin */
                }

                .skills-container {
                  display: flex;
                  gap: 0.3rem; /* Reduced gap */
                  margin: 0.3rem 0; /* Reduced margin */
                }

                .skill-tag2 {
                  // border: 1px solid rgb(136, 89, 184);
                  border-radius: 3px;
                  padding: 0.1rem 0.3rem; /* Reduced padding */
                  font-size: 0.6rem; /* Smaller font size */
                  background: rgb(231, 224, 255);
                  // background-clip: text;
                  color: rgb(51, 51, 51);
                  // -webkit-background-clip: text;
                }

                .select-button {
                  margin-top: 0.5rem; /* Reduced margin */
                  width: 100%;
                  padding: 0.5rem; /* Reduced padding */
                  background: linear-gradient(
                    180deg,
                    rgb(102, 126, 234) 0%,
                    rgb(136, 89, 184) 100%
                  );
                  color: white;
                  border: none;
                  border-radius: 4px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 0.3rem; /* Reduced gap */
                  cursor: pointer;
                  transition: background-color 0.3s;
                }

                .select-button:hover {
                  background: #5b0ca2;
                }

                .button-text {
                  font-size: 0.7rem; /* Reduced font size */
                }

                .source-link {
                  display: flex;
                  align-items: center;
                  gap: 0.3rem; /* Reduced gap */
                  margin-top: 0.3rem; /* Reduced margin */
                }

                .source-link-img {
                  width: 1.5rem; /* Reduced size */
                  height: 1.5rem; /* Reduced size */
                  border-radius: 50%;
                  border: 1px solid #a17c3e;
                }

                .source-link-text {
                  color: white;
                  font-size: 0.7rem; /* Reduced font size */
                  font-family: Roboto;
                  font-weight: 300;
                }
                .main {
                  display: flex;
                  gap: 10px;
                }

                @media (max-width: 480px) {
                  .spot-plus-card-container {
                    max-width: 250px; /* Even smaller on very small screens */
                  }

                  .card-header h6 {
                    font-size: 0.8rem;
                  }

                  .card-details,
                  .card-description,
                  .skill-tag {
                    font-size: 0.5rem;
                  }

                  .select-button {
                    padding: 0.4rem;
                  }
                }
              `}</style>

              <div className="card-wrapper">
                <svg
                  className="card-svg"
                  viewBox="0 0 374 358"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {/* SVG paths remain the same */}
                  <path
                    d="M367.161 42.451C367.161 61.1313 352.018 76.2747 333.338 76.2747C314.657 76.2747 299.514 61.1313 299.514 42.451C299.514 23.7707 314.657 8.62737 333.338 8.62737C352.018 8.62737 367.161 23.7707 367.161 42.451Z"
                    fill="url(#pattern0)"
                    stroke="white"
                    strokeWidth="0.856"
                  />
                  <path
                    d="M0 28.0139C0 12.648 12.4565 0.191406 27.8224 0.191406H260.742C271.269 0.191406 295.422 15.1059 295.422 47.9588C295.422 58.0381 305.698 86.9615 338.665 81.2644C352.654 78.847 375.486 93.0967 373.773 112.817V329.709C373.773 345.075 361.316 357.531 345.951 357.531H27.8224C12.4565 357.531 0 345.075 0 329.709V28.0139Z"
                    fill="#DDC8FF"
                  />
                </svg>

                <div className="card-content">
                  <div>
                    <div className="card-header">
                      <h6>{candidate.name}</h6>
                      <div className="card-details">
                        <span>{candidate.experience}</span>
                        <span>*</span>
                        <span>{candidate.location}</span>
                      </div>
                      <div className="card-description">
                        {candidate.description}
                      </div>
                    </div>

                    <div>
                      <div className="skills-container">
                        {candidate.skills.map((skill, index) => (
                          <div key={index} className="skill-tag2">
                            {skill}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <button
                    className="select-button"
                    onClick={() => handleCheckboxClick(candidate.id)}
                  >
                    <span className="button-text">Select Candidate</span>
                    <img
                      src={
                        selectedCandidateIds.includes(candidate.id)
                          ? select
                          : checkbox
                      }
                      alt="Select candidate"
                      style={{ width: "1.2rem", height: "auto" }}
                    />
                  </button>
                </div>
              </div>

              <div className="source-link">
                <img
                  className="source-link-img"
                  src={sourcelink}
                  alt="Source link"
                />
                <div className="source-link-text">www.sourcelink.com</div>
              </div>
            </div>
          )
        )
      ) : (
        <p>Loading candidates...</p> // or a loading indicator
      )}
    </div>
  );
}

export default SpotPlusCardGpt;
