import React, { useContext, useEffect, useState } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import carbonCloseOutline from "../../assets/carbon_close-outline.svg";
import informationCircleOutlineSvgrepoCom1 from "../../assets/information-circle-outline-svgrepo-com 1.svg";
import line6 from "../../assets/Line 6.svg";
import checkbox from "../../assets/checkbox.svg";
import select from "../../assets/checkbox-select.svg";
import { SpotContext } from "../../context/SpotContext";
import { listCandidateProfiles, listInterviewEvaluations } from "../../graphql/queries";

// const jobData = [
//   {
//     id: 1,
//     jdName: "Software Engineer",
//     experience: "3-5 years",
//     workCategory: "Remote",
//     summary:
//       "Experienced software engineer with expertise in JavaScript, React, Node.js, and AWS.",
//     skills: ["JavaScript", "React", "Node.js", "AWS"],
//     total: 10,
//     resumeBase: 3,
//     filterCandidate: 7,
//   },
//   {
//     id: 2,
//     jdName: "Data Scientist",
//     experience: "2+ years",
//     workCategory: "On-site",
//     summary:
//       "Data scientist proficient in Python, Machine Learning algorithms, and various data analysis techniques.",
//     skills: ["Python", "Machine Learning", "Data Analysis"],
//     total: 5,
//     resumeBase: 2,
//     filterCandidate: 3,
//   },
//   {
//     id: 3,
//     jdName: "Data Scientist",
//     experience: "2+ years",
//     workCategory: "On-site",
//     summary:
//       "Data scientist proficient in Python, Machine Learning algorithms, and various data analysis techniques.",
//     skills: ["Python", "Machine Learning", "Data Analysis"],
//     total: 5,
//     resumeBase: 2,
//     filterCandidate: 3,
//   },
//   {
//     id: 4,
//     jdName: "Data Scientist",
//     experience: "2+ years",
//     workCategory: "On-site",
//     summary:
//       "Data scientist proficient in Python, Machine Learning algorithms, and various data analysis techniques.",
//     skills: ["Python", "Machine Learning", "Data Analysis"],
//     total: 5,
//     resumeBase: 2,
//     filterCandidate: 3,
//   },
// ];

const styles = {
  card: {
    backgroundColor: "#ddc8ff",
    borderRadius: "32px 12px 12px 12px",
    height: "334px",
    width: "430px",
    padding: "16px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", // Added box shadow
    position: "relative", // To position the close button
  },
  title: {
    background:
      "linear-gradient(180deg, rgb(35, 29, 56) 0%, rgb(80, 61, 117) 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    color: "transparent",
    fontFamily: "Roboto-Medium, Helvetica",
    fontSize: "20px",
    fontWeight: 500,
    marginBottom: "16px",
  },
  subtitle: {
    background:
      "linear-gradient(180deg, rgb(35, 29, 56) 0%, rgb(80, 61, 117) 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    color: "transparent",
    fontFamily: "Roboto-Regular, Helvetica",
    fontSize: "1rem",
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },
  remote: {
    background:
      "linear-gradient(180deg, rgb(35, 29, 56) 0%, rgb(80, 61, 117) 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    color: "transparent",
    fontFamily: "Roboto-Regular, Helvetica",
    fontSize: "1rem",
    fontWeight: 400,
  },
  description: {
    background:
      "linear-gradient(180deg, rgb(35, 29, 56) 0%, rgb(80, 61, 117) 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    color: "transparent",
    fontFamily: "Roboto-Regular, Helvetica",
    fontSize: "14px",
    fontWeight: 400,
    marginBottom: "16px",
  },
  skillsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    marginBottom: "16px",
  },
  skill: {
    backgroundColor: "#ffffff47",
    borderRadius: "2.04px",
    padding: "4px 8px",
    fontFamily: "Roboto-Regular, Helvetica",
    fontSize: "12px",
    fontWeight: 400,
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    color: "transparent",
  },
  sourceContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
    justifyContent: "sourceContainer",
    justifyContent: "space-between",
  },
  source: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ffffff47",
    borderRadius: "4px",
    justifyContent: "center",
    padding: "0px 4px",
  },
  sourceTitle: {
    background:
      "linear-gradient(180deg, rgb(35, 29, 56) 0%, rgb(80, 61, 117) 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    color: "transparent",
    fontFamily: "Roboto-Light, Helvetica",
    fontSize: "16px",
    fontWeight: 300,
    marginRight: "8px",
  },
  sourceCount: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    borderRadius: "2.25px",
    padding: "2px 8px",
    fontFamily: "Roboto-Light, Helvetica",
    fontSize: "9px",
    fontWeight: 300,
    color: "#ffffff",
  },
  totalContainer: {
    backgroundColor: "#ffffff47",
    borderRadius: "4px",
    padding: "4px 8px",
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
  },
  totalTitle: {
    background:
      "linear-gradient(180deg, rgb(35, 29, 56) 0%, rgb(80, 61, 117) 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    color: "transparent",
    fontFamily: "Roboto-Regular, Helvetica",
    fontSize: "16px",
    fontWeight: 400,
    marginBottom: "4px",
    textAlign: "center",
  },
  totalCount: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    borderRadius: "2px",
    padding: "4px 20px",
    fontFamily: "Roboto-Light, Helvetica",
    fontSize: "16px",
    fontWeight: 300,
    color: "#ffffff",
  },
  closeButton: {
    position: "absolute",
    top: "16px",
    right: "16px",
    cursor: "pointer",
  },
  line: {
    height: "6px",
    width: "6px",
    objectFit: "cover",
    marginLeft: "8px",
  },
  button: {
    marginTop: "16px",
    padding: "12px",
    backgroundColor: "#6A0DAD",
    color: "white",
    border: "none",
    borderRadius: "10px",
    cursor: "pointer",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.3s",
  },
  buttonHover: {
    backgroundColor: "#5b0ca2",
  },
  checkbox: {
    marginLeft: "8px",
    backgroundColor: "transparent",
    border: "1px solid #fff",
    borderRadius: "4px",
  },
  details: {
    display: "flex",
    gap: "8px",
  },
  detailsSecound: {
    display: "grid",
    gap: "4px",
  },
  jdinfo: {
    display: "flex",
    gap: "8px",
  },
};

function SpotPlusYourJd() {

  // store code start

  const {
    spotprocessCompanyJDList,
    setSpotprocessSelectedJDID,
    setSpotprocessSelectedJD,
    setSpotprocessAiJDSlcandidates,
    // prepare AI loading animation code
    setLoadingPrepareAIData
  } = useContext(SpotContext);


  //  store code end

  const [jobData, setJobData] = useState(spotprocessCompanyJDList)


  //  Selected Jd shorlist code start

  const [combinedProfilesWithEvaluations, setCombinedProfilesWithEvaluations] = useState([]);
  const [isFetchingEvaluations, setIsFetchingEvaluations] = useState(false);
  const [evaluations, setEvaluations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);


  useEffect(() => {
    if (profiles.length) {
      const combinedProfiles = profiles.map((profile, index) => {
        // Find matching evaluations by candidateEmail and profile.email
        const matchedEvaluations = evaluations.filter(
          evaluation => evaluation.candidateEmail === profile.email
        );

        // Prepare evaluation fields directly in the profile
        const evaluationData = {};
        matchedEvaluations.forEach((evaluation, idx) => {
          evaluationData[`evaluation${idx + 1}Email`] = evaluation.candidateEmail;
          evaluationData[`evaluation${idx + 1}Transcript`] = evaluation.transcript;
          evaluationData[`evaluation${idx + 1}VideoURL`] = evaluation.videoURL;
          evaluationData[`evaluation${idx + 1}evaluation`] = evaluation.evaluation;
        });

        // Return the profile with merged evaluation data (if any)
        return {
          customId: index + 1,  // New custom ID
          ...profile,           // Existing profile fields (including original id)
          ...evaluationData,    // Add each evaluation field directly to the profile
        };
      });

      setCombinedProfilesWithEvaluations(combinedProfiles);
      setSpotprocessAiJDSlcandidates(combinedProfiles)
    }
  }, [profiles, evaluations]);

  const handledSelectedJd = async (job) => {

    setSpotprocessSelectedJDID(job.id)
    setSpotprocessSelectedJD(job)

    setLoadingPrepareAIData(true)

    const fetchShortlistCandidate = async (jobid) => {

      try {

        const data = {
          id: jobid,
        };

        // Make the POST request
        const res = await fetch('https://jdwithai-e9e5hke8crfvcrg4.canadacentral-01.azurewebsites.net/shortlist-candidates', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });


        // Check if the request was successful
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }

        // Parse the JSON response
        const result = await res.json();
        console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeee", result)
        // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeee", result.detailed_profiles)
        // setServerEmails(result.detailed_profiles)
        // setEmails(result.emails)


        const { emails } = result;

        //   fetching profile and evaution code start

        const fetchCandidateProfiles = async (nextToken = null) => {
          const limit = 100; // Adjust limit as needed

          // Create the filter to match any of the emails
          const filter = {
            or: emails.map(email => ({ email: { eq: email } })),
          };

          try {
            const response = await API.graphql(
              graphqlOperation(listCandidateProfiles, {
                filter,
                limit,
                nextToken,
              })
            );

            const { items, nextToken: newNextToken } = response.data.listCandidateProfiles;

            // Update profiles state with fetched items
            setProfiles(prev => [...prev, ...items]);

            if (newNextToken) {
              // Fetch more items if there is a nextToken
              await fetchCandidateProfiles(newNextToken);
            }
          } catch (error) {
            console.error('Error fetching candidate profiles:', error);
          }
        };

        const handleFetch = async () => {
          setIsLoading(true);
          setProfiles([]); // Reset profiles on new fetch
          await fetchCandidateProfiles();
          setIsLoading(false);
        };


        // const [isFetchingEvaluations, setIsFetchingEvaluations] = useState(false);
        // const [evaluations, setEvaluations] = useState([]);

        const fetchInterviewEvaluations = async (nextToken = null) => {
          const limit = 100; // Adjust limit as needed

          // Create the filter to match any of the candidate emails
          const filter = {
            or: emails.map(email => ({ candidateEmail: { eq: email } })),
          };

          try {
            const response = await API.graphql(
              graphqlOperation(listInterviewEvaluations, {
                filter,
                limit,
                nextToken,
              })
            );

            const { items, nextToken: newNextToken } = response.data.listInterviewEvaluations;

            // Update evaluations state with fetched items
            setEvaluations(prev => [...prev, ...items]);

            if (newNextToken) {
              // Fetch more items if there is a nextToken
              await fetchInterviewEvaluations(newNextToken);
            }
          } catch (error) {
            console.error('Error fetching interview evaluations:', error);
          }
        };

        const handleEvalutionFetch = async () => {
          setIsFetchingEvaluations(true);
          setEvaluations([]); // Reset evaluations on new fetch
          await fetchInterviewEvaluations();
          setIsFetchingEvaluations(false);
        };

        await handleFetch()

        await new Promise(resolve => setTimeout(resolve, 10000));

        await handleEvalutionFetch()


      } catch (error) {

      }

    }

    await fetchShortlistCandidate(job.id)

    setLoadingPrepareAIData(false)

  }
  //  Selected Jd shorlist code end


  console.log("565656565656565656565656565656565656", profiles)
  console.log("676767676767676767676767676767676767", evaluations)
  console.log("78787878787878787878787878787878", combinedProfilesWithEvaluations)


  return (
    <>
      {spotprocessCompanyJDList.map((job) => (
        <div key={job.id} style={styles.card}
          onClick={() => handledSelectedJd(job)}
        >
          <img
            src={carbonCloseOutline}
            alt="Close"
            style={styles.closeButton}
            onClick={() => {
              console.log(`Job with ID ${job.id} closed`);
            }}
          />
          <h2 style={styles.title}>{job.jdName}</h2>
          <div style={styles.jdinfo}>
            {/* <div style={styles.subtitle}>
              {job.experience}
              <img src={line6} alt="Line" style={styles.line} />
            </div> */}
            <div style={styles.remote}>{job.workCategory}</div>
          </div>
          <p style={styles.description}>{job.summary}</p>
          {/* <div style={styles.skillsContainer}>
            {job.skills.map((skill, idx) => (
              <span key={idx} style={styles.skill}>
                {skill}
              </span>
            ))}
          </div> */}
          <div style={styles.skillsContainer}>
            {(() => {
              // Inline conversion logic for skills
              const skillsArray = typeof job.skills === 'string'
                ? job.skills
                  .replace(/[\[\]"]+/g, '') // Remove square brackets and quotes
                  .split(',')              // Split by comma
                  .map(skill => skill.trim()) // Trim whitespace from each skill
                : job.skills || []; // Use existing array if it's already an array

              // Get only the first three skills
              const displayedSkills = skillsArray.slice(0, 3);

              // Render skills with individual background
              return displayedSkills.length > 0
                ? displayedSkills.map((skill, index) => (
                  <React.Fragment key={index}>
                    <span
                      // className="inline-block bg-blue-200 text-black rounded mr-1"
                      style={styles.skill} // Example custom background color
                    >
                      {skill}
                    </span>
                    {index < displayedSkills.length - 1 && ' '} {/* Add comma if not the last item */}
                  </React.Fragment>
                ))
                : 'No skills available'; // Default message if no skills
            })()}
          </div>


          <div style={styles.sourceContainer}>
            <div style={styles.details}>
              <div style={styles.totalContainer}>
                <span style={styles.totalTitle}>Total</span>
                <span style={styles.totalCount}>{job.total}</span>
              </div>
              {/* <div style={styles.detailsSecound}>
                {job.sources.map((source, idx) => (
                  <div key={idx} style={styles.source}>
                    <span style={styles.sourceTitle}>{source.resumeBase}</span>
                    <span style={styles.sourceCount}>
                      {source.filterCandidate}
                    </span>
                  </div>
                ))}
              </div> */}
              {/* <div style={styles.detailsSecound}>
                <div style={styles.source}>
                  <span style={styles.sourceTitle}>Resume Base</span>
                  <span style={styles.sourceCount}>{job.resumeBase}</span>
                </div>
                <div style={styles.source}>
                  <span style={styles.sourceTitle}>Our Data Base</span>
                  <span style={styles.sourceCount}>
                    {job.filterCandidate}
                  </span>{" "}
                </div>
              </div> */}
            </div>
            <img
              src={informationCircleOutlineSvgrepoCom1}
              alt="Info"
              style={{ height: "24px", width: "24px" }}
            />
          </div>
        </div>
      ))}
    </>
  );
}

export default SpotPlusYourJd;
