import React, { useState, useRef } from "react";

import checkbox from "../../assets/checkbox.svg"; // Import the SVG image
import select from "../../assets/checkbox-select.svg"; // Import the SVG image
// Inline CSS with improved media queries for small screens
const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // minHeight: "100vh",
    backgroundColor: "rgb(221, 200, 255)",
    padding: "10px",
    width: "100%",
    gap: "10px",
    borderRadius: "4px",
  },
  svgContainer: {
    position: "relative",
    width: "100%",
    maxWidth: "400px", // Limit card width on large screens
    aspectRatio: "374 / 358", // Maintain SVG aspect ratio
  },
  cardContent: {
    position: "relative",

    display: "grid",

    alignItems: "center",
    justifyContent: "center",
    // width: "90%",
    color: "#333",
    // textAlign: "center",
  },
  title: {
    fontSize: "22px",
    fontWeight: "500",
    margin: "0 0 0 0",
  },
  subtitle: {
    fontSize: "1rem",
    color: "#30313a",
    margin: "0",
    padding: "0px",
  },
  interviewvideo: {
    background: "#000",
    width: "inherit",
    margin: "10px 0px",
    height: "16vh",
  },
  description: {
    fontSize: "0.9rem",
    color: "#555",
    // margin: "0px 10px",
  },
  skillsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "6px",
    marginBottom: "10px",
  },
  skill: {
    backgroundColor: "#E7E0FF",
    padding: "6px 10px",
    borderRadius: "8px",
    fontSize: "0.85rem",
    color: "#333",
  },
  button: {
    marginTop: "16px",
    padding: "12px",
    backgroundColor: "#6A0DAD",
    color: "white",
    border: "none",
    borderRadius: "10px",
    cursor: "pointer",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.3s",
  },
  buttonHover: {
    backgroundColor: "#5b0ca2",
  },
  checkbox: {
    marginLeft: "8px",
    backgroundColor: "transparent", // Corrected typo
    border: "1px solid #fff", // Added border property
    borderRadius: "4px",
  },

  fitScore: {
    backgroundColor: "#E0E7FF",
    color: "#333",
    padding: "5px 10px",
    borderRadius: "4px",
    fontSize: "0.85rem",
    marginLeft: "10px",
    width: "72%",
    textAlign: "center",
  },
  titleDiv: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
    gap: "8px",
    // marginRight: "76px",
  },

  Score: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    padding: "4px 8px",
    borderRadius: "2px",
    padding: "3px 8px",
    color: "#ffffff",
  },
  position: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    WebkitBackgroundClip: "text", // Camel case for -webkit-background-clip
    backgroundClip: "text",
    color: "transparent",
    padding: "0px 8px",
  },

  textWrapper: {
    color: "#fbfbfb",
    fontFamily: '"Roboto-Regular", Helvetica',
    fontSize: "22px",
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: "normal",
    position: "relative",
    whiteSpace: "nowrap",
    width: "fit-content",
  },
  descriptionContainer: {
    width: "100%",
    height: "auto",
    margin: "10px 0px",
    overflow: "hidden", // Hide content that overflows
  },
  description: {
    fontSize: "0.9rem",
    color: "#555",
    // transition: "opacity 0.3s ease, transform 0.3s ease",
  },
  interviewvideo: {
    background: "#000",
    width: "inherit",
    height: "16vh",
    // transition: "opacity 0.3s ease, transform 0.3s ease",
    // opacity: 0,
    // transform: "translateY(100%)",
  },
  toppick: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    width: "fit-content",
    padding: "2px 10px",
    borderRadius: "6px 0px",
    margin: " -1px 0px 4px 0px",
    color: "#fff",
    fontWeight: "100",
  },
  // Media Queries for better responsiveness
  "@media (max-width: 768px)": {
    svgContainer: {
      maxWidth: "300px",
    },
    title: {
      fontSize: "20px",
    },
    subtitle: {
      fontSize: "0.9rem",
    },
    description: {
      fontSize: "0.85rem",
    },
    skill: {
      fontSize: "0.8rem",
      padding: "5px 8px",
    },
  },
  "@media (max-width: 480px)": {
    svgContainer: {
      maxWidth: "100%",
    },
    title: {
      fontSize: "18px",
    },
    subtitle: {
      fontSize: "0.85rem",
    },
    description: {
      fontSize: "0.75rem",
    },
    button: {
      padding: "10px",
    },
    titleDiv: {
      flexDirection: "column",
      marginRight: "0",
      gap: "4px",
    },
    fitScore: {
      marginLeft: "0",
      marginTop: "5px",
    },
  },
  "@media (max-width: 400px) to  @media (max-width: 349px)": {
    svgContainer: {
      maxWidth: "90%",
      aspectRatio: "1 / 1",
    },
    title: {
      fontSize: "16px",
    },
    description: {
      fontSize: "0.75rem",
    },
    button: {
      padding: "8px",
    },
  },
  "@media (max-width: 350px)": {
    svgContainer: {
      maxWidth: "95%",
      aspectRatio: "1 / 1",
    },
    title: {
      fontSize: "14px", // Adjusted for extra-small screens
    },
    subtitle: {
      fontSize: "0.75rem",
    },
    description: {
      fontSize: "0.7rem",
    },
    skill: {
      fontSize: "0.75rem",
      padding: "4px 6px",
    },
    button: {
      padding: "6px", // Reduced padding for smaller screens
    },
  },
};

function SpotPlusProfileCard({ card, isSelected, onSelect }) {
  const [hover, setHover] = React.useState(false);
  const videoRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxClick = () => {
    setIsChecked(!isChecked);
  };
  const handleCardClick = () => {
    onSelect(card.id);
  };
  const handleMouseEnter = () => {
    setHover(true);
    if (videoRef.current) {
      videoRef.current.style.opacity = 1;
      videoRef.current.style.transform = "translateY(0)";
      // Ensure video takes up space even if paragraph is removed
      videoRef.current.style.height = "auto";
      videoRef.current.style.visibility = "visible";
    }
  };

  const handleMouseLeave = () => {
    setHover(false);
    if (videoRef.current) {
      videoRef.current.style.opacity = 0;
      videoRef.current.style.transform = "translateY(100%)";
      // Collapse video height for smooth transition
      videoRef.current.style.height = "0px";
      videoRef.current.style.visibility = "hidden";
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.svgContainer}>
        {/* <svg
          width="100%"
          height="100%"
          viewBox="0 0 374 358"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid slice"
        >
          <path
            d="M367.161 42.451C367.161 61.1313 352.018 76.2747 333.338 76.2747C314.657 76.2747 299.514 61.1313 299.514 42.451C299.514 23.7707 314.657 8.62737 333.338 8.62737C352.018 8.62737 367.161 23.7707 367.161 42.451Z"
            fill="url(#pattern0)"
            stroke="white"
            strokeWidth="0.856"
          />
          <path
            d="M0 28.0139C0 12.648 12.4565 0.191406 27.8224 0.191406H260.742C271.269 0.191406 295.422 15.1059 295.422 47.9588C295.422 58.0381 305.698 86.9615 338.665 81.2644C352.654 78.847 375.486 93.0967 373.773 112.817V329.709C373.773 345.075 361.316 357.531 345.951 357.531H27.8224C12.4565 357.531 0 345.075 0 329.709V28.0139Z"
            fill="#DDC8FF"
          />
          <defs>
            <pattern id="pattern0" width="1" height="1">
              <use xlinkHref="#image0" transform="scale(0.00390625)" />
            </pattern>
            <image
              id="image0"
              width="256"
              height="256"
              href="https://upload.wikimedia.org/wikipedia/commons/4/47/PNG_transparency_demonstration_1.png"
            />
          </defs>
        </svg> */}

        <div style={styles.cardContent}>
          <div style={styles.toppick}>Top Picked</div>
          <div>
            <div style={styles.titleDiv}>
              <h2 style={styles.title}>Sachin Gadekar</h2>
              {/* <div style={styles.fitScore}>
                Fit Score: <span style={styles.Score}>65/100</span>{" "}
              </div> */}
            </div>
            <p style={styles.subtitle}>
              5 Years of Experience<span style={styles.position}>•</span>Remote
            </p>
          </div>

          <div
            style={styles.descriptionContainer}
            onMouseLeave={handleMouseLeave}
          >
            {/* Conditionally render the paragraph or video */}

            <p style={styles.description} onMouseEnter={handleMouseEnter}>
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in 45 BC, making it over 2000 years old.
            </p>
          </div>
          <div style={styles.skillsContainer}>
            <span style={styles.skill}>HTML</span>
            <span style={styles.skill}>CSS</span>
            <span style={styles.skill}>JS</span>
            <span style={styles.skill}>Artificial Intelligence</span>
          </div>
          <button
            style={
              hover
                ? { ...styles.button, ...styles.buttonHover }
                : styles.button
            }
            // onMouseEnter={() => setHover(true)}
            // onMouseLeave={() => setHover(false)}
            onClick={handleCheckboxClick} // Handle click on the button
          >
            <div className="text-wrapper">Select Candidate</div>
            {/* Conditionally render checkbox or select image */}
            <div
              style={isSelected ? styles.selectedCard : styles.card}
              onClick={handleCardClick}
            >
              {/* ... your card content ... */}
              {/* <p>Card ID: {card.id}</p> */}
              {/* <p>Card Title: {card.title}</p> */}
              {/* Display the card title */}
            </div>
            {isChecked ? (
              <img
                className="fluent-select-all-on"
                alt="Fluent select all on"
                src={select}
              />
            ) : (
              <img
                className="fluent-select-all-on"
                alt="Fluent select all on"
                src={checkbox}
              />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default SpotPlusProfileCard;
