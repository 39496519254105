import React from "react";
import close from "../../assets/closesearchbar.svg";

function SpotPlusSearch({ onClose }) {
  return (
    <div style={styles.main}>
      <div style={styles.container}>
        <div style={styles.closeContainer}>
          <img src={close} onClick={onClose} />
        </div>

        <div style={styles.header}>
          <div style={styles.button}>
            Can you provide the candidates with the highest overall interview
            scores?
          </div>
          <div style={styles.button}>Show candidates' specific skills?</div>
          <div style={styles.button}>
            Show me candidates who match at least 90% of the job requirements?
          </div>
        </div>
        <div style={styles.inputContainer}>
          <input style={styles.input} type="text" placeholder="Ask AI..." />
          <button style={styles.sendButton}>&#x27A4;</button>
        </div>
      </div>
    </div>
  );
}

const styles = {
  main: {
    width: "100%",
    background: "#000",
    height: "100vh",
    display: "flex",
    alignItems: " center",
    opacity: "70%",
  },
  container: {
    width: "300px",
    backgroundColor: "#E9D9FF",
    borderRadius: "4px 32px 4px 4px",
    padding: "15px",
    fontFamily: "'Arial', sans-serif",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    color: "#000",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginBottom: "15px",
  },
  button: {
    padding: "2px 12px",
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    color: "#fff",
    border: "none",
    borderRadius: "45px",
    textAlign: "left",
    fontSize: "12px",
    cursor: "pointer",
  },
  buttonHover: {
    opacity: "0.8",
  },
  inputContainer: {
    display: "flex",
    alignItems: "end",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    border: "1px solid #CCC",
    borderRadius: "5px",
    padding: "5px",
    height: "40vh",
  },
  input: {
    flex: 1,
    border: "none",
    outline: "none",
    fontSize: "14px",
    padding: "8px",
    boxSizing: "border-box",
    // Remove height or set to auto if it exists
    height: "auto", // Or remove this line entirely
    resize: "none", // Prevent manual resizing of the input
  },

  sendButton: {
    padding: "5px 10px",
    backgroundColor: "#6C47B4",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
  },

  closeContainer: {
    display: "flex",
    justifyContent: "end",
    margin: "8px 0px",
  },
};

export default SpotPlusSearch;
