import React, { useEffect, useRef, useState } from 'react'
import { API, Storage, graphqlOperation } from "aws-amplify";
import jsPDF from 'jspdf';
import { createSpotJD } from '../../graphql/mutations';
import { useNavigate } from 'react-router-dom';

const SpotplusManualForm = ({ onClose }) => {

  const [jdIsModalOpen, setJdIsModalOpen] = useState(false);
  const [jdFormData, setJdFormData] = useState({
    email: '',
    phone: '',
    age: '',
    address: '',
    country: '',
  });

  const [jobTitle, setJobTitle] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedExperience, setSelectedExperience] = useState("");
  const [skill, setSkill] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [compensation, setCompensation] = useState("");
  const [benefits, setBenefits] = useState("");
  const [workMode, setWorkMode] = useState("");
  const [companyEmail, setCompanyEmail] = useState('');

  const compensationRef = useRef(null);
  const [companyjobcount, setcompanyjobcount] = useState(false);
  const [createjdIsApiWorking, setCreatejdIsApiWorking] = useState(false);

  const navigate = useNavigate()



  useEffect(() => {

    const email = localStorage.getItem('CompanyEmailID');
    if (email) {
      setCompanyEmail(email);
    }

  }, [])

  // create jd code start

  const handleJdChange = (e) => {
    setJdFormData({ ...jdFormData, [e.target.name]: e.target.value });
  };

  const handleJdSubmit = (e) => {
    e.preventDefault();
    console.log(jdFormData);
    // You can handle form submission here (e.g., send data to an API)
    setJdIsModalOpen(false); // Close modal after submission
  };


  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const handleExperienceSelect = (experience) => {
    setSelectedExperience(experience);
  };



  const renderCategoryButtons = () => {
    const categories = [
      "Full-time",
      "Part-time",
      "Contract",
      "Temporary",
      "Internship",
    ];
    return categories.map((category, index) => (
      <button
        key={index}
        style={{
          padding: "5px 15px",
          backgroundColor:
            selectedCategory === category ? "#9900ff" : "#f3f3f3",
          borderRadius: "15px",
          cursor: "pointer",
          transition: "background-color 0.3s",
          marginRight: "5px",
          marginBottom: "5px",
          color: selectedCategory === category ? "#fff" : "#000",
          border: "1px solid #9900ff",
        }}
        onClick={() => handleCategorySelect(category)}
      >
        {category}
      </button>
    ));
  };

  const renderExperienceButtons = () => {

    // original code start

    // const experienceLevels = [
    //   { label: "Fresher\nJust out of college", value: "Fresher" },
    //   { label: "Entry Level\n1-3 years", value: "Entry Level" },
    //   { label: "Mid Level\n3-5 years", value: "Mid Level" },
    //   { label: "Senior Level\n5+ years", value: "Senior Level" },
    // ];

    // original code end

    const experienceLevels = [
      { label: "Fresher\nJust out of college", value: "Fresher" },
      { label: "Entry Level\n1-3 years", value: "Entry Level 1-3 years" },
      { label: "Mid Level\n3-5 years", value: "Mid Level 3-5 years" },
      { label: "Senior Level\n5+ years", value: "Senior Level 5+ years" },
    ];

    return experienceLevels.map((experience, index) => (
      <button
        key={index}
        style={{
          backgroundColor:
            selectedExperience === experience.value ? "#9900ff" : "#1b1935",
          border: "1px solid #9900ff",
          borderRadius: "5px",
          padding: "15px 10px",
          textAlign: "center",
          fontSize: "14px",
          cursor: "pointer",
          flexGrow: "1",
          margin: "0 5px",
          transition: "border-color 0.3s ease",
        }}
        onClick={() => handleExperienceSelect(experience.value)}
      >
        {experience.label}
      </button>
    ));
  };


  const handleWorkModeSelect = (mode) => {
    setWorkMode(mode);
  };

  const renderWorkModeOptions = () => {
    const workModes = ["Work from home", "Remote", "Work from office"];

    return workModes.map((mode, index) => (
      <button
        key={index}
        style={{
          padding: "5px 15px",
          backgroundColor: workMode === mode ? "#9900ff" : "#f3f3f3",
          borderRadius: "15px",
          cursor: "pointer",
          transition: "background-color 0.3s",
          marginRight: "5px",
          marginBottom: "5px",
          color: workMode === mode ? "#fff" : "#000",
          border: "1px solid #9900ff",
        }}
        onClick={() => handleWorkModeSelect(mode)}
      >
        {mode}
      </button>
    ));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const skillArray = skill.split(',').map(s => s.trim());
    // Check if all mandatory fields are filled out
    if (
      !jobTitle ||
      !selectedCategory ||
      !skill ||
      !jobDescription ||
      !workMode // Check if work mode is selected
    ) {
      alert("Please fill out all mandatory fields.");
      return;
    }

    setCreatejdIsApiWorking(true);

    // create JD code working start 

    const doc = new jsPDF();

    // Add content to the PDF
    doc.text(`Job Title: ${jobTitle}`, 10, 10);
    doc.text(`Category: ${selectedCategory}`, 10, 20);
    doc.text(`Skills: ${skillArray.join(', ')}`, 10, 30);
    doc.text(`Description: ${jobDescription}`, 10, 40);
    doc.text(`Work Mode: ${workMode}`, 10, 50);

    // Save the PDF
    // doc.save('job-posting.pdf');

    // Generate a unique filename using 
    const sanitizedJobTitle = jobTitle.replace(/[^a-z0-9]/gi, '_').toLowerCase(); // Sanitize job title for filename
    const timestamp = new Date().toISOString().replace(/[:.-]/g, '');
    const randomNum = Math.floor(Math.random() * 10000); // Random number between 0 and 9999
    const uniqueFilename = `${sanitizedJobTitle}-job-posting-${timestamp}-${randomNum}.pdf`;

    // Convert PDF to Blob
    const pdfBlob = doc.output('blob');

    const pdfFile = {
      blob: pdfBlob,
      name: uniqueFilename,
    };

    try {

      // let companyEmail = "companyEmail";

      let email = companyEmail

      const timestamp = Date.now();
      const uniqueFileName = `${timestamp}_${pdfFile.name}`;
      // Upload the PDF to S3

      const folder = "SpotJD/"; // Folder name in S3
      const yourS3BucketName = "getscreenedstoragebucketf24ed-devget";
      const s3Key = `${folder}${uniqueFileName}`; // Combine folder and file name

      // Upload to S3
      const uploadResult = await Storage.put(s3Key, pdfBlob, {
        contentType: pdfBlob.type,
      });

      const fileUrl = await `https://${yourS3BucketName}.s3.amazonaws.com/public/${uploadResult.key}`;

      const input = {
        companyEmail: email,
        jdURL: fileUrl, // S3 bucket URL
      };


      const response = await API.graphql(graphqlOperation(createSpotJD, { input }));
      console.log(response);


      const { data: { createSpotJD: { id } } } = response;

      const data = {
        id: id,
      };


      // Make the POST request
      const res = await fetch('https://jdwithai-e9e5hke8crfvcrg4.canadacentral-01.azurewebsites.net/extract-job-details', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!res.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await res.json();

      // window.location.reload();
      await localStorage.setItem('RecentJDIdAfterJDupload', JSON.stringify(result.updated_jd_details));

      await navigate("/spotpluscandidte")



    } catch (error) {
      console.error("Error uploading file or updating GraphQL:", error);
      alert("An error occurred. Please try again."); // Alert user on error
    } finally {
      setCreatejdIsApiWorking(false); // Reset the loading state
    }

    // create JD code end 

  }

  return (
    <div>
      <div>
        <div className="p-4 overflow-y-auto max-h-[600px] bg-gray-100 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-6 text-center text-black">Basic Details Form</h2>

          <div className="mb-6">
            <label htmlFor="job-title" className="block text-gray-700 mb-2">
              * Job Title
            </label>
            <input
              type="text"
              id="job-title"
              placeholder="Enter job title"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
            />
          </div>

          <div className="mb-6">
            <label htmlFor="job-category" className="block text-gray-700 mb-2">
              * Job Category
            </label>
            <div className="flex flex-wrap gap-2">
              {renderCategoryButtons()}
            </div>
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 mb-2">* Work Mode</label>
            <div className="flex flex-wrap gap-2">
              {renderWorkModeOptions()}
            </div>
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 mb-2">Experience Level</label>
            <div className="flex justify-between p-2 border border-gray-300 rounded">
              {renderExperienceButtons()}
            </div>
          </div>

          <div className="mb-6">
            <label htmlFor="skill" className="block text-gray-700 mb-2">
              * Skill
            </label>
            <input
              type="text"
              id="skill"
              placeholder="Enter skills separated by comma"
              value={skill}
              onChange={(e) => setSkill(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
            />
          </div>

          <div className="mb-6">
            <label htmlFor="job-description" className="block text-gray-700 mb-2">
              * Job Description
            </label>
            <textarea
              id="job-description"
              placeholder="Enter job description"
              rows="4"
              value={jobDescription}
              onChange={(e) => setJobDescription(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
            ></textarea>
          </div>

          <div ref={compensationRef}>
            <div className="mb-6">
              <label htmlFor="compensation" className="block text-gray-700 mb-2">
                Compensation
              </label>
              <input
                type="text"
                id="compensation"
                placeholder="eg : 6LPA-12LPA"
                value={compensation}
                onChange={(e) => setCompensation(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
              />
            </div>

            <div className="mb-6">
              <label htmlFor="benefits" className="block text-gray-700 mb-2">
                Benefits
              </label>
              <textarea
                id="benefits"
                placeholder="Enter benefits details"
                rows="4"
                value={benefits}
                onChange={(e) => setBenefits(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
              ></textarea>
            </div>

            <div className="flex justify-end">
              <button
                type="button"
                // onClick={() => navigate("/Spotsearch")} // Navigate to 
                // style={styles.navButton}
                // onClick={() => { setJdIsModalOpen(false) }}
                onClick={onClose}
                className="px-4 py-2 mr-2 text-white bg-red-600 rounded hover:bg-red-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={handleSubmit}
                // style={styles.navButton}
                disabled={createjdIsApiWorking}
                className="px-4 py-2 text-white bg-green-600 rounded hover:bg-green-500"
              >
                {/* Submit */}
                {createjdIsApiWorking ? 'Loading...' : 'Create JD'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SpotplusManualForm
