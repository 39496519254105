import React from 'react'
import { useState } from 'react';
import { createContext } from 'react';

export const SpotContext = createContext();

export const SpotProvider = ({ children }) => {

  // SPOT GPT code start

  const [companyjdlist, setCompanyjdlist] = useState([]);
  const [selectedjdid, setSelectedjdid] = useState("")
  const [selectedjdname, setSelectedjdname] = useState("")
  const [jdId, setJdId] = useState("")
  const [candidates_referring_to, setCandidates_referring_to] = useState([])

  // SPOT Process Code start
  const [spotprocessCompanyJDList, setSpotprocessCompanyJDList] = useState([])
  const [spotprocessSelectedJDID, setSpotprocessSelectedJDID] = useState("")
  const [spotprocessSelectedJD, setSpotprocessSelectedJD] = useState({})
  const [spotprocessSelectedJDName, setSpotprocessSelectedJDName] = useState("")
  const [spotprocessSelectedResume, setSpotprocessSelectedResume] = useState({})
  // Shorlisted from API 
  const [spotprocessAiJDSlcandidates, setSpotprocessAiJDSlcandidates] = useState([])      // sl = ShortListed
  const [spotprocessAiResumeSlCandidates, setSpotprocessAiResumeSlCandidates] = useState([])      // sl = ShortListed
  // outrich selected candidate 
  const [spotprocessAiJDSselectedCandidates, setSpotprocessAiJDSselectedCandidates] = useState([])
  const [spotprocessAiResumeselectedCandidates, setSpotprocessAiResumeselectedCandidates] = useState([])
  // prepare AI and Resume loading animation code 
  const [loadingPrepareAIData, setLoadingPrepareAIData] = useState(false); // Loading state for job data
  const [loadingPrepareAIResumeData, setLoadingPrepareAIResumeData] = useState(false); // Loading state for resume    

  // SPOT Process Code end


  return (
    <SpotContext.Provider value={{
      companyjdlist,
      setCompanyjdlist,
      setSelectedjdid,
      selectedjdid,
      selectedjdname,
      setSelectedjdname,
      setCandidates_referring_to,
      candidates_referring_to,
      setSpotprocessCompanyJDList,
      spotprocessCompanyJDList,
      setSpotprocessSelectedJDID,
      setSpotprocessSelectedJD,
      spotprocessSelectedJD,
      setSpotprocessAiJDSlcandidates,
      spotprocessAiJDSlcandidates,
      setSpotprocessAiResumeSlCandidates,
      spotprocessAiResumeSlCandidates,
      setSpotprocessAiJDSselectedCandidates,
      spotprocessAiJDSselectedCandidates,
      setSpotprocessAiResumeselectedCandidates,
      spotprocessAiResumeselectedCandidates,
      setLoadingPrepareAIData,
      loadingPrepareAIData,
      setLoadingPrepareAIResumeData,
      loadingPrepareAIResumeData,
      setSpotprocessSelectedResume,
      spotprocessSelectedResume

    }}>
      {children}
    </SpotContext.Provider>
  )
}

// export default SpotContext
