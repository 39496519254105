import React from "react";

function SpotPlusScoreCard() {
  return (
    <div>
      <div style={styles.fitscore}>
        <h2 style={styles.fitscoretext}>Fit Score</h2>
        <div style={styles.fitscoremeter}>
          <div style={styles.meterContainer}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="161"
              height="136"
              viewBox="0 0 265 136"
              fill="none"
              style={styles.colorpanal}
            >
              <path
                d="M4.18213 135.488C1.17207 134.668 -0.0234875 131.163 0.0921958 128.045C1.27165 96.2594 13.8491 65.9469 35.5239 42.6623C37.5818 40.4515 40.7051 38.8666 43.4715 40.0789C44.5991 40.5729 45.3827 41.3465 45.8665 42.5777C46.8946 45.1939 45.3442 48.0048 43.4295 50.0627C23.6208 71.3535 12.092 99.0384 10.9276 128.085C10.8035 131.18 9.69243 134.689 6.70234 135.496C5.86714 135.722 5.04712 135.723 4.18213 135.488Z"
                fill="#CE1616"
              />
              <path
                d="M127.783 0.511058C130.807 0.402578 134.217 1.48922 135.136 4.37209C135.434 5.30736 135.44 6.23269 135.115 7.23852C134.177 10.1423 130.788 11.229 127.739 11.3493C101.91 12.3679 77.1549 21.5817 57.0096 37.5254C54.6619 39.3835 51.4975 40.7239 48.8582 39.3102C47.918 38.8066 47.2651 38.1458 46.8273 37.2705C45.4516 34.5205 47.3754 31.3617 49.777 29.4416C71.9287 11.7311 99.2671 1.53409 127.783 0.511058Z"
                fill="#EDBA00"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M258.188 135.492C255.313 134.629 254.274 131.249 254.158 128.249C253.155 102.361 243.919 77.5462 227.925 57.3664C225.676 54.53 224.326 50.102 227.105 47.783C229.895 45.4542 233.857 47.4397 236.125 50.2801C253.744 72.3513 263.91 99.561 264.983 127.951C265.103 131.123 263.87 134.693 260.807 135.525C259.928 135.763 259.077 135.759 258.188 135.492Z"
                fill="#378220"
              />
              <path
                d="M215.067 43.5077C217.352 45.6158 220.567 47.2219 223.365 45.8661C224.457 45.3367 225.174 44.5914 225.62 43.5549C226.731 40.9762 225.156 38.1149 223.107 36.1956C202.043 16.4678 175.311 4.23203 146.639 1.17645C143.208 0.810732 139.061 2.09389 138.619 5.51662C138.529 6.21363 138.591 6.89998 138.806 7.61176C139.656 10.4328 142.827 11.6541 145.756 11.974C171.563 14.7923 195.881 25.8067 215.067 43.5077Z"
                fill="#6FB13A"
              />
            </svg>
            <div style={styles.zeroLabel}>0</div> {/* Label for 0 */}
            <div style={styles.hundredLabel}>100</div> {/* Label for 100 */}
          </div>
          {/* <div>
                  <div>0</div>
                  <div>100</div>
                </div> */}
          <div style={styles.score}>
            <h2 style={styles.scorenumber}>65</h2>
          </div>

          <div style={styles.remark}>
            <div style={styles.remarktext}>Medium</div>
          </div>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="37"
            viewBox="0 0 37 37"
            fill="none"
            style={styles.knoch}
          >
            <circle cx="18.2666" cy="18.1904" r="9.94039" fill="#D8E1F1" />
            <circle
              cx="18.2666"
              cy="18.1904"
              r="13.9165"
              stroke="#FFFEFE"
              stroke-opacity="0.33"
              stroke-width="7.95231"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
const styles = {
  rectanglesplit: {
    display: "flex",
  },
  // Main container to hold both box and JD panel
  mainContainer: {
    display: "flex",
    height: "100vh",
  },

  // JD Card Panel styles
  jdPanel: {
    backgroundColor: "#f0f0f0", // Example background color
    overflowX: "hidden", // Hide content when panel is closed
    position: "relative", // Allow absolute positioning of children

    justifyContent: "center",
    paddingTop: "16px",
    // padding: "12px",
    alignSelf: "center",
    gap: "8px",
    display: "ruby",
    // left: "32px",
    height: "86vh",
  },
  body: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
  },
  box: {
    height: " 100vh",
    width: "100%",
    // Add position: relative to allow absolute positioning of the button
    position: "relative",
  },

  // Style for the right-side button
  rightButton: {
    position: "absolute",
    top: "50%", // Position at 50% from the top
    // right: "-16px",
    // Adjust distance from the right edge as needed
    transform: "translateY(-50%) rotate(0deg)", // Add rotation
    padding: "10px 20px",

    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    zIndex: "1",
  },
  header: {
    background: "#000",
    width: "100%",
    // height: "12%",
    display: "flex",
    alignItems: "center",
  },
  //   hr: {
  //     width: "inherit",
  //   },
  rectangle: {
    backgroundColor: "#d9d9d91a",
    border: " 1px solid #d9d9d97a",
    borderRadius: "16px",
    boxShadow: "0px 4px 4px #00000040",
    // height: "80%",
    left: "0",

    top: " 0",
    width: "98%",
    margin: "14px",
  },
  internalheader: {
    // background: "#000",
    width: "100%",
    // height: "12%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  jobtitle: {
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    // width: "50%",
    paddingLeft: "10px",
    marginBottom: "0rem",
  },
  miidlebox: {
    // background: "#000",
    // width: "100%",
    // height: "90%",
    // marginTop: "10px",
    display: "flex",
    margin: "1% 2%",
    gap: "56px",
  },
  leftmidbox: {
    backgroundColor: "transparent",

    // width: "10%",
    // height: "92%",
    display: "grid",
    // alignItems: "center",
    justifyContent: "center",
    // position: "absolute",
    // gap: "82px",
  },
  sidenav: {
    backgroundColor: "#ffffff1a",
    border: "1px solid #ffffff33",
    borderRadius: "20px",
    width: "65px",
    height: "278px",
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer", // Make the whole area look clickable
  },
  rightmidbox: {
    background: "transparent",
    width: "100%",
    alignItems: "start",
    justifyContent: "start",
    position: "relative",
    borderRadius: "8px",
    height: "63vh",
    overflowX: "hidden",
  },
  cardstructure: {
    display: "grid", // Use grid layout
    gridTemplateColumns: "repeat(auto-fit, minmax(400px, 3fr))", // Responsive columns
    gap: "24px",
    padding: "10px", // Add padding for better spacing
  },
  toggle: {
    width: "100%",
    // height: "15vh",
    position: "relative",
  },
  icon: {
    width: "65px", // Default size for the big icon
    position: "absolute",
    cursor: "pointer",
  },
  iconHidden: {
    width: "32px", // Size for the small icon
    position: "absolute",
    top: "40px", // Adjust position to match your design
    left: "44px", // Adjust position to match your design
    cursor: "pointer",
  },

  ailogo: {
    width: "65px",
    position: "absolute",
  },
  searchbar: {
    width: "32px",
    position: "absolute",
    top: "40px",
    left: "44px",
    zIndex: "1",
  },
  container: {
    position: "relative",
    display: "inline-block",
    padding: "10px",
    // width: "10%",
    display: "flex",
    width: "50%",
    // gap: "16px",
  },
  leftcontainer: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  svg: {
    width: "100%",
  },
  textselect: {
    position: "absolute",
    fontSize: "14px",
    fontWeight: "bold",
    background: "linear-gradient(180deg, #667EEA 0%, #8859B8 100%)", // Added gradient background
    backgroundClip: "text", // Added for gradient clipping
    WebkitBackgroundClip: "text", // Added for WebKit browsers
    WebkitTextFillColor: "transparent", // Make text transparent for gradient to show
    pointerEvents: "none",
    paddingLeft: "0px",
  },

  text: {
    position: "absolute",
    // top: "45%",
    // left: "18%",

    // transform: "translate(-50%, -50%)",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#000",
    pointerEvents: "none", // Prevents text from interfering with SVG interactions
    paddingLeft: "0px",
  },
  breadmain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sidebar: {
    width: "98px",
    height: "73px",
    backgroundColor: "#ffffff1a",
    border: " 1px solid #ffffff33",
    position: "relative",
    borderRadius: "20px",
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
    marginTop: "20px",
    marginBottom: "0px",
    marginRight: "20px",
  },
  sidebarnavigation: {
    width: "98px",
    height: "179px",
    backgroundColor: "#ffffff1a",
    border: " 1px solid #ffffff33",
    borderRadius: "20px",
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
  },
  createjdbutton: {
    backgroundColor: "rgba(255, 255, 255, 1.0)",
    borderTop: " 1px solid rgba(255, 255, 255, 0.4)",

    width: "98px",
    height: "20px",
    borderRadius: "0px 0px 20px 20px",
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    fontWeight: "100",
    color: "#fff",
  },
  createjd: {
    top: "13px",
    position: "absolute",
    left: "28px",
  },
  uploadjd: {},
  main: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
  },
  createjd: {
    top: "13px",
    position: "absolute",
    left: "28px",
  },
  uploadjd: {},
  main: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
  },
  box2: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    border: "1px solid #ffffff7a",

    borderRadius: "18px",
    height: "24px",
    width: "24px",
    position: "absolute",
    top: "-13px",
    left: "81px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  spotanimationlogo: {
    width: "50%",
  },
  inputdiv: {
    width: "98%",

    // position: "absolute",
    margin: "4px 12px",
  },
  formdiv: {
    border: "1px solid #fff",
    width: "98%",
    height: "5vh",
    // position: "absolute",
    margin: "8px 0px",
    borderRadius: "4px",

    background: "transparent",
  },

  description: {
    border: "1px solid #fff",
    width: "98%",
    height: "80px",
    // position: "absolute",
    margin: "8px 0px",
    borderRadius: "4px",
    background: "transparent",
  },

  Experincetitle: {
    appearance: "none", // Hide default arrow
    padding: "8px 12px", // Adjust padding to give room for the icon
    borderRadius: "4px",
    border: "1px solid #ccc",
    backgroundColor: "#fffefe", // Solid background for dropdown
    backgroundImage: `
        linear-gradient(45deg, transparent 50%, rgb(102, 126, 234) 50%), 
        linear-gradient(135deg, rgb(136, 89, 184) 50%, transparent 50%)
      `, // Gradient-colored arrow
    backgroundPosition: `
        calc(100% - 15px) calc(1em + 2px), 
        calc(100% - 10px) calc(1em + 2px)
      `,
    backgroundSize: "5px 5px, 5px 5px",
    backgroundRepeat: "no-repeat",
    color: "#333", // Optional text color for dropdown text
    width: "34%",
  },
  form: {
    position: "absolute",
    width: "98%",
    display: "flex",
    flexDirection: "column", // Align items in a column
    alignItems: "flex-end",
  },
  Experince: {
    display: "flex",
    alignItems: "center",
    marginTop: "12px",
    justifyContent: "space-around",
  },
  underExperince: {
    width: "61%",
  },
  title: {
    margin: "10px",
  },
  worktype: {
    display: "flex",
    margin: "12px",
    alignItems: "center",
    gap: "12px",
    marginTop: "12px",
  },
  worktypename: {
    border: "1px solid #fff",
    // width: "260px",
    // height: "10px",
    padding: "5px 20px",
    borderRadius: "4px",
  },

  // Style for selected work type
  selectedWorkType: {
    backgroundColor: "#667EEA", // Example: Highlight selected type
    color: "#fff",
  },
  submit: {
    border: "1px solid #fff",
    padding: "5px 20px",
    borderRadius: "4px",
    margin: "10px 40px",
  },
  frame: {
    alignItems: "center",
    backgroundColor: "#ddc8ff",
    border: "0.5px solid",
    borderColor: "#d9d9d952",
    borderRadius: "32px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    height: "50px",
    justifyContent: "center",
    padding: "11px 18px",
    position: "relative",
    width: "50px",
    cursor: "pointer", // Add cursor pointer to indicate clickability
  },

  // Style for the selected frame
  selectedFrame: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)", // Example: Highlight selected frame
  },
  // Nested style for .frame .text-wrapper
  textWrapper: {
    color: "#ffffff",
    fontFamily: '"Roboto-Regular", Helvetica',
    fontSize: "22.8px",
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: "normal",
    position: "relative",
    whiteSpace: "nowrap",
    width: "fit-content",
  },
  filter: {
    display: "flex",
    gap: "12px",
  },
  togglediv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "32px",
    margin: "16px",
    background: "#DDC8FF",
    padding: "6px 16px",
    borderRadius: "24px",
  },
  toggleinterviewdetails: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    color: " black",
    padding: "8px 32px",
    borderRadius: "22px",
  },
  toggleinterviewdetailsof: {
    // Styles for INACTIVE tabs
    padding: "4px 32px",
    borderRadius: "22px",
    color: "#000", // Or a lighter color that contrasts with the active tab's background
    background: "transparent", // Or a subtle background color if you prefer
    border: "1px solid #DDC8FF", // Optional thin border
    cursor: "pointer", // Indicate clickability
  },
  viewdetails: {
    backgroundColor: "#000",
    height: "100vh", //after adding contain remove height
    borderRadius: "8px",
  },
  activeTab: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    color: "#fff", // White text for better contrast
    // Add any other styles you want for the active tab, e.g., border, box-shadow, etc.
  },
  fitscore: {
    background: "#000",
    width: "95%",
    height: "40vh",
    margin: "8px",
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
  },
  profileCard: {
    background: "#000",
    width: "95%",

    margin: "8px",
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
  },
  fitscoremeter: {
    width: "177px",
    height: "177px",
    backgroundColor: "#fff",
    borderRadius: "66%",
    justifyContent: "center",
    display: "flex",
    position: "relative",
  },
  knoch: {
    position: "absolute",
  },
  score: {
    position: "absolute", // Absolutely position within the circle
    top: "50%", // Position vertically in the middle
    left: "50%", // Position horizontally in the middle
    transform: "translate(-50%, -50%)", // Adjust for element's size
    color: "#000",
  },

  remark: {
    position: "absolute", // Absolutely position within the circle
    bottom: "10px", // Adjust distance from bottom as needed
    left: "50%", // Position horizontally in the middle
    transform: "translateX(-50%)", // Adjust for element's size
    color: "#000",
    top: "54%",
  },
  remarktext: {
    fontSize: "1.7rem",
    fontWeight: "600",
    color: "#6FB13A",
  },
  scorenumber: {
    fontSize: "2.5rem",
  },

  fitscoretext: {
    justifySelf: "center",
    marginBottom: "0px",
  },
  colorpanal: {
    marginTop: "-72px",
  },
  meterContainer: {
    position: "relative", // Required for absolute positioning of labels
    width: "177px", // Same as your fitscoremeter width
    height: "177px", // Same as your fitscoremeter height
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  zeroLabel: {
    position: "absolute",
    left: "8px", // Adjust as needed
    top: "60%",
    transform: "translateY(-50%)", // Center vertically
    color: "#000", // Or whatever color you want
  },
  hundredLabel: {
    position: "absolute",
    right: "4px", // Adjust as needed
    top: "60%",
    transform: "translateY(-50%)", // Center vertically
    color: "#000", // Or whatever color you want
  },
  backtitle: {
    display: " flex",
    alignItems: "center",
    justifyContent: "center",
  },
  BackButtonArrow: {
    border: "1px solid #fff",
    padding: "8px",
    borderRadius: "32px",
    margin: "20px",
  },
  spacify: {
    position: "absolute",
    left: "22px",
  },
  Prepare: {
    position: "absolute",
    left: "170px",
  },
  outreach: {
    position: "absolute",
    right: "274px",
  },
  target: {
    position: "absolute",
    right: "117px",
  },
};
export default SpotPlusScoreCard;
