import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import AiLogo from "../../assets/AiLogo.svg"; // Import the SVG image
import CreateJd from "../../assets/system-uicons_create.svg"; // Import the SVG image
import UploadJd from "../../assets/hugeicons_file-upload.svg"; // Import the SVG image
// import Document from "./solar_document-linear.svg"; // Import the SVG image
// import Search from "./hugeicons_job-search.svg"; // Import the SVG image
import Document from "../../assets/hugeicons_job-search.svg"; // Import the SVG image
import Search from "../../assets/linear.svg"; // Import the SVG image
import JdPanelKnock from "../../assets/Jd panal.svg"; //Import the knock SVG
import selectedCandidate from "../../assets/Candidatelist.svg"; //Import the knock SVG
import SpotPlusJd from "./SpotPlusJd"; // Import the SpotPlusJd component
import { SpotContext } from "../../context/SpotContext";
import { listSpotJDS } from "../../graphql/queries";
import { API, graphqlOperation, Storage } from "aws-amplify";
import Header from "../../components/header/Header";
import SpotPlusCardGpt from "../Company/SpotPlusCardGpt"; // Uncomment this line
import loadinganimation from "../../assets/loadinganimation.gif"; // Import the SVG imageimport sourcelink from "../../assets/sourcelink.svg"; // Import the SVG image
import backButton from "../../assets/back-button-arrow.svg"; // Import the SVG image
import promptstar from "../../assets/promptstar.svg";
import SpotPlusSendMailPopup from "./SpotPlusSendMailPopup"; // Import the SpotPlusJd component
import SpotPlusCardGptSelect from "./SpotPlusCardGptSelect";
import { createSpotJD } from "../../graphql/mutations";
import SpotplusManualForm from "./SpotplusManualForm";


function SpotPlusAskAi() {
  const [isLoading, setIsLoading] = useState(false); // State for loading overlay
  const [showOverlay, setShowOverlay] = useState(false); // State for overlay
  const [showTag1, setShowTag1] = useState(false); // State for tag 1 visibility
  const [showTag2, setShowTag2] = useState(false); // State for tag 2 visibility
  const [isJdPanelOpen, setIsJdPanelOpen] = useState(false);
  const [inputFocused, setInputFocused] = useState(false); // New state for input focus
  const [charmingMessage, setCharmingMessage] = useState(
    "AI is gathering and processing the data."
  );
  const [showLoadingMessage, setShowLoadingMessage] = useState(false); // New state
  const [showResults, setShowResults] = useState(false);
  const [results, setResults] = useState([]);
  const [animationProgress, setAnimationProgress] = useState(0);
  const [displayedResults, setDisplayedResults] = useState([]); // State to hold the currently displayed parts of the results
  const [typingTimer, setTypingTimer] = useState(null); // Timer for typing effect
  const [charIndex, setCharIndex] = useState(0);
  const [resultIndex, setResultIndex] = useState(0);
  const [isChecked, setIsChecked] = useState(false); //Add isChecked state
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Add this line
  const [companyEmail, setCompanyEmail] = useState("");

  // upload jd code start

  const [isModalVisible, setModalVisibility] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadErrors, setUploadErrors] = useState([]); // Track errors for each file
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFileUrls, setUploadedFileUrls] = useState([]);

  // upload jd code end

  // jd Manual form upload code start

  const [isManualJDCreateModalOpen, setManualJDCreateModalOpen] = useState(false);

  // jd Manual form upload code end

  const popupRef = useRef(null);
  // ... other functions ...

  const handleCheckChange = (newIsChecked) => {
    setIsChecked(newIsChecked);
  };
  const dummyResults = [
    // "Result 1: simply dummy text of the printing and typesetting industry.",

    // { text: "Hello", sender: "user" },
    // { text: "Hi! How can I help you today?", sender: "bot-message" },
    // { text: "What's the weather like?", sender: "user" },
    // { text: "It's sunny and 75°F.", sender: "bot-message" },
    // { text: "Do I need an umbrella?", sender: "user" },
    // { text: "No, it's clear today.", sender: "bot-message" },
    // { text: "Thanks!", sender: "user" },
    // { text: "You're welcome!", sender: "bot-message" },
    // { text: "Can you help me with my homework?", sender: "user" },
    // { text: "Sure! What subject?", sender: "bot-message" }

    { type: "user-message", text: "Hello, how are you?" },
    { type: "bot-message", text: "I am doing great, thank you!" },
    { type: "user-message", text: "What is the weather like today?" },
    { type: "bot-message", text: "It looks sunny today!" },
    { type: "user-message", text: "Can you tell me a joke?" },
    {
      type: "bot-message",
      text: "Sure! Why don’t skeletons fight each other? They don’t have the guts.",
    },
    { type: "user-message", text: "What time is it?" },
    { type: "bot-message", text: "It’s 3:00 PM." },
    { type: "user-message", text: "Tell me a fun fact!" },
    { type: "bot-message", text: "Did you know honey never spoils?" },
    { type: "user-message", text: "Hello, how are you?" },
    { type: "bot-message", text: "I am doing great, thank you!" },
    { type: "user-message", text: "What is the weather like today?" },
    { type: "bot-message", text: "It looks sunny today!" },
    { type: "user-message", text: "Can you tell me a joke?" },
    {
      type: "bot-message",
      text: "Sure! Why don’t skeletons fight each other? They don’t have the guts.",
    },
    { type: "user-message", text: "What time is it?" },
    { type: "bot-message", text: "It’s 3:00 PM." },
    { type: "user-message", text: "Tell me a fun fact!" },
    { type: "bot-message", text: "Did you know honey never spoils?" },
  ];

  // useEffect(() => {
  //   let animationInterval;
  //   let timeoutId;

  //   if (initialLoad && inputFocused) {
  //     setShowLoadingMessage(true); // Show loading message immediately
  //     setShowLoadingAnimation(true);
  //     timeoutId = setTimeout(() => {
  //       setShowLoadingMessage(false); // Hide loading message after 30 seconds
  //     }, 30000);

  //     const messages = [
  //       "AI is gathering and processing the data..",
  //       "AI is gathering and processing the data...",
  //       "AI is gathering and processing the data....",
  //       "AI is gathering and processing the data.....",
  //     ];
  //     let currentIndex = 0;

  //     animationInterval = setInterval(() => {
  //       setCharmingMessage(messages[currentIndex]);
  //       currentIndex = (currentIndex + 1) % messages.length;
  //     }, 800);
  //   }

  //   return () => {
  //     clearInterval(animationInterval);
  //     clearTimeout(timeoutId); // Clear timeout on unmount/blur
  //   };
  // }, [inputFocused]);

  const containerRef = useRef(null); // Ref for the main container
  const jdPanelRef = useRef(null);
  const navigate = useNavigate(); // Get the navigation function
  const suggestions = [
    "Who are the top candidates based on AI-generated recommendations?",
    "Who are the candidates with the highest communication skills ratings?",
    "Highest skills ratings?",
    // "Who match at least 90%?",
  ];

  // gpt ai code start

  const {
    companyjdlist,
    setCompanyjdlist,
    selectedjdname,
    selectedjdid,
    setCandidates_referring_to,
  } = useContext(SpotContext);
  const handleSuggestionClick = (suggestion) => {
    setPrompt(suggestion); // Directly set the prompt to the selected suggestion
  };
  const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true); // New state

  const promptContainerRef = useRef(null); // Ref for prompt container

  const [isGptSendEnabled, setIsGptSendEnabled] = useState(false);
  const [isGptLoading, setIsGptLoading] = useState(false);
  const [isGptInitialized, setIsGptInitialized] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [query, setQuery] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [isSending, setIsSending] = useState(false); // Tracks if the API is in progress
  // gpt ai code end
  const [promptsAndResults, setPromptsAndResults] = useState({});
  const [currentPromptIndex, setCurrentPromptIndex] = useState(null); // Track the currently displayed prompt/results

  const handleNewThreadClick = () => {
    // Store current prompt and results (if any)
    if (prompt.trim() && !promptsAndResults[prompt]) {
      // Avoid overwriting existing prompts
      setPromptsAndResults((prev) => ({ ...prev, [prompt]: results }));
    }

    // Reset state for new prompt
    setPrompt("");
    setShowResults(false);
    setResults([]);
    setCurrentPromptIndex(null); // Clear currently displayed prompt index
    setInputFocused(true); // Focus on the input for the new thread
  };

  const handleTreadClick = (promptIndex) => {
    const prompts = Object.keys(promptsAndResults);
    const selectedPrompt = prompts[promptIndex];
    setPrompt(selectedPrompt);
    setResults(promptsAndResults[selectedPrompt] || []);
    setShowResults(true);
    setCurrentPromptIndex(promptIndex); // Set the currently displayed prompt index
  };
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     const isSuggestionClick = event.target.closest(".suggestionItem"); // Use a class selector
  //     if (
  //       promptContainerRef.current &&
  //       !promptContainerRef.current.contains(event.target) &&
  //       showResults && // Only minimize if results are shown
  //       !isSuggestionClick // Don't minimize if a suggestion is clicked
  //     ) {
  //       setInputFocused(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [showResults]);

  // testing code start

  // useEffect(() => {
  //   let timeoutId;

  //   if (inputFocused) {
  //     setShowLoadingAnimation(true); // Show loading animation immediately
  //     setShowLoadingMessage(true); // Show charming message immediately

  //     timeoutId = setTimeout(() => {
  //       setShowLoadingAnimation(false);
  //       setShowLoadingMessage(false); // Hide charming message
  //     }, 8000); // Hide after 30 seconds
  //   }

  //   return () => clearTimeout(timeoutId); // Clear timeout on unmount or blur
  // }, [inputFocused]);

  // const intiliized = () => {
  //   let timeoutId;

  //   if (inputFocused) {
  //     setShowLoadingAnimation(true); // Show loading animation immediately
  //     setShowLoadingMessage(true); // Show charming message immediately

  //     timeoutId = setTimeout(() => {
  //       setShowLoadingAnimation(false);
  //       setShowLoadingMessage(false); // Hide charming message
  //     }, 8000); // Hide after 30 seconds
  //   }

  //   return () => clearTimeout(timeoutId); // Clear timeout on unmount or blur

  // }

  const handleMiniMessagebar = async () => {
    setInputFocused(true);

    setShowLoadingAnimation(true); // Show loading animation immediately
    setShowLoadingMessage(true); // Show charming message immediately

    // setTimeout(() => {

    //   setShowLoadingAnimation(false); // Show loading animation immediately
    //   setShowLoadingMessage(false); // Show charming message immediately
    //   setIsGptSendEnabled(true)

    // }, 10000);

    const ResumeID = "4984bd8e-ac31-441d-b6ab-7e17824a498f";
    const JDID = selectedjdid;

    const data1 = {
      resume_id: ResumeID,
      jd_id: JDID, // Add your new parameter here
    };

    try {
      // Replace with your actual API URL
      const response = await fetch(
        "https://7143-20-70-140-142.ngrok-free.app/initialize",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data1), // Include resume_id in the request body
        }
      );

      const result = await response.json();
      // Check if the response message matches the expected success message
      if (
        result.message ===
        "Resumes and JD profiles embedded, QA system initialized successfully!"
      ) {
        setIsGptSendEnabled(true);
        setShowLoadingAnimation(false); // Show loading animation immediately
        setShowLoadingMessage(false); // Show charming message immediately
      } else {
        setIsGptSendEnabled(false);
      }
    } catch (error) {
      console.error("API call failed", error);
      setIsGptSendEnabled(false); // Ensure the send button is disabled if the API call fails
    } finally {
      setIsGptLoading(false);
    }
  };

  // testing code end

  const handleSend = async () => {
    if (!prompt) {
      return;
    }

    setIsSending(true); // Disable the button

    setShowResults(true); // Show the results area
    setShowLoadingMessage(false); // Hide the "charming" message

    try {
      setPrompt("");
      addMessage(prompt, "user-message");

      // Send API request
      const response = await fetch(
        "https://7143-20-70-140-142.ngrok-free.app/ask",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: prompt,
            resume_id: "4984bd8e-ac31-441d-b6ab-7e17824a498f",
          }),
        }
      );

      // Parse response
      const data = await response.json();

      console.log("oooooooooooooooo", data.candidates_referring_to);
      setCandidates_referring_to(data.candidates_referring_to);

      // Add API response to chat history
      addMessage(data.answer ? data.answer : data.error, "bot-message");
      // setCandidates(data.candidates_referring_to)
      // setCandidatelist(data.candidates_referring_to)
      // setCandidateSource(data.sources)

      // Clear input field after successful submission
      setQuery("");
      setPrompt("");
      // setSearched(true);

      setTimeout(() => {
        setIsSending(false); // Re-enable the button after API completes
      }, 5000);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const addMessage = (message, type) => {
    const newMessage = { text: message, type: type };
    setChatHistory((prevChatHistory) => [...prevChatHistory, newMessage]);
  };

  // const startAnimation = () => {
  //   setResults(dummyResults); // Set the full results immediately

  //   let currentResultIndex = 0;
  //   let currentCharIndex = 0;
  //   setDisplayedResults([]);

  //   const typeNextChar = () => {
  //     if (currentResultIndex < dummyResults.length) {
  //       if (currentCharIndex < dummyResults[currentResultIndex].length) {
  //         const updatedDisplayedResults = [...displayedResults];
  //         updatedDisplayedResults[currentResultIndex] = dummyResults[
  //           currentResultIndex
  //         ].slice(0, currentCharIndex + 1);
  //         setDisplayedResults(updatedDisplayedResults);
  //         currentCharIndex++;
  //       } else {
  //         currentResultIndex++;
  //         currentCharIndex = 0;

  //         if (currentResultIndex < dummyResults.length) {
  //           const updatedDisplayedResults = [...displayedResults, ""]; // Add a new empty string for the next result
  //           setDisplayedResults(updatedDisplayedResults);
  //         }
  //       }

  //       setTypingTimer(setTimeout(typeNextChar, 50)); // Typing speed (adjust delay as needed)
  //     } else {
  //       clearTimeout(typingTimer);
  //     }
  //   };

  //   setTypingTimer(setTimeout(typeNextChar, 50)); // Initial call
  // };

  // useEffect(() => {
  //   return () => {
  //     clearTimeout(typingTimer); // Cleanup timer on unmount
  //   };
  // }, [typingTimer]);

  // Function to handle Create JD action
  const handleCreateJD = () => {
    navigate("/SpotPlusForm"); // Navigate to the SpotPlusForm route
    setShowOverlay(true);
    // Add your logic for creating a JD here
    console.log("Create JD action triggered!");
  };
  // Function to handle Upload JD action
  // const handleUploadJD = () => {
  //   setShowOverlay(true);
  //   // Add your logic for uploading a JD here
  //   console.log("Upload JD action triggered!");
  // };

  // spot ask ai code start

  useEffect(() => {
    const fetchSpotJDs = async () => {
      let spotJDItemsList = [];
      let currentNextToken = null;

      try {
        const companyEmailID = localStorage.getItem("CompanyEmailID");

        setCompanyEmail(companyEmailID);

        if (!companyEmailID) {
          console.error("CompanyEmailID not found in local storage");
          return;
        }

        do {
          const response = await API.graphql(
            graphqlOperation(listSpotJDS, {
              filter: { companyEmail: { eq: companyEmailID } }, // Filter by email
              nextToken: currentNextToken, // Pagination token
            })
          );

          // console.log("GraphQL response for Spot JDs++++++++++++++++++++++++++:", response.data.listSpotJDs.items);
          console.log(
            "GraphQL response for Spot JDs++++++++++++++++++++++++++:",
            response.data.listSpotJDS.items
          );

          // Ensure we're accessing the correct data structure
          const spotJDsData = response.data.listSpotJDS || {};
          const items = spotJDsData.items || [];

          // Set currentNextToken for pagination
          currentNextToken = spotJDsData.nextToken;

          // Append the fetched items to the list
          spotJDItemsList = [...spotJDItemsList, ...items];
        } while (currentNextToken);

        // Set state with the fetched Spot JDs
        // setCompanyjdlist(spotJDItemsList); // Assuming you want to set all the Spot JDs
        // console.log(
        //   "Spot JDs State Set:------------------------",
        //   spotJDItemsList
        // );


        const filteredAndSortedItems = spotJDItemsList
          .filter(item => {
            const filterCandidate = item.filterCandidate || '';
            const selectedCandidate = item.selectedCandidate || '';
            return !(filterCandidate.trim() === '' && selectedCandidate.trim() === '');
          })
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Sort by createdAt descending

        console.log("Spot JDs State Set:*****************", filteredAndSortedItems);
        setCompanyjdlist(filteredAndSortedItems)


      } catch (error) {
        console.error(
          "Error fetching Spot JDs:**************************",
          error
        );
      }
    };

    fetchSpotJDs();
  }, []);

  const handleJdPanelToggle = () => {
    setIsJdPanelOpen(!isJdPanelOpen);
    if (containerRef.current) {
      containerRef.current.style.width = isJdPanelOpen ? "100%" : "100%";
      containerRef.current.style.maxWidth = isJdPanelOpen ? " 1400px" : "746px";
    }
    // Adjust knock position based on container width
    const knock = document.getElementById("jdPanelKnock");
    if (knock) {
      knock.style.right = isJdPanelOpen ? "calc(60% + 20px)" : "20px";
    }
  };

  // spot ask ai code end

  // upload jd code start

  const openUploadModal = () => {
    setModalVisibility(true);
  };

  const closeUploadModal = () => {
    setModalVisibility(false);
    resetFileUploadStates();
  };

  const resetFileUploadStates = () => {
    setSelectedFiles([]);
    setUploadedFileUrls([]);
    setUploadErrors([]);
    setIsUploading(false);
  };

  const handleFileSelection = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
  };

  const handleJDUpload = async () => {
    if (selectedFiles.length === 0) {
      setUploadErrors(["Please select at least one file to upload."]);
      return;
    }

    setIsUploading(true);
    setUploadErrors([]);
    const uploadedUrls = [];

    try {
      for (const file of selectedFiles) {
        // Generate unique filename using timestamp
        const timestamp = Date.now();
        const uniqueFileName = `${timestamp}_${file.name}`;

        const folder = "SpotJD/"; // Folder name in S3
        const yourS3BucketName = "getscreenedstoragebucketf24ed-devget";
        const s3Key = `${folder}${uniqueFileName}`; // Combine folder and file name

        // Upload to S3
        const uploadResult = await Storage.put(s3Key, file, {
          contentType: file.type,
        });

        // Get file URL
        // const fileUrl = await Storage.get(uploadResult.key);

        const fileUrl =
          await `https://${yourS3BucketName}.s3.amazonaws.com/public/${uploadResult.key}`;

        uploadedUrls.push(uniqueFileName);

        console.log("uploaded file one by one", uploadedUrls);
        console.log("uploaded urls", fileUrl);

        // Update DynamoDB via GraphQL
        const input = {
          jdURL: fileUrl,
          companyEmail: companyEmail, // Replace with actual company email
        };

        const response = await API.graphql(
          graphqlOperation(createSpotJD, { input })
        );
        const {
          data: {
            createSpotJD: { id },
          },
        } = response;

        console.log("Created JD with ID:", id);

        // Pass model ID to the parse API
        await sendToParseAPI(id);
      }

      setUploadedFileUrls(uploadedUrls);
      navigate("/SpotPlusCandidte");
      // alert("All files uploaded successfully!");
    } catch (error) {
      console.error("Error uploading files:", error);
      setUploadErrors([
        "Error occurred while uploading files. Please try again.",
      ]);
    } finally {
      setIsUploading(false);
    }
  };

  const sendToParseAPI = async (id) => {
    try {
      const data = { id };
      const response = await fetch(
        "https://jdwithai-e9e5hke8crfvcrg4.canadacentral-01.azurewebsites.net/extract-job-details",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send data to parse API");
      }

      // console.log("Parse API response:", await response.json());
      const updatedJDinfo = await response.json();

      // const jdId = updatedJDinfo.updated_jd_details?.id;  // Safe navigation in case the property is 

      console.log("<><><><><><><><><><><", updatedJDinfo);


      console.log("JD ID:", updatedJDinfo.updated_jd_details);
      localStorage.setItem('RecentJDIdAfterJDupload', JSON.stringify(updatedJDinfo.updated_jd_details));

    } catch (error) {
      console.error("Error sending data to parse API:", error);
    }
  };

  // upload jd code end

  // jd Manual form upload code start

  const handleOpenModal = () => {
    setManualJDCreateModalOpen(true);
  };

  const handleCloseModal = () => {
    setManualJDCreateModalOpen(false);
  };

  //  jd Manual form upload code end

  // console.log("pppppppppppppppppppppppppppppp", companyjdlist);

  // danger code start

  const [animatedMessages, setAnimatedMessages] = useState([]);

  useEffect(() => {
    const newMessages = chatHistory.map((message, index) => {
      // Only apply typing animation to the most recent bot message
      if (message.type === "bot-message" && index === chatHistory.length - 1) {
        const words = message.text.split(" "); // Split by words
        let typedMessage = "";
        let wordIndex = 0;
        let interval;

        // Create a function to simulate typing one word at a time
        const typeMessage = () => {
          if (wordIndex < words.length) {
            typedMessage += words[wordIndex] + " "; // Add the next word with a space
            wordIndex += 1;

            setAnimatedMessages((prevMessages) => {
              const updatedMessages = [...prevMessages];
              updatedMessages[index] = {
                ...updatedMessages[index],
                text: typedMessage, // Update the text with the new word
              };
              return updatedMessages;
            });
          } else {
            clearInterval(interval); // Stop the typing once the whole message is typed
          }
        };

        // Run the typing animation every 300ms (adjust the speed as needed)
        interval = setInterval(typeMessage, 300);

        return { ...message, text: "" }; // Initially set an empty text for bot messages
      } else {
        // For all other messages, display them immediately
        return message;
      }
    });

    setAnimatedMessages(newMessages); // Set the initial state with empty bot message texts
  }, [chatHistory]); // Trigger whenever chatHistory changes

  // dander code ned

  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 }); // For positioning

  const handleSendMailClick = (event) => {
    // Calculate center position based on click or parent element
    const buttonRect = event.target.getBoundingClientRect();
    setPopupPosition({
      x: buttonRect.left + buttonRect.width / 2,
      y: buttonRect.top + buttonRect.height / 2,
    });

    setIsPopupOpen(true);
  };

  return (
    <div>
      <Header />

      <div>
        <div style={styles.titlesub}>
          {showResults && ( // Show only when showResults is true
            <img
              onClick={handleNewThreadClick}
              src={backButton}
              style={styles.backButton}
              alt="Back"
            />
          )}
          <h2 style={styles.jobTitle}>{selectedjdname}</h2>
        </div>

        <div style={styles.main}>
          {isLoading && (
            <div style={styles.overlay}>
              <img
                src={loadinganimation}
                alt="Loading..."
                style={styles.logoanimation}
              />
              <p style={styles.aiCharmingMessage}>{charmingMessage}</p>
            </div>
          )}
          {/* Black Overlay */}
          {showOverlay && (
            <div style={styles.overlay} onClick={() => setShowOverlay(false)}>
              {/* You can add content to the overlay if needed */}
            </div>
          )}
          <div style={styles.sidebardiv}>
            <div style={styles.sidebar}>
              <div
                // onClick={handleCreateJD}
                onClick={handleOpenModal}
              >
                {" "}
                {/* Call handleCreateJD on click */}
                <img src={CreateJd} alt="Create JD" style={styles.createjd} />
                {showTag1 && <span style={styles.tag}>Create JD</span>}
              </div>
              <div
                style={styles.box}
                //  onClick={handleUploadJD}
                onClick={openUploadModal}
              >
                {" "}
                {/* Call handleUploadJD on click */}
                <img src={UploadJd} alt="Upload JD" style={styles.uploadjd} />
                {showTag2 && <span style={styles.tag2}>Upload JD</span>}
              </div>

              {/* jd upload code start */}

              {isModalVisible && (
                <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
                  <div className="bg-white p-8 rounded-lg w-full max-w-md shadow-lg transform transition-all duration-300 scale-95 hover:scale-100">
                    {/* Modal Header */}
                    <div className="flex justify-between items-center mb-4">
                      <h2 className="text-2xl font-semibold text-black">
                        Upload Job Description
                      </h2>
                      <button
                        onClick={closeUploadModal}
                        className="text-gray-500 hover:text-gray-700 text-2xl transition-all duration-300"
                      >
                        &times;
                      </button>
                    </div>

                    {/* File Input */}
                    <input
                      type="file"
                      multiple
                      onChange={handleFileSelection}
                      className="block w-full px-4 py-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                    />

                    {/* Selected Files */}
                    {selectedFiles.length > 0 && (
                      <div className="mb-4">
                        <h3 className="text-lg font-semibold">
                          Selected Files:
                        </h3>
                        <ul>
                          {selectedFiles.map((file, index) => (
                            <li key={index} className="text-sm text-gray-600">
                              {file.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}

                    {/* Error Messages */}
                    {uploadErrors.length > 0 && (
                      <div className="text-red-600 mb-4">
                        {uploadErrors.map((error, index) => (
                          <p key={index}>{error}</p>
                        ))}
                      </div>
                    )}

                    {/* Upload Button or Spinner */}
                    <div className="text-center">
                      {isUploading ? (
                        <div className="animate-pulse text-blue-600">
                          Uploading...
                        </div>
                      ) : (
                        <button
                          onClick={handleJDUpload}
                          className="px-6 py-3 bg-green-600 text-white rounded-md hover:bg-green-700 transition ease-in-out duration-300"
                        >
                          Upload Files
                        </button>
                      )}
                    </div>

                    {/* Display Uploaded Files */}
                    {uploadedFileUrls.length > 0 && (
                      <div className="mt-4">
                        <h3 className="text-lg font-semibold text-green-600">
                          Uploaded Files:
                        </h3>
                        <ul>
                          {uploadedFileUrls.map((url, index) => (
                            <li key={index} className="text-sm">
                              <a
                                href={url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:underline"
                              >
                                View File {index + 1}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* jd upload code end */}

              {/* jd Manual form upload code start */}

              {isManualJDCreateModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                  <div className="bg-white p-6 rounded-lg shadow-md ">
                    {/* <h2 className="text-xl font-semibold mb-4 text-center text-black">Create Job Description</h2> */}
                    {/* <SpotplusManualForm  /> */}
                    <SpotplusManualForm onClose={handleCloseModal} />
                  </div>
                </div>
              )}

              {/* jd Manual form uploda code end */}

              {/* spot ai code start */}

              {/* spot ai code end */}
              <div style={styles.createjdbutton}>Create JD</div>
            </div>
            <div style={styles.sidebarnavigation}>
              <img src={Document} alt="AI Logo" />
              <img src={Search} alt="AI Logo" />
            </div>
          </div>
          <div
            ref={containerRef}
            style={styles.container}
            className={isPopupOpen ? "hidden" : ""} // Apply hidden class conditionally
          >
            {/* Conditionally render results */}

            {/* Chatting code */}
            {showResults && (
              <div className="overflow-auto">
                <div style={styles.promptname}>
                  <img
                    src={promptstar}
                    alt="Loading..."
                    style={styles.promptnamestar}
                  />
                  {/* Display the entered prompt */}
                  <span>{prompt}</span>
                </div>
                <div style={styles.card}>
                  <SpotPlusCardGpt
                    // ... other props ...
                    // isChecked={isChecked}
                    onCheckChange={handleCheckChange}
                  />
                </div>
                <div style={styles.resultsContainer}>
                  <div>
                    {/* {displayedResults.map( */}
                    {/* {dummyResults.map(
                      (
                        result,
                        index // Map over displayedResults
                      ) => (
                        <div key={index} style={styles.resultItembox}>
                          <p style={styles.resultItem}>{result}</p>
                        </div>
                      )
                    )} */}

                    {/* {dummyResults.map((result, index) => (
                      <div key={index} className={`flex ${result.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
                        <div className={`p-2 rounded-lg max-w-[70%] break-words ${result.sender === 'user' ? ' text-white' : ' text-white'}`}>
                          {result.text}
                        </div>
                      </div>
                    ))} */}

                    {/* {chatHistory.map((message, index) => (
                      <div key={index} className={`flex ${message.type === 'user-message' ? 'justify-end' : 'justify-start'}`}>
                        <div className={`p-2 rounded-lg max-w-[70%] break-words ${message.type === 'user-message' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'}`}>
                          {message.text}
                        </div>
                      </div>
                    ))
                    } */}

                    {/* <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                      {chatHistory.map((message, index) => (
                        <div key={index} className={`flex ${message.type === 'user-message' ? 'justify-end' : 'justify-start'}`}>
                          <div className={`p-2 rounded-lg max-w-[70%] break-words ${message.type === 'user-message' ? ' text-white' : ' text-white'}`}>
                            {message.type === 'user-message' ? 'User: ' : 'Bot: '}
                            {message.text}
                          </div>
                        </div>
                      ))}
                    </div> */}

                    {/* <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                      {animatedMessages.map((message, index) => (
                        <div
                          key={index}
                          className={`flex ${message.type === 'user-message' ? 'justify-end' : 'justify-start'}`}
                        >
                          <div
                            className={`p-2 rounded-lg max-w-[70%] break-words ${message.type === 'user-message' ? ' text-white' : ' text-white'}`}
                          >
                            {message.type === 'user-message' ? 'User: ' : 'Bot: '}
                            {message.text}
                          </div>
                        </div>
                      ))}
                    </div> */}

                    <div style={{ overflowY: "auto" }}>
                      {animatedMessages.map((message, index) => (
                        <div
                          key={index}
                          className={`flex ${message.type === "user-message"
                            ? "justify-end"
                            : "justify-start"
                            }`}
                        >
                          <div
                            className={`p-2 rounded-lg max-w-[70%] break-words ${message.type === "user-message"
                              ? " text-white"
                              : "text-white"
                              }`}
                          >
                            {/* Circular Header with Gradient and Layout for User/Bot */}
                            <div
                              className={`flex ${message.type === "user-message"
                                ? "flex-row-reverse"
                                : "flex-row"
                                } items-center space-x-2`}
                            >
                              {/* Circle */}
                              <div
                                className={`w-8 h-8 rounded-full flex items-center justify-center text-white font-bold`}
                                style={{
                                  background:
                                    "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
                                }}
                              >
                                {message.type === "user-message" ? "Y" : "G"}{" "}
                                {/* User -> 'U', Bot -> 'B' */}
                              </div>

                              {/* Header Label */}
                              <div
                                className={`text-sm font-bold ${message.type === "user-message"
                                  ? "text-left pr-2"
                                  : "text-right"
                                  }`}
                              >
                                {message.type === "user-message"
                                  ? "You"
                                  : "Getscreened"}
                              </div>
                            </div>

                            {/* Message Text */}
                            <div className="mt-1">{message.text}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* <div style={styles.tradcontainer}>
                  <div style={styles.tread}></div>
                  <div style={styles.tread}></div>
                  <div style={styles.tread}></div>
                </div> */}
              </div>
            )}

            {/* Input and suggestions (conditionally rendered) */}
            {!showResults && ( // Only show if results are not being displayed
              <>
                {/* Logo at the Top */}

                {/* Conditionally render loading animation or static logo */}
                {showLoadingAnimation ? (
                  <img
                    src={loadinganimation}
                    alt="Loading..."
                    style={styles.logoanimation}
                  />
                ) : (
                  <img src={AiLogo} alt="AI Logo" style={styles.logo} />
                )}
                {/* ... other JSX */}
                {showLoadingMessage && ( // Conditionally render the loading message
                  <p style={styles.aiCharmingMessage}>{charmingMessage}</p>
                )}

                <h1 style={styles.title}>
                  Revolutionize Recruitment: Find Your Perfect Match with GPT.
                </h1>
                {!showResults && Object.keys(promptsAndResults).length > 0 && (
                  <div style={styles.tradcontainer}>
                    {Object.keys(promptsAndResults).map((prompt, index) => (
                      <div
                        key={index}
                        style={styles.tread}
                        onClick={() => handleTreadClick(index)} // Pass the index
                      >
                        <p style={{ color: "#fff", margin: "10px" }}>
                          {prompt.slice(0, 20)}...
                        </p>
                      </div>
                    ))}
                  </div>
                )}

                {/* ... (Your input and suggestion JSX remains here) */}
              </>
            )}

            {/* Prompt Input Box (Always shown, moves below results) */}
            <div
              style={
                showResults
                  ? styles.promptContainerAfterResults
                  : styles.promptContainer2
              }
            >
              {/* ... input and button JSX */}
            </div>

            {inputFocused ? ( // Conditionally render based on input focus
              <>
                {" "}
                {/* Fragment to group elements */}
                {/* Suggestions Bar */}
                <div style={styles.suggestionContainer}>
                  {suggestions.map((suggestion, index) => (
                    <span
                      className="suggestionItem" // Add the class here
                      key={index}
                      style={styles.suggestionItem}
                      onClick={() => handleSuggestionClick(suggestion)} // Call handleSuggestionClick
                    >
                      {suggestion}
                    </span>
                  ))}
                </div>
                {/* Prompt Input Box */}
                <div
                  ref={promptContainerRef} // Attach ref here
                  style={{
                    ...styles.promptContainer,
                    ...(inputFocused
                      ? styles.expandedPromptContainer
                      : styles.minimizedPromptContainer),
                    transition: "all 0.3s ease", // Animation transition
                  }}
                >
                  <input
                    type="text"
                    placeholder="Ask GPT..."
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    // onFocus={() => setInputFocused(true)}
                    // onClick={intiliized}
                    style={{
                      ...styles.promptInput,
                      ...(!inputFocused && styles.minimizedPromptInputclicked),
                    }}
                  />
                  <div
                    style={
                      {
                        /* ... styles ... */
                      }
                    }
                  >
                    {" "}
                    {/* Your existing input and button styles */}
                    {/* ... input JSX ... */}
                    {isGptSendEnabled && (
                      <button
                        onClick={handleSend}
                        style={{
                          ...styles.sendButton,
                          opacity: showLoadingMessage && initialLoad ? 0.3 : 1, // Conditional opacity
                          pointerEvents:
                            showLoadingMessage && initialLoad ? "none" : "auto",
                        }}
                        disabled={isSending} // Disable button (extra safeguard)
                      >
                        <span style={styles.arrow}>&#9654;</span>
                      </button>
                    )}
                  </div>
                </div>
              </>
            ) : (
              // Minimized input
              // <div style={styles.mainMinimizedPromptContainer}>
              <div style={styles.mainMinimizedPromptContainer}>
                <div style={styles.minimizedPromptContainer}>
                  <input
                    type="text"
                    placeholder="Ask GPT..."
                    // onFocus={() => setInputFocused(true)} // Open on focus
                    // onClick={() => setInputFocused(true)}
                    // onClick={handleMiniMessagebar}
                    onClick={() => {
                      if (!selectedjdid) {
                        alert("Please select the Job Description first");
                      } else {
                        handleMiniMessagebar();
                      }
                    }}
                    style={styles.minimizedPromptInput}
                  />
                  <img
                    src={AiLogo}
                    alt="AiLogo"
                    style={styles.newthreadlogo}
                    onClick={handleNewThreadClick} // Call new thread function
                  />
                </div>
                {isChecked && (
                  <div
                    style={styles.sendmail}
                    onClick={() => {
                      // You'll need to add state and logic to handle the popup here.
                      // This example uses a simple state variable.  A more robust approach
                      // might use a modal library or context API.
                      setIsPopupOpen(true); // Set state to show the popup
                    }}
                  >
                    Send Mail
                  </div>
                )}
              </div>
              // </div>
            )}
          </div>
          {isPopupOpen && (
            <div style={styles.popupOverlay}>
              {" "}
              {/* Black overlay */}
              <div style={styles.popupContainer}>
                {" "}
                {/* Centered container */}
                <SpotPlusSendMailPopup onClose={() => setIsPopupOpen(false)} />
              </div>
            </div>
          )}

          {!isGptSendEnabled && (
            <div>
              <img
                src={JdPanelKnock}
                alt="Open JD Panel"
                style={{
                  ...styles.rightButton,
                  // right: isJdPanelOpen ? "39.10%" : "0px",
                  right: isJdPanelOpen ? "-75%" : "0px",
                  transition: "right 0.3s ease",
                }} // Update button position
                onClick={handleJdPanelToggle}
              />
              {/* JD Panel - Slide from left to right */}
              <div
                ref={jdPanelRef}
                style={{
                  ...styles.jdPanel,
                  transform: isJdPanelOpen
                    ? "translateX(0)"
                    : "translateX(100%)", // Slide from left
                  transition: "transform 0.3s ease",
                  width: isJdPanelOpen ? "40%" : "0%",
                  position: "absolute",
                  right: " -10px", // Position to the left
                  top: "94px",
                  height: "84vh",
                  overflow: "auto",
                  zIndex: 10,
                  borderRadius: "20px",
                  margin: " 7px 8px",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  border: "1px solid rgba(255, 255, 255, 0.2)",
                  padding: "10px",
                }}
              >
                <SpotPlusJd />
              </div>
            </div>
          )}

          {isGptSendEnabled && (
            <div>
              {/* Selected Candidate list - Slide from left to right */}
              <img
                src={selectedCandidate}
                alt="Open JD Panel"
                style={{
                  ...styles.rightButton,
                  // right: isJdPanelOpen ? "39.30%" : "0px",
                  right: isJdPanelOpen ? "-39.30%" : "0px",
                  transition: "right 0.3s ease",
                }} // Update button position
                onClick={handleJdPanelToggle}
              />
              <div
                ref={jdPanelRef}
                style={{
                  ...styles.jdPanel,
                  transform: isJdPanelOpen
                    ? "translateX(0)"
                    : "translateX(100%)", // Slide from left
                  transition: "transform 0.3s ease",
                  width: isJdPanelOpen ? "40%" : "0%",
                  position: "absolute",
                  right: 0,
                  top: "94px",
                  height: "84vh",
                  overflow: "auto",
                  zIndex: 10,
                  borderRadius: "20px",
                  margin: " 7px 8px",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  border: "1px solid rgba(255, 255, 255, 0.2)",
                }}
              >
                <SpotPlusCardGptSelect />
                <div style={styles.sendmailcontainer}>
                  <div style={styles.sendmail} onClick={handleSendMailClick}>
                    {" "}
                    {/* onClick handler */}
                    Send Mail
                  </div>
                  {/* Conditional Popup */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
// Internal CSS
const styles = {
  body: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
  },
  // container: {
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   height: "100vh",
  //   padding: "16px",

  //   width: "100%",
  // },
  title: {
    color: "#fff",
    margin: "20px",
  },
  suggestionContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "10px",
    marginBottom: "-2px",
  },
  suggestionItem: {
    padding: "4px 12px",
    backgroundColor: "#C6A0F7",
    borderRadius: "20px",
    color: "#fff",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  suggestionItemHover: {
    backgroundColor: "#B98CE2",
  },
  promptContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#E0CFFF",
    padding: "10px",
    borderRadius: "30px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
    width: "80%",
    // maxWidth: "600px",
    maxWidth: "746px",
  },
  promptInput: {
    flex: 1,
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    padding: "10px",
    color: "#606B8B",
  },
  sendButton: {
    border: "none",
    backgroundColor: "#B98CE2",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  arrow: {
    color: "#fff",
    fontSize: "18px",
  },
  logo: {
    width: "64px",
    // marginBottom: "20px",
  },
  newthreadlogo: {
    // width: "48px",
    width: "36px",
  },

  logoanimation: {
    width: "120px",
    borderRadius: "100px",
  },
  sidebardiv: {
    gap: "8px",
    display: " grid",
    margin: "0px 20px",
    zIndex: 2, // Ensure it's on top
  },
  sidebar: {
    width: "98px",
    height: "73px",
    backgroundColor: "#ffffff1a",
    border: " 1px solid #ffffff33",
    position: "relative",
    borderRadius: "20px",
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
  },
  sidebarnavigation: {
    width: "65px",
    height: "179px",
    backgroundColor: "#ffffff1a",
    border: " 1px solid #ffffff33",
    borderRadius: "20px",
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    justifySelf: " center",
  },
  createjdbutton: {
    backgroundColor: "rgba(255, 255, 255, 1.0)",
    borderTop: " 1px solid rgba(255, 255, 255, 0.4)",
    width: "98px",
    height: "20px",
    borderRadius: "0px 0px 20px 20px",
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    fontWeight: "100",
    color: "#fff",
  },
  createjd: {
    top: "13px",
    position: "absolute",
    left: "28px",
  },
  uploadjd: {},
  main: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
  },
  box: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    border: "1px solid #ffffff7a",
    borderRadius: "18px",
    height: "24px",
    width: "24px",
    position: "absolute",
    top: "-13px",
    left: "81px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  // Styles for the loading overlay
  overlay: {
    position: "fixed",
    top: 0, // Cover the whole viewport
    left: 0,
    width: "100%",
    height: "100%",
    background: "linear-gradient(111deg, #667EEA 0%, #8859B8 100%)",
    display: "flex", // Use flexbox for centering
    flexDirection: "column", // Align items vertically within overlay
    alignItems: "center", // Center horizontally
    justifyContent: "center", // Center vertically
    zIndex: 1,
  },
  loader: {
    color: "#fff",
    fontSize: "20px",
  },
  tag: {
    position: "absolute",
    top: "131%",
    left: "137%",
    transform: "translate(-50%, -150%)", // Adjust position as needed
    backgroundColor: "#fff",
    color: "#000",
    padding: "4px 8px",
    borderRadius: "4px",
    fontSize: "12px",
    // visibility: "hidden",
    zIndex: 2, // Ensure it's on top
    width: "max-content",
  },
  tag2: {
    position: "absolute",
    top: "56%",
    left: "238%",
    transform: "translate(-50%, -150%)", // Adjust position as needed
    backgroundColor: "#fff",
    color: "#000",
    padding: "4px 8px",
    borderRadius: "4px",
    fontSize: "12px",
    // visibility: "hidden",
    zIndex: 2, // Ensure it's on top
    width: "max-content",
  },
  // ... existing styles ...
  rightButton: {
    position: "relative",

    right: "20px", // Adjust as needed
    cursor: "pointer",
    zIndex: "11",
  },
  container: {
    position: "relative", // Important for absolute positioning of the JD panel
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "80vh",
    padding: "16px",
    width: "100%",

    // maxWidth: "600px",
    transition: "width 0.3s ease, max-width 0.3s ease",
    gap: "10px",
  },
  jdPanel: {
    backgroundColor: "#f0f0f0",
    position: "relative",
    display: "grid",
    justifyContent: "left",
    padding: "10px",
  },
  jobTitle: {
    padding: "14px 20px",
  },
  minimizedPromptContainer: {
    // Styles for the minimized input
    display: "flex",
    alignItems: "center",
    backgroundColor: "#E0CFFF",
    padding: "6px", // Smaller padding
    borderRadius: "30px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Lighter shadow
    // width: "50%",
    maxWidth: "300px", // Smaller max width
    cursor: "pointer", // Indicate clickability
    transition: "all 0.3s ease",
    // marginTop: "12px",
  },
  minimizedPromptInput: {
    flex: 1,
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "14px", // Smaller font size
    padding: "8px", // Smaller padding
    color: "#606B8B",
    transform: "scale(1)",
    transition: "background-color 2s ease-in-out, transform 1s ease-in 500ms",
  },
  minimizedPromptInputclicked: {
    flex: 1,
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "14px", // Smaller font size
    padding: "8px", // Smaller padding
    color: "#606B8B",
    transform: "scale(1.2)",
    transition: "background-color 2s ease-in-out, transform 1s ease-in 500ms",
  },
  aiCharmingMessage: {
    // Styles for the AI message
    color: "#fff",
    marginTop: "10px",
    fontSize: "24px",
    fontStyle: "italic",
  },
  resultsContainer: {
    background: "linear-gradient(111deg, #667EEA 0%, #8859B8 100%)", // Added gradient
    width: "90%",
    padding: "32px 8px",
    color: "#d9d9d9",
    borderRadius: "12px",
    border: "1px rgba(255, 255, 255, 0.40) solid",
  },
  card: {
    display: "flex",
    gap: "12px",
    marginBottom: "8px",
    width: "fit-content",
  },
  titlesub: {
    display: "flex",
    alignItems: "center",
    marginTop: "8px",
  },
  backButton: {
    border: "1px solid #fff",
    borderRadius: "28px",
    height: " 32px",
    padding: "4px",
    marginBottom: "1rem",
    marginLeft: "8px",
    marginTop: "2px",
    cursor: "pointer",
  },
  tread: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    border: "1px solid rgba(255, 255, 255, 0.2)",
    // width: "100%",
    // height: "12vh",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tradcontainer: {
    display: "flex",
    gap: "10px",
    margin: "10px",
    width: "100%",
    justifyContent: "center",
  },

  promptname: {
    margin: "20px 10px",
    fontSize: "20px",
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  promptnamestar: {
    width: "20px",
  },
  // mainMinimizedPromptContainer: {
  //   display: "flex",
  //   alignItems: "center",
  //   width: "50%",
  //   justifyContent: "center",
  //   gap: "8px",
  //   marginTop: "12px",
  // },
  mainMinimizedPromptContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  sendmail: {
    border: "1px solid #fff",
    padding: "10px",
    borderRadius: "2px 50px 50px 26px",
    width: "25%",
    height: " max-content",
  },
  sendmailcontainer: {
    display: "grid",
    alignItems: "end",
  },
  hidden: {
    display: "none",
  },
  // In your styles object:
  popupOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000, // Ensure it's on top
  },
  popupContainer: {
    backgroundColor: "#161616", // Or any other background color
    opacity: "98%",
    padding: "20px", // Add padding as needed
    borderRadius: "8px", // Optional border radius
  },

  // ... other styles ...
};
export default SpotPlusAskAi;
