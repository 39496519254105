import React, { useEffect, useRef, useState } from "react";
import { API, Storage, graphqlOperation } from "aws-amplify";
import "./SpotSearch.css";
// import backclose from "./img/Group 6755.svg";
import backclose from "../../assets/Group 6755.svg"
import { listCandidateProfiles, listInterviewEvaluations, listSpotJDS, listSpotResumes } from "../../graphql/queries";
import { createSpotJD, createSpotResume, updateSpotJD, updateSpotResume } from "../../graphql/mutations";
import profile from "../../assets/profile.png"
import SpotAnimation from "../../assets/SpotAnimation.gif"
import { DownloadOutlined, FacebookFilled } from "@ant-design/icons";
import { Await, useNavigate } from "react-router-dom";
import Header from "../../components/header/Header";
import aicharmingdata from "../../assets/aicharmingdata.gif"
import iconinformation from "../../assets/iconinformation.svg"
import jsPDF from 'jspdf';

const SpotSearch = () => {
  const [isChatboxOpen, setIsChatboxOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [activeTab, setActiveTab] = useState("JD");
  const [activeCandidateTab, setActiveCandidateTab] = useState("AI Picked");
  const [activateOutrich, setActivateOutrich] = useState("Outrich select")
  const [showTable, setShowTable] = useState(false);
  const [targetTable, setTargetTable] = useState(false);
  const [currentScreen, setCurrentScreen] = useState("candidates"); // Initial screen
  const [nextArray, setNextArray] = useState(["candidates"]);
  const [index, setIndex] = useState(1);
  const [isSectionVisible, setIsSectionVisible] = useState(true);

  //spot code start

  const [isJdUploadOpen, setIsJdUploadOpen] = useState(false);
  const [isJdUploading, setIsJdUploading] = useState(false);
  const [jdError, setJdError] = useState(null);
  const [jdSuccess, setJdSuccess] = useState(null);

  const [jdFile, setJdFile] = useState(null);
  const [localCompanyEmail, setLocalCompanyEmail] = useState("")
  const [spotJDSItems, setSpotJDSItems] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [spotJD, setSpotJD] = useState({})
  const [serverEmails, setServerEmails] = useState([])
  const [specificSelectedJDid, setSpecificSelectedJDid] = useState("")
  const [selectedJDId, setSelectedJDId] = useState(null);

  const [isJobCardModalOpen, setIsJobCardModalOpen] = useState(false);
  const [selectedJobCard, setSelectedJobCard] = useState(null);
  const [spotJDmachesResumeID, setSpotJDmachesResumeID] = useState(false)

  //  resume code
  const [isResumeModalOpen, setIsResumeModalOpen] = useState(false);
  const [resumeFiles, setResumeFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(null);
  const [aiResumesResponce, setAiResumesResponce] = useState([])
  const [isResumeUploading, setIsResumeUploading] = useState(false);
  const [spotResumeItems, setSpotResumeItems] = useState([]);
  const [spotResume, setSpotResume] = useState(null);

  // outrich code 

  const [selectedDatabaseCandidates, setSelectedDatabaseCandidates] = useState([]);
  const [selectedAiResumes, setSelectedAiResumes] = useState([]);
  const [selectedActiveTab, setSelectedActiveTab] = useState('database'); // renamed variable
  const [databaseSelectionCount, setDatabaseSelectionCount] = useState('5');
  const [aiSelectionCount, setAiSelectionCount] = useState('5');
  const [showSelectionOptions, setShowSelectionOptions] = useState(false);
  const navigate = useNavigate();

  // JD Form 
  const [jdIsModalOpen, setJdIsModalOpen] = useState(false);
  const [jdFormData, setJdFormData] = useState({
    email: '',
    phone: '',
    age: '',
    address: '',
    country: '',
  });

  const [jobTitle, setJobTitle] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedExperience, setSelectedExperience] = useState("");
  const [skill, setSkill] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [compensation, setCompensation] = useState("");
  const [benefits, setBenefits] = useState("");
  const CompanyEmailID = localStorage.getItem("CompanyEmailID");
  const [workMode, setWorkMode] = useState("");

  const compensationRef = useRef(null);
  const [companyjobcount, setcompanyjobcount] = useState(false);
  const [createjdIsApiWorking, setCreatejdIsApiWorking] = useState(false);


  //  candidate profile and evaution code start

  const [combinedProfilesWithEvaluations, setCombinedProfilesWithEvaluations] = useState([]);
  const [isFetchingEvaluations, setIsFetchingEvaluations] = useState(false);
  const [evaluations, setEvaluations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);
  // const [emails,setEmails]=useState([])

  //  candidate profile and evaution code end

  const names = [
    {
      id: 1,
      name: "John Doe",
      email: "john@example.com",
      phone: "123-456-7890",
      position: "Developer",
    },
    { name: "Bob Smith", id: "2" },
    { name: "Carol Williams", id: "3" },
    { name: "David Brown", id: "4" },
    { name: "Eve Davis", id: "5" },
    { name: "Frank Miller", id: "6" },
  ];
  // const names = [
  //   {
  //     id: 1,
  //     name: "John Doe",
  //     email: "john@example.com",
  //     phone: "123-456-7890",
  //     position: "Developer",
  //   },
  //   {
  //     id: 2,
  //     name: "Jane Smith",
  //     email: "jane@example.com",
  //     phone: "987-654-3210",
  //     position: "Designer",
  //   },
  // ];

  const handleBackCloseClick = () => {
    setIsSectionVisible((prevState) => !prevState); // Toggle visibility
  };

  const array = [...Array(6)].map((_, index) => names[index]);

  const handleChatboxToggle = () => {
    setIsChatboxOpen((prevState) => !prevState);
  };

  const handleCandidateClick = (candidate) => {
    setSelectedCandidate(candidate);
  };

  const handleCloseProfile = () => {
    setSelectedCandidate(null);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleCandidateTabClick = (tab) => {
    setActiveCandidateTab(tab);
  };

  // handle next original code start

  // const handleNextClick = () => {
  //   setShowTable(true);
  //   setCurrentScreen(`candidates${index}`);

  //   // testing start
  //   if (activeTab === "JD" && spotJDmachesResumeID === false) {
  //     setActiveTab("Resume")
  //   } else {
  //     setActiveTab("Outrich select")
  //     setActiveCandidateTab("Outrich select")
  //   }

  //   if (activeTab === "Resume") {
  //     setActiveTab("Outrich select")
  //     setActiveCandidateTab("Outrich select")
  //   }
  //   // testing end

  //   console.log("sssssss:", currentScreen);
  //   setNextArray((prevArray) => [...prevArray, `candidates${index}`]);
  //   setIndex((prevIndex) => prevIndex + 1);
  // };

  // handle next original code end


  const handleNextClick = () => {
    setShowTable(true);
    setCurrentScreen(`candidates${index}`);
    // testing start
    if (activeTab === "JD") {
      setActiveTab("Resume")
    }
    if (activeTab === "Resume") {
      setActiveTab("Outrich select")
      setActiveCandidateTab("Outrich select")
    }
    // testing end
  };



  const TargetClick = () => {
    setTargetTable(!targetTable);
    // setCurrentScreen(`candidates${index}`);
    setCurrentScreen(!targetTable ? `candidates${index}` : "candidates");
    console.log("sssssss:", currentScreen);
    // setNextArray((prevArray) => [...prevArray, `candidates${index}`]);
    // setIndex((prevIndex) => prevIndex + 1);
  };

  // handle back original code start

  // const handleBackClick = () => {

  //   //  back button working code 

  //   if (activeTab === "Outrich select") {

  //     setActiveTab("Resume")
  //   }


  //   if (activeTab === "Resume") {
  //     setActiveTab("Resume")
  //   }

  //   //   back buton working code


  //   console.log("this page:r", nextArray);
  //   console.log("this page:999999999999999999", nextArray.length);

  //   // testing start
  //   if (activeTab === "Resume") {
  //     setActiveTab("JD")
  //   }
  //   // testing end

  //   setNextArray((prevArray) => prevArray.slice(0, -1));
  //   if (currentScreen === "showTable") {
  //     setShowTable(false);
  //     setCurrentScreen("candidates");
  //   } else {
  //     setShowTable(false);
  //     setCurrentScreen("candidates");
  //     // Handle other back navigation logic if necessary
  //   }
  //   if (nextArray.length == 1) {
  //     setIndex(1);
  //   }
  //   console.log("showTable", showTable);
  // };

  // handle back original code end


  const handleBackClick = () => {
    if (activeTab === "Outrich select") {
      setActiveTab("Resume")
      setCurrentScreen("candidates1")
      setActiveCandidateTab("Your Candidate")
    }
    if (activeTab === "Resume") {
      setActiveTab("JD")
      setShowTable(false)
      setCurrentScreen("candidates")
    }
  }


  const getNavButtonStyle = (currentScreen) => ({
    padding: "10px 20px",
    // background:
    //   "linear-gradient(135deg, rgb(102 126 234 / 52%), rgb(136 89 184 / 52%))",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginRight: "4px",
    background:
      currentScreen === "candidates"
        ? "linear-gradient(135deg, rgb(102 126 234 / 52%), rgb(136 89 184 / 10%))"
        : "linear-gradient(135deg, rgb(102 126 234 / 52%), rgb(136 89 184 / 100%))", // Gradient for other screens
  });
  console.log("ssssssS:", nextArray);
  console.log("currentScreen:", currentScreen);
  const breadcrumbnav = (currentScreen) => ({
    background: nextArray.includes(currentScreen)
      ? "linear-gradient(135deg, #667eea, #8859b8)"
      : "",

    fontWeight: "bold",
    color: "#ffffff",
    padding: "5px 10px",
    borderRadius: "4px",
    margin: "4px",
  });

  // spot code start

  useEffect(() => {
    const storedEmail = localStorage.getItem('CompanyEmailID');
    if (storedEmail) {
      setLocalCompanyEmail(storedEmail);
    }


    const fetchSpotJDS = async () => {
      let spotJDSItemsList = [];
      let currentNextToken = nextToken; // Initialize with state token

      do {
        const response = await API.graphql(
          graphqlOperation(listSpotJDS, {
            filter: {
              companyEmail: { eq: storedEmail }, // Filter by email
            },
            limit: 100, // Adjust the limit as needed
            nextToken: currentNextToken, // Use the current token
          })
        );

        spotJDSItemsList = [
          ...spotJDSItemsList,
          ...response.data.listSpotJDS.items,
        ];

        currentNextToken = response.data.listSpotJDS.nextToken;
      } while (currentNextToken); // Continue until no more tokens

      setSpotJDSItems(spotJDSItemsList);
      setNextToken(currentNextToken); // Update the state with the last token
      console.log("**************************", spotJDSItemsList)
      if (spotJDSItemsList.length > 0) {
        setSpotJD(spotJDSItemsList[0])
      } else {
        console.log('No data available.');
      }
    };



    // fetching spotResume start
    // fetching spotResume end

    fetchSpotJDS();


  }, [])

  const openJdUploadModal = () => setIsJdUploadOpen(true);
  const closeJdUploadModal = () => setIsJdUploadOpen(false);

  const handleJdFileChange = (e) => {
    setJdFile(e.target.files[0]);
  };

  const handleJdFileUpload = async (e) => {
    e.preventDefault()
    if (!jdFile) return;
    if (!jdFile) return; // Check if file is selected

    setIsJdUploading(true);
    setJdError(null);
    setJdSuccess(null);

    const yourS3BucketName = "getscreenedstoragebucketf24ed-devget";
    const folderPath = 'SpotJD/';
    try {
      //   let videoURL = "33333333333333333333333333333333";
      let companyEmail = localCompanyEmail

      let videoURL = null;
      if (jdFile) {
        const videoKey = await Storage.put(
          // original code start
          // videoFile.name,
          // original code end
          `${folderPath}${jdFile.name}`, // Adjusted to include folder path
          jdFile,
          {
            contentType: "video/*",
          }
        );
        // original code start
        // videoURL = `https://${yourS3BucketName}.s3.amazonaws.com/public/${videoKey.key}`;
        // original code end
        videoURL = `https://${yourS3BucketName}.s3.amazonaws.com/public/${videoKey.key}`;
      }

      const input = {
        jdURL: videoURL, // or whatever the URL is
        companyEmail: companyEmail
      };
      const response = await API.graphql(graphqlOperation(createSpotJD, {
        input
      }));
      console.log(response)

      // window.location.reload();

      const { data: { createSpotJD: { id } } } = response;

      console.log("kkkkkkkkkkkkkkk", id)


      // Py Send Data

      const data = {
        id: id,
      };

      // localStorage.setItem('SpotJDID', data.id);

      try {
        // Make the POST request
        const res = await fetch('https://jdwithai-e9e5hke8crfvcrg4.canadacentral-01.azurewebsites.net/extract-job-details', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });


        // Check if the request was successful
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }

        // Parse the JSON response
        const result = await res.json();
        console.log("kkkkkkkkkkkkkkkkkk", result)
        window.location.reload();
        //   setResponse(result);
      } catch (error) {
        console.error('Error:', error);
      }


    } catch (error) {
      setIsJdUploading(false)
    }

  };


  // const handleRadioChange = (id, email) => {
  //   setSelectedJDId(id);
  //   handleSelectJD(id, email);
  // };


  const handleSelectJD = async (id, companyEmail) => {

    setSpecificSelectedJDid(id)
    const emails = []
    // fetching Spot Resume according to Job Id start

    const fetchSpotResumes = async () => {
      let spotResumeItemsList = [];
      let currentNextToken = nextToken; // Initialize with state token

      try {
        do {
          const response = await API.graphql(
            graphqlOperation(listSpotResumes, {
              filter: {
                spotJdID: { eq: id }, // Filter by email
              },
              limit: 100, // Adjust the limit as needed
              nextToken: currentNextToken, // Use the current token
            })
          );

          // Log the full response to inspect its structure
          console.log("GraphQL response:", response);

          // Extract the listSpotResumes and handle undefined cases
          const spotResumesData = response.data?.listSpotResumes || {};
          const items = spotResumesData.items || [];
          const nextToken = spotResumesData.nextToken;

          // Update the items list and next token
          spotResumeItemsList = [
            ...spotResumeItemsList,
            ...items,
          ];

          currentNextToken = nextToken;
        } while (currentNextToken); // Continue until no more tokens

        setSpotResumeItems(spotResumeItemsList); // Update the state with fetched items
        setNextToken(currentNextToken); // Update the state with the last token
        console.log("Fetched Spot Resumes:", spotResumeItemsList);

        if (spotResumeItemsList.length > 0) {
          setSpotJDmachesResumeID(true)
          setSpotResume(spotResumeItemsList[0]); // Set the first resume item as spotResume
          // setAiResumesResponce(spotResumeItemsList[0].filterCandidate)
          const filterCandidate = spotResumeItemsList[0].filterCandidate;
          setAiResumesResponce(JSON.parse(filterCandidate || "[]")); // Parse the string or set to empty array if undefined
          localStorage.setItem('SpotResumeID', spotResumeItemsList[0].id);
        } else {
          console.log('No data available.');
        }
      } catch (error) {
        console.error('Error fetching SpotResumes:', error);
      }
    };

    fetchSpotResumes();

    // fetching Spot Resume according to Job Id end 

    // saving SpotJDID At local Storage
    localStorage.setItem('SpotJDID', id);

    try {

      const data = {
        id: id,
      };

      try {
        // Make the POST request
        const res = await fetch('https://jdwithai-e9e5hke8crfvcrg4.canadacentral-01.azurewebsites.net/shortlist-candidates', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });


        // Check if the request was successful
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }

        // Parse the JSON response
        const result = await res.json();
        console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeee", result)
        // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeee", result.detailed_profiles)
        setServerEmails(result.detailed_profiles)
        // setEmails(result.emails)
        const { emails } = result;

        //   fetching profile and evaution code start

        const fetchCandidateProfiles = async (nextToken = null) => {
          const limit = 100; // Adjust limit as needed

          // Create the filter to match any of the emails
          const filter = {
            or: emails.map(email => ({ email: { eq: email } })),
          };

          try {
            const response = await API.graphql(
              graphqlOperation(listCandidateProfiles, {
                filter,
                limit,
                nextToken,
              })
            );

            const { items, nextToken: newNextToken } = response.data.listCandidateProfiles;

            // Update profiles state with fetched items
            setProfiles(prev => [...prev, ...items]);

            if (newNextToken) {
              // Fetch more items if there is a nextToken
              await fetchCandidateProfiles(newNextToken);
            }
          } catch (error) {
            console.error('Error fetching candidate profiles:', error);
          }
        };

        const handleFetch = async () => {
          setIsLoading(true);
          setProfiles([]); // Reset profiles on new fetch
          await fetchCandidateProfiles();
          setIsLoading(false);
        };


        // const [isFetchingEvaluations, setIsFetchingEvaluations] = useState(false);
        // const [evaluations, setEvaluations] = useState([]);

        const fetchInterviewEvaluations = async (nextToken = null) => {
          const limit = 100; // Adjust limit as needed

          // Create the filter to match any of the candidate emails
          const filter = {
            or: emails.map(email => ({ candidateEmail: { eq: email } })),
          };

          try {
            const response = await API.graphql(
              graphqlOperation(listInterviewEvaluations, {
                filter,
                limit,
                nextToken,
              })
            );

            const { items, nextToken: newNextToken } = response.data.listInterviewEvaluations;

            // Update evaluations state with fetched items
            setEvaluations(prev => [...prev, ...items]);

            if (newNextToken) {
              // Fetch more items if there is a nextToken
              await fetchInterviewEvaluations(newNextToken);
            }
          } catch (error) {
            console.error('Error fetching interview evaluations:', error);
          }
        };

        const handleEvalutionFetch = async () => {
          setIsFetchingEvaluations(true);
          setEvaluations([]); // Reset evaluations on new fetch
          await fetchInterviewEvaluations();
          setIsFetchingEvaluations(false);
        };

        await handleFetch()

        await new Promise(resolve => setTimeout(resolve, 10000));

        await handleEvalutionFetch()

        // fecthing profile and evaution code end

        // console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%",combinedProfilesWithEvaluations)

        // window.location.reload();
        //   setResponse(result);
      } catch (error) {
        console.error('Error:', error);
      }



    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleJobCardClick = (job) => {
    setSelectedJobCard(job);
    setIsJobCardModalOpen(true);

    setSelectedJDId(job.id);
    handleSelectJD(job.id, job.companyEmail);

  };


  // Function to close the modal
  const handleJobCardModalClose = () => {
    setIsJobCardModalOpen(false);
    setSelectedJobCard(null);
  };

  //resume code start

  const openResumeModal = () => setIsResumeModalOpen(true);
  const closeResumeModal = () => setIsResumeModalOpen(false);

  // Handle file input change
  const handleResumeFileChange = (event) => {
    setResumeFiles([...event.target.files]);
  };

  // Simulate file upload and collect file names
  const simulateFileUpload = async (files) => {
    const fileNames = [];

    const yourS3BucketName = "getscreenedstoragebucketf24ed-devget";
    const folderPath = 'SpotResume/';


    console.log("------------------", files.name)
    console.log("******************", files)


    for (const file of files) {

      if (files) {
        const fileName = file.name;
        const videoKey = await Storage.put(
          // original code start
          // videoFile.name,
          // original code end
          `${folderPath}${fileName}`, // Adjusted to include folder path
          file,
          {
            contentType: file.type,
          }
        );
        // original code start
        // videoURL = `https://${yourS3BucketName}.s3.amazonaws.com/public/${videoKey.key}`;
        // original code end
        let url = `https://${yourS3BucketName}.s3.amazonaws.com/public/${videoKey.key}`;

        fileNames.push(url);
      }
    }

    return fileNames;
  };

  // Save file names locally as JSON
  const saveFileNamesLocally = (fileNames) => {
    // looking json data start
    const jsonData = JSON.stringify({ resumes: fileNames }, null, 2);
    console.log('File names JSON: ************************', jsonData);
    //upload table

    const updatetable = async (jsonData) => {

      let companyEmail = localCompanyEmail
      let jdid = specificSelectedJDid
      const input = {
        // jdURL: videoURL, // or whatever the URL is
        companyEmail: companyEmail,
        spotJdID: jdid,
        resumeURLs: jsonData,
      };
      const response = await API.graphql(graphqlOperation(createSpotResume, {
        input
      }));
      console.log("[][[][][[]][][[[][][][][[[][][][][][][]]", response)

      console.log("uuuuuuuuuuuuuuuuuuuuuuuuuuuu", companyEmail)
      console.log("iiiiiiiiiiiiiiiiiiiiiiiiiiiii", jsonData)
      console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx", jdid)

      const { data: { createSpotResume: { id } } } = response;

      console.log("--------------------------------", id)
      localStorage.setItem('SpotResumeID', id);


      //  fetching the create resume shortlisting request for ai cdde  

      const ResumeShortlistReqApi = async (id) => {
        console.log("7979797979797979797979797979", id)

        if (!id) {
          return
        }

        const input = {
          resume_id: id
        };

        try {
          // Replace with your API endpoint
          const response = await fetch('https://resumeai-budueug9cybsgbaj.canadacentral-01.azurewebsites.net/process_resume', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(input),
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          console.log("951595159515951595159515951595159", data)
          // console.log("951595159515951595159515951595159", data.detailed_profiles)
          setAiResumesResponce(data.detailed_profiles)
          // setServerEmails(data);
        } catch (error) {
          // setError(error.message);
          console.log(error.message)
        } finally {
          // setLoading(false);
        }

      }

      ResumeShortlistReqApi(id)

    }

    updatetable(jsonData)






    // looking json data end
    const blob = new Blob([JSON.stringify({ resumes: fileNames }, null, 2)], { type: 'application/json' });
    console.log("5555555555555555555555555555555555", blob)
    // const url = URL.createObjectURL(blob);
    // const a = document.createElement('a');
    // a.href = url;
    // a.download = 'resume-urls.json';
    // a.click();
    // URL.revokeObjectURL(url);
  };

  // Handle form submission
  const handleResumeUpload = async (e) => {
    e.preventDefault();
    if (resumeFiles.length === 0) return;

    setIsResumeUploading(true);

    setIsUploading(true);
    setUploadError(null);
    setUploadSuccess(null);

    try {
      // Simulate file upload and save file names
      const fileNames = await simulateFileUpload(resumeFiles);
      saveFileNamesLocally(fileNames);
      setUploadSuccess('Files processed and JSON saved locally successfully.');
      setIsResumeModalOpen(false)
    } catch (error) {
      console.error('Processing error:', error);
      setUploadError('An error occurred during the file processing.');

    } finally {
      setIsUploading(false);
      setIsResumeUploading(false);
    }
  };



  //  outrich code start

  // below code causing the problem of when shifting ai to database selection getting reset dont use this code

  // useEffect(() => {
  //   if (selectedActiveTab === 'database') { // renamed variable
  //     handleDatabaseSelectionCountChange({ target: { value: databaseSelectionCount } });
  //   } else {
  //     handleAiSelectionCountChange({ target: { value: aiSelectionCount } });
  //   }
  // }, [selectedActiveTab, databaseSelectionCount, aiSelectionCount]); // renamed variable

  // below code causing the problem of when shifting ai to database selection getting reset dont use this code 


  const handleSelectCandidate = (candidate) => {
    if (selectedActiveTab === 'database') { // renamed variable
      setSelectedDatabaseCandidates((prev) => {
        if (prev.find((item) => item.id === candidate.id)) {
          return prev.filter((item) => item.id !== candidate.id);
        } else {
          return [...prev, candidate];
        }
      });
    } else {
      setSelectedAiResumes((prev) => {
        if (prev.find((item) => item.id === candidate.id)) {
          return prev.filter((item) => item.id !== candidate.id);
        } else {
          return [...prev, candidate];
        }
      });
    }
  };

  const handleDatabaseSelectionCountChange = (e) => {
    const count = parseInt(e.target.value, 10);
    setDatabaseSelectionCount(e.target.value);
    const sortedDatabaseCandidates = serverEmails
      .sort((a, b) => a.id - b.id)
      .slice(0, count);
    setSelectedDatabaseCandidates(sortedDatabaseCandidates);
  };

  const handleAiSelectionCountChange = (e) => {
    const count = parseInt(e.target.value, 10);
    setAiSelectionCount(e.target.value);
    const sortedAiResumes = aiResumesResponce
      .sort((a, b) => b.fitScore - a.fitScore)
      .slice(0, count);
    setSelectedAiResumes(sortedAiResumes);
  };

  const toggleSelectAll = () => {
    if (selectedActiveTab === 'database') { // renamed variable
      const allSelected = selectedDatabaseCandidates.length === serverEmails.length;
      setSelectedDatabaseCandidates(allSelected ? [] : serverEmails);
    } else {
      const allSelected = selectedAiResumes.length === aiResumesResponce.length;
      setSelectedAiResumes(allSelected ? [] : aiResumesResponce);
    }
  };

  const saveSelectedCandidates = () => {
    console.log('Selected Database Candidates:', selectedDatabaseCandidates);
    console.log('Selected AI Resumes:', selectedAiResumes);

    // const updateSpotCandidateAndResume = async () => {

    //   if (localStorage.getItem('SpotJDID')) {

    //     const jdid = localStorage.getItem('SpotJDID')
    //     const companyEmail = localCompanyEmail
    //     const selectedCandidateforSpot = JSON.stringify(selectedDatabaseCandidates)

    //     const input = {
    //       // companyEmail: companyEmail,
    //       id: jdid,
    //       selectedCandidate: selectedCandidateforSpot,
    //     };
    //     const response = await API.graphql(graphqlOperation(updateSpotJD, {
    //       input
    //     }));

    //     console.log("from save jd", jdid)
    //     console.log("from save jd ", companyEmail)
    //     console.log("from save jd sleected", selectedCandidateforSpot)
    //     console.log("from save jd responce", response)

    //   }

    //   if (localStorage.getItem('SpotResumeID')) {

    //     const SpotResumeID = localStorage.getItem('SpotResumeID')
    //     const companyEmail = localCompanyEmail
    //     const selectedCandidateforResume = JSON.stringify(selectedAiResumes)

    //     const input = {
    //       // companyEmail: companyEmail,
    //       id: SpotResumeID,
    //       selectedCandidate: selectedCandidateforResume,
    //     };
    //     const response = await API.graphql(graphqlOperation(updateSpotResume, {
    //       input
    //     }));

    //     console.log("from save jd", SpotResumeID)
    //     console.log("from save jd ", companyEmail)
    //     console.log("from save jdResume ", selectedCandidateforResume)
    //     console.log("from save jdResume responce", response)

    //   }

    //   navigate('/spotGPT')

    // }

    // updateSpotCandidateAndResume()

  };

  const truncateDescription = (description) => {
    const words = description.split(' ');
    if (words.length > 10) {
      return words.slice(0, 20).join(' ') + '...';
    }
    return description;
  };

  const renderContent = () => {
    const items = selectedActiveTab === 'database' ? serverEmails : aiResumesResponce; // renamed variable
    const selectedItems = selectedActiveTab === 'database' ? selectedDatabaseCandidates : selectedAiResumes; // renamed variable

    return (

      <div className="grid grid-cols-3 gap-2">
        {items.map((item) => (
          <div
            key={item.id}
            className="p-4 flex items-center justify-center border-b border-gray-200 card overflow-hidden bg-white"
          >
            <div className="flex items-center ">
              <div>
                <div className=" flex items-center justify-center ">
                  <img src={profile} alt="" className="w-24 h-24 rounded-full object-cover items-center" />
                </div>
                <div className="text-lg font-bold text-black flex items-center justify-center mt-2">
                  {item.name || item.email}
                </div>
                <div className="text-gray-600  mt-2">
                  {selectedActiveTab === "database"
                    ? truncateDescription(item.description)
                    : truncateDescription(item.comment)}
                </div>
                {selectedActiveTab === "ai" && (
                  <div className=" fitscore flex items-center justify-center text-black font-bold" style={{ backgroundColor: 'rgba(221, 200, 255, 0.84)' }}>
                    Fit Score : <span className="score">{item.fitScore}</span>
                  </div>
                )}
                <div className="flex items-center justify-between input-bar mt-2" style={{ backgroundColor: 'rgba(221, 200, 255, 0.84)' }}>
                  <div className="text-gray-400 flex items-center justify-between overflow-hidden">
                    {item.keySkills.split(",").slice(0, 2).map((skill, index) => (
                      <span key={index} className="mr-2 skill text-nowrap" style={styles.jdSkill}>
                        {skill.trim()}
                      </span>
                    ))}
                  </div>
                  <input
                    type="checkbox"
                    checked={selectedItems.some((c) => c.id === item.id)}
                    onChange={() => handleSelectCandidate(item)}
                    className="mr-3 w-5 h-5"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  // outrich code end

  // JD form code start

  const handleJdChange = (e) => {
    setJdFormData({ ...jdFormData, [e.target.name]: e.target.value });
  };

  const handleJdSubmit = (e) => {
    e.preventDefault();
    console.log(jdFormData);
    // You can handle form submission here (e.g., send data to an API)
    setJdIsModalOpen(false); // Close modal after submission
  };


  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const handleExperienceSelect = (experience) => {
    setSelectedExperience(experience);
  };



  const renderCategoryButtons = () => {
    const categories = [
      "Full-time",
      "Part-time",
      "Contract",
      "Temporary",
      "Internship",
    ];
    return categories.map((category, index) => (
      <button
        key={index}
        style={{
          padding: "5px 15px",
          backgroundColor:
            selectedCategory === category ? "#9900ff" : "#f3f3f3",
          borderRadius: "15px",
          cursor: "pointer",
          transition: "background-color 0.3s",
          marginRight: "5px",
          marginBottom: "5px",
          color: selectedCategory === category ? "#fff" : "#000",
          border: "1px solid #9900ff",
        }}
        onClick={() => handleCategorySelect(category)}
      >
        {category}
      </button>
    ));
  };

  const renderExperienceButtons = () => {

    // original code start

    // const experienceLevels = [
    //   { label: "Fresher\nJust out of college", value: "Fresher" },
    //   { label: "Entry Level\n1-3 years", value: "Entry Level" },
    //   { label: "Mid Level\n3-5 years", value: "Mid Level" },
    //   { label: "Senior Level\n5+ years", value: "Senior Level" },
    // ];

    // original code end

    const experienceLevels = [
      { label: "Fresher\nJust out of college", value: "Fresher" },
      { label: "Entry Level\n1-3 years", value: "Entry Level 1-3 years" },
      { label: "Mid Level\n3-5 years", value: "Mid Level 3-5 years" },
      { label: "Senior Level\n5+ years", value: "Senior Level 5+ years" },
    ];

    return experienceLevels.map((experience, index) => (
      <button
        key={index}
        style={{
          backgroundColor:
            selectedExperience === experience.value ? "#9900ff" : "#1b1935",
          border: "1px solid #9900ff",
          borderRadius: "5px",
          padding: "15px 10px",
          textAlign: "center",
          fontSize: "14px",
          cursor: "pointer",
          flexGrow: "1",
          margin: "0 5px",
          transition: "border-color 0.3s ease",
        }}
        onClick={() => handleExperienceSelect(experience.value)}
      >
        {experience.label}
      </button>
    ));
  };


  const handleWorkModeSelect = (mode) => {
    setWorkMode(mode);
  };

  const renderWorkModeOptions = () => {
    const workModes = ["Work from home", "Remote", "Work from office"];

    return workModes.map((mode, index) => (
      <button
        key={index}
        style={{
          padding: "5px 15px",
          backgroundColor: workMode === mode ? "#9900ff" : "#f3f3f3",
          borderRadius: "15px",
          cursor: "pointer",
          transition: "background-color 0.3s",
          marginRight: "5px",
          marginBottom: "5px",
          color: workMode === mode ? "#fff" : "#000",
          border: "1px solid #9900ff",
        }}
        onClick={() => handleWorkModeSelect(mode)}
      >
        {mode}
      </button>
    ));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const skillArray = skill.split(',').map(s => s.trim());
    // Check if all mandatory fields are filled out
    if (
      !jobTitle ||
      !selectedCategory ||
      !skill ||
      !jobDescription ||
      !workMode // Check if work mode is selected
    ) {
      alert("Please fill out all mandatory fields.");
      return;
    }

    setCreatejdIsApiWorking(true);

    // create JD code working start 

    const doc = new jsPDF();

    // Add content to the PDF
    doc.text(`Job Title: ${jobTitle}`, 10, 10);
    doc.text(`Category: ${selectedCategory}`, 10, 20);
    doc.text(`Skills: ${skillArray.join(', ')}`, 10, 30);
    doc.text(`Description: ${jobDescription}`, 10, 40);
    doc.text(`Work Mode: ${workMode}`, 10, 50);

    // Save the PDF
    // doc.save('job-posting.pdf');

    // Generate a unique filename using 
    const sanitizedJobTitle = jobTitle.replace(/[^a-z0-9]/gi, '_').toLowerCase(); // Sanitize job title for filename
    const timestamp = new Date().toISOString().replace(/[:.-]/g, '');
    const randomNum = Math.floor(Math.random() * 10000); // Random number between 0 and 9999
    // const uniqueFilename = `job-posting-${timestamp}-${randomNum}.pdf`;
    const uniqueFilename = `${sanitizedJobTitle}-job-posting-${timestamp}-${randomNum}.pdf`;

    // Convert PDF to Blob
    const pdfBlob = doc.output('blob');

    const yourS3BucketName = "getscreenedstoragebucketf24ed-devget";
    const folderPath = 'SpotJD/';

    try {

      let companyEmail = localCompanyEmail

      let videoURL = null;
      // Upload the PDF to S3
      const videoKey = await Storage.put(
        `${folderPath}${uniqueFilename}`, // Include folder path if needed
        pdfBlob,
        {
          contentType: "application/pdf", // Set the content type for PDF
        }
      );

      videoURL = `https://${yourS3BucketName}.s3.amazonaws.com/public/${videoKey.key}`;


      const input = {
        companyEmail: companyEmail, // Only email
        jdURL: videoURL, // S3 bucket URL
      };


      const response = await API.graphql(graphqlOperation(createSpotJD, { input }));
      console.log(response);


      const { data: { createSpotJD: { id } } } = response;

      console.log("kkkkkkkkkkkkkkk", id)

      const data = {
        id: id,
      };


      // Make the POST request
      const res = await fetch('https://95c0-20-70-140-142.ngrok-free.app/extract-job-details', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!res.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await res.json();
      console.log("kkkkkkkkkkkkkkkkkk", result)

      window.location.reload();

    } catch (error) {
      console.error("Error uploading file or updating GraphQL:", error);
      alert("An error occurred. Please try again."); // Alert user on error
    } finally {
      setCreatejdIsApiWorking(false); // Reset the loading state
    }

    // create JD code working end 

  }



  //  JD Form Code 

  // testing candidate profile and evautuion code start
  // const [isLoading, setIsLoading] = useState(false);
  // const [profiles, setProfiles] = useState([]);


  // const fetchCandidateProfiles = async (nextToken = null) => {
  //   const limit = 100; // Adjust limit as needed

  //   // Create the filter to match any of the emails
  //   const filter = {
  //     or: emails.map(email => ({ email: { eq: email } })),
  //   };

  //   try {
  //     const response = await API.graphql(
  //       graphqlOperation(listCandidateProfiles, {
  //         filter,
  //         limit,
  //         nextToken,
  //       })
  //     );

  //     const { items, nextToken: newNextToken } = response.data.listCandidateProfiles;

  //     // Update profiles state with fetched items
  //     setProfiles(prev => [...prev, ...items]);

  //     if (newNextToken) {
  //       // Fetch more items if there is a nextToken
  //       await fetchCandidateProfiles(newNextToken);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching candidate profiles:', error);
  //   }
  // };

  // const handleFetch = async () => {
  //   setIsLoading(true);
  //   setProfiles([]); // Reset profiles on new fetch
  //   await fetchCandidateProfiles();
  //   setIsLoading(false);
  // };


  // // const [isFetchingEvaluations, setIsFetchingEvaluations] = useState(false);
  // // const [evaluations, setEvaluations] = useState([]);

  // const fetchInterviewEvaluations = async (nextToken = null) => {
  //   const limit = 100; // Adjust limit as needed

  //   // Create the filter to match any of the candidate emails
  //   const filter = {
  //     or: emails.map(email => ({ candidateEmail: { eq: email } })),
  //   };

  //   try {
  //     const response = await API.graphql(
  //       graphqlOperation(listInterviewEvaluations, {
  //         filter,
  //         limit,
  //         nextToken,
  //       })
  //     );

  //     const { items, nextToken: newNextToken } = response.data.listInterviewEvaluations;

  //     // Update evaluations state with fetched items
  //     setEvaluations(prev => [...prev, ...items]);

  //     if (newNextToken) {
  //       // Fetch more items if there is a nextToken
  //       await fetchInterviewEvaluations(newNextToken);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching interview evaluations:', error);
  //   }
  // };

  // const handleEvalutionFetch = async () => {
  //   setIsFetchingEvaluations(true);
  //   setEvaluations([]); // Reset evaluations on new fetch
  //   await fetchInterviewEvaluations();
  //   setIsFetchingEvaluations(false);
  // };


  console.log("565656565656565656565656565656565656", profiles)
  console.log("676767676767676767676767676767676767", evaluations)

  // first working code start

  // const [combinedProfilesWithEvaluations, setCombinedProfilesWithEvaluations] = useState([]);

  useEffect(() => {
    if (profiles.length) {
      const combinedProfiles = profiles.map((profile, index) => {
        // Find matching evaluations by candidateEmail and profile.email
        const matchedEvaluations = evaluations.filter(
          evaluation => evaluation.candidateEmail === profile.email
        );

        // Prepare evaluation fields directly in the profile
        const evaluationData = {};
        matchedEvaluations.forEach((evaluation, idx) => {
          evaluationData[`evaluation${idx + 1}Email`] = evaluation.candidateEmail;
          evaluationData[`evaluation${idx + 1}Transcript`] = evaluation.transcript;
          evaluationData[`evaluation${idx + 1}VideoURL`] = evaluation.videoURL;
          evaluationData[`evaluation${idx + 1}evaluation`] = evaluation.evaluation;
        });

        // Return the profile with merged evaluation data (if any)
        return {
          customId: index + 1,  // New custom ID
          ...profile,           // Existing profile fields (including original id)
          ...evaluationData,    // Add each evaluation field directly to the profile
        };
      });

      setCombinedProfilesWithEvaluations(combinedProfiles);
    }
  }, [profiles, evaluations]);

  // first working code end


  console.log("78787878787878787878787878787878", combinedProfilesWithEvaluations)


  // testing candidate profile and evaution code end


  // selecting candidate from prepare code start

  const [selectedProfiles, setSelectedProfiles] = useState([]);

  // Handle profile selection/deselection
  const handleProfileSelection = (profile) => {
    if (selectedProfiles.includes(profile)) {
      setSelectedProfiles(selectedProfiles.filter((p) => p !== profile));
    } else {
      setSelectedProfiles([...selectedProfiles, profile]);
    }
  };

  // Select the top N profiles
  const selectTopProfiles = (num) => {
    const topProfiles = combinedProfilesWithEvaluations.slice(0, num);
    setSelectedProfiles(topProfiles);
  };

  // Deselect all profiles
  const deselectAllProfiles = () => {
    setSelectedProfiles([]);
  };

  // Save selected profiles
  const handleSaveSelectedProfiles = async () => {
    console.log("Selected Profiles: ", selectedProfiles);



    if (localStorage.getItem('SpotJDID')) {

      const jdid = localStorage.getItem('SpotJDID')
      const companyEmail = localCompanyEmail
      const selectedCandidateforSpot = JSON.stringify(selectedProfiles)


      const input = {
        // companyEmail: companyEmail,
        id: jdid,
        selectedCandidate: selectedCandidateforSpot,
        filterCandidate: selectedCandidateforSpot
      };
      const response = await API.graphql(graphqlOperation(updateSpotJD, {
        input
      }));

      console.log("from save jd", jdid)
      console.log("from save jd ", companyEmail)
      console.log("from save jd sleected", selectedCandidateforSpot)
      console.log("from save jd responce", response)

    }


  };

  // selecting candidate from preapare code end



  // selecting candidate from prepare resume code start

  const [selectedResumeCandidates, setSelectedResumeCandidates] = useState([]); // Updated naming convention

  // Handle resume candidate selection/deselection
  const handleResumeCandidateSelection = (candidate) => {
    if (selectedResumeCandidates.includes(candidate)) {
      // Deselect candidate
      setSelectedResumeCandidates(selectedResumeCandidates.filter((c) => c !== candidate));
    } else {
      // Select candidate
      setSelectedResumeCandidates([...selectedResumeCandidates, candidate]);
    }
  };

  // Save selected resume candidates
  const handleSaveSelectedResumeCandidates = () => {
    console.log("Selected Resume Candidates: ", selectedResumeCandidates);

    const updateSpotCandidateAndResume = async () => {



      if (localStorage.getItem('SpotResumeID')) {

        const SpotResumeID = localStorage.getItem('SpotResumeID')
        const companyEmail = localCompanyEmail
        const selectedCandidateforResume = JSON.stringify(selectedResumeCandidates)

        const input = {
          // companyEmail: companyEmail,
          id: SpotResumeID,
          selectedCandidate: selectedCandidateforResume,
          filterCandidate: selectedCandidateforResume
        };
        const response = await API.graphql(graphqlOperation(updateSpotResume, {
          input
        }));

        console.log("from save jd", SpotResumeID)
        console.log("from save jd ", companyEmail)
        console.log("from save jdResume ", selectedCandidateforResume)
        console.log("from save jdResume responce", response)

      }


    }

    updateSpotCandidateAndResume()


  };

  // Select top N candidates based on button click
  const handleSelectTop = (count) => {
    const topCandidates = aiResumesResponce.slice(0, count);
    setSelectedResumeCandidates(topCandidates);
  };

  // selecting candidate from prepare resume code end


  // outreach data show code start

  const [showOutreachProfiles, setShowOutreachProfiles] = useState(true);
  const [emailSent, setEmailSent] = useState(false);

  const toggleOutreachView = () => {
    setShowOutreachProfiles(!showOutreachProfiles);
  };

  const handleSendMail = () => {
    setEmailSent(true);
    // Logic for sending email goes here
  };

  // outreach data show code end




  console.log("121212121212121212121212121212121212121212112", activeTab)
  console.log("232323232323232323232323232332323232323232323", activeCandidateTab)
  console.log("343434343434343434343434343434343434343434343", currentScreen)
  console.log("343434343434343434343434343434343434343434343", showTable)
  console.log("454545454454545454545454545454545454545454454", aiResumesResponce)

  return (
    <div>
      <Header />
      <div style={styles.body}>
        {/* Header Section */}
        <div style={styles.header}>
          {/* <div style={styles.username}> 👨‍🦰 Username</div> */}
          {/* <img
          style={styles.username2}
          src=""
          alt=""
          srcset="https://www.getscreened.in/logogetscreened.svg"
        /> */}
          <div style={styles.steps}>
            <span style={styles.SpanSteps} className="spanSteps">
              📇{" "}
              <span style={styles.spot} className="spot">
                S
              </span>
              pecify{" "}
            </span>{" "}
            &gt;{" "}
            <span style={activeTab === "Resume" || activeTab === "Outrich select" ? styles.SpanSteps : breadcrumbnav("candidates1")}
              className="spanSteps">
              📑
              <span style={styles.spot2} className="spot2">
                P
              </span>
              repare{" "}
            </span>{" "}
            &gt;{" "}
            <span style={activeTab === "Outrich select" ? styles.SpanSteps : breadcrumbnav("candidates1")} className="spanSteps">
              🔎
              <span style={styles.spot2} className="spot2">
                O
              </span>
              utreach{" "}
            </span>{" "}
            &gt;{" "}
            <span className="spanSteps">
              🎯
              <span style={styles.spot2} className="spot2">
                T
              </span>
              arget
            </span>
            <span style={styles.Target} onClick={TargetClick}>
              {targetTable ? "❌ Close" : "🎯 Targeted Candidate"}
            </span>
            {/* <div style={styles.username}> 👨‍🦰</div> */}
          </div>
        </div>

        <div style={styles.container}>
          {/* Main Content */}

          <div style={styles.mainContent} className="mainContent">
            {/* JD Section */}

            {isSectionVisible && (
              <div style={styles.jdSection} className="jdSection">
                {activeTab === "JD" && (
                  <>
                    <div style={styles.jdUpload}>
                      <button style={styles.uploadButton} onClick={openJdUploadModal} >📂 Upload JD</button>
                      <button style={styles.jdCreation} onClick={() => setJdIsModalOpen(true)} >+</button>

                      {/* testing code candidate profile start */}

                      {/* <button onClick={handleFetch} disabled={isLoading}>
                        {isLoading ? 'Fetching...' : 'Fetch Candidate Profiles'}
                      </button>
                      <button onClick={handleEvalutionFetch} disabled={isFetchingEvaluations}>
                        {isFetchingEvaluations ? 'Fetching...' : 'Fetch Interview Evaluations'}
                      </button> */}

                      {/* testing code candidate prfile end */}

                      {isJdUploadOpen && (
                        <div
                          // style={{ zIndex: 1, position: 'relative' }}
                          style={{ zIndex: 1 }}
                          className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center "
                          onClick={closeJdUploadModal}

                        >
                          {/* originl code start */}
                          {/* <div
                          className=" p-6 rounded-lg w-80 text-center"
                          style={{
                            background: 'linear-gradient(rgb(96, 61, 191), rgb(20, 8, 46))'
                          }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <h2 className="text-xl font-semibold mb-4">Upload JD</h2>
                          <input
                            type="file"
                            accept=".pdf" // Restrict file selection to PDFs
                            onChange={handleJdFileChange}
                            className="border rounded p-2 mb-4"
                          />
                          <button
                            onClick={handleJdFileUpload}
                            disabled={isJdUploading || !jdFile}
                            // className={`bg-blue-500 text-white p-2 rounded hover:bg-blue-600 ${isJdUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            style={styles.uploadButton}
                          >
                            {isJdUploading ? 'Uploading...' : 'Upload File'}
                          </button>
                          {jdError && <p className="text-red-500 mt-4">{jdError}</p>}
                          {jdSuccess && <p className="text-green-500 mt-4">{jdSuccess}</p>}
                          <button
                            onClick={closeJdUploadModal}
                            className="mt-4 bg-red-500 text-white p-2 rounded hover:bg-red-600"
                          >
                            Close
                          </button>
                        </div> */}
                          {/* original code end */}

                          <div
                            className="p-6 rounded-lg w-80 text-center bg-gradient-to-r from-purple-700 via-indigo-800 to-black shadow-lg"
                            style={{ background: 'linear-gradient(168deg, rgb(39, 44, 88), rgb(65, 36, 94))' }}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <h2 className="text-2xl font-bold text-white mb-4">Upload JD</h2>
                            <input
                              type="file"
                              accept=".pdf"
                              onChange={handleJdFileChange}
                              className="border border-gray-300 rounded p-2 mb-4 w-full text-gray-800"
                            />
                            <button
                              onClick={handleJdFileUpload}
                              disabled={isJdUploading || !jdFile}
                              className={`bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition ${isJdUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                              {isJdUploading ? 'Uploading...' : 'Upload File'}
                            </button>
                            {jdError && <p className="text-red-400 mt-4">{jdError}</p>}
                            {jdSuccess && <p className="text-green-400 mt-4">{jdSuccess}</p>}
                            <button
                              onClick={closeJdUploadModal}
                              className="mt-4 bg-red-600 text-white py-2 px-4 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 transition"
                            >
                              Close
                            </button>
                          </div>


                        </div>
                      )}

                    </div>

                    <div style={styles.jdList} className="text-black">
                      {spotJDSItems.map((value, index) => (
                        <div style={styles.jdCard} key={index} onClick={() => handleJobCardClick(value)}>
                          <div style={styles.jdContent}>
                            {/* <div>983 data from specify JD list: {value.jdName}</div> */}
                            <div>{value.jdName}</div>
                            <div>
                              <button style={styles.iconButton}>🔗</button>
                              <button style={styles.CloseIconButton}>✖️</button>
                            </div>
                          </div>

                          <div style={styles.candidteContent}>
                            <span style={styles.candidteDiscription}>
                              {value.summary}
                            </span>
                          </div>

                          <div style={styles.jdSkillContent}>
                            {/* <span style={styles.jdSkill}>HTML</span>
                          <span style={styles.jdSkill}>CSS</span>
                          <span style={styles.jdSkill}>JS</span> */}

                            {/* <span className="text-black"style={styles.jdSkill}>
                            {(() => {
                              // Inline conversion logic for skills
                              const skillsArray = typeof value.skills === 'string'
                                ? value.skills
                                  .replace(/[\[\]"]+/g, '') // Remove square brackets and quotes
                                  .split(',')              // Split by comma
                                  .map(skill => skill.trim()) // Trim whitespace from each skill
                                : value.skills || []; // Use existing array if it's already an array

                              return skillsArray.length > 0
                                ? skillsArray.join(', ') // Join skills with comma and space
                                : 'No skills available'; // Default message if no skills
                            })()}
                          </span> */}

                            {/* <span className="text-black" style={{ ...styles.jdSkill }}> */}
                            {(() => {
                              // Inline conversion logic for skills
                              const skillsArray = typeof value.skills === 'string'
                                ? value.skills
                                  .replace(/[\[\]"]+/g, '') // Remove square brackets and quotes
                                  .split(',')              // Split by comma
                                  .map(skill => skill.trim()) // Trim whitespace from each skill
                                : value.skills || []; // Use existing array if it's already an array

                              // Get only the first three skills
                              const displayedSkills = skillsArray.slice(0, 3);

                              // Render skills with individual background
                              return displayedSkills.length > 0
                                ? displayedSkills.map((skill, index) => (
                                  <React.Fragment key={index}>
                                    <span
                                      className="inline-block bg-blue-200 text-black rounded mr-1"
                                      style={styles.jdSkill} // Example custom background color
                                    >
                                      {skill}
                                    </span>
                                    {index < displayedSkills.length - 1 && ', '} {/* Add comma if not the last item */}
                                  </React.Fragment>
                                ))
                                : 'No skills available'; // Default message if no skills
                            })()}

                            {/* </span> */}


                          </div>
                          <div style={styles.iconContainer}>
                            <div style={styles.blogcontainer}>
                              <div style={styles.blog}>
                                <span>Total</span>
                                <div>50</div>
                              </div>
                              <div style={styles.blog}>
                                <span>Total</span>
                                <div>50</div>
                              </div>
                              <div style={styles.blog}>
                                <span>Total</span>
                                <div>50</div>
                              </div>
                            </div>
                            {/* <input
                            type="radio"
                            name="select-jd"
                            style={styles.radio}
                            checked={isSelected}
                            onChange={() => handleRadioChange(value.id, value.companyEmail)}
                          /> */}

                            <img src={iconinformation} alt="hello" style={styles.radio} className="h-[20px] w-[20px]" />

                          </div>
                        </div>
                      ))}
                    </div>

                    {/* <div style={styles.jdList} className="text-black">
                    {spotJDSItems.map((value, index) => {
                      // Inline JSON parsing and mapping
                      let skillsArray = [];
                      let languagesArray = [];

                      try {
                        skillsArray = JSON.parse(value.skills);
                        console.log()
                      } catch (error) {
                        console.error("Error parsing skills JSON:", error);
                      }

                      try {
                        languagesArray = JSON.parse(value.languages);
                      } catch (error) {
                        console.error("Error parsing languages JSON:", error);
                      }

                      return (
                        <div style={styles.jdCard} key={index}>
                          <div style={styles.jdContent}>
                            <div>668{value.jdName}</div>
                            <input
                              type="radio"
                              name="select-jd"
                              style={styles.radio}
                              onChange={() => handleRadioChange(value.id, value.companyEmail)}
                            />
                          </div>
                          <div style={styles.jdContent}>
                            <span style={styles.jdDiscription}>
                              {value.summary}
                            </span>
                          </div>
                          <div style={styles.jdSkillContent}>
                            {skillsArray.length > 0 ? (
                              skillsArray.map((skill, skillIndex) => (
                                <span key={skillIndex} style={styles.jdSkill}>{skill}</span>
                              ))
                            ) : (
                              <span>No skills available</span>
                            )}
                          </div>
                          <div style={styles.jdSkillContent}>
                            {languagesArray.length > 0 ? (
                              languagesArray.map((language, langIndex) => (
                                <span key={langIndex} style={styles.jdSkill}>{language}</span>
                              ))
                            ) : (
                              <span>No languages available</span>
                            )}
                          </div>
                          <div style={styles.iconContainer}>
                            <button style={styles.iconButton}>✖️</button>
                            <button style={styles.iconButton}>🔗</button>
                          </div>
                          <button
                            // onClick={() => handleSelectJD(value.id, value.companyEmail)}
                            className="p-2 rounded-lg"
                          >
                            Select
                          </button>
                        </div>
                      );
                    })}
                  </div> */}

                    {/* <div style={styles.jdList}>
      {spotJDSItems.map((value, index) => {
        let skillsArray = [];
        let languagesArray = [];

        try {
          skillsArray = JSON.parse(value.skills);
        } catch (error) {
          console.error("Error parsing skills JSON:", error);
        }

        try {
          languagesArray = JSON.parse(value.languages);
        } catch (error) {
          console.error("Error parsing languages JSON:", error);
        }

        const isSelected = selectedJDId === value.id;

        return (
          <div style={styles.jdCard} key={index}>
            <div style={styles.jdContent}>
              <div>{value.jdName}</div>
              <input
                type="radio"
                name="select-jd"
                style={styles.radio}
                checked={isSelected}
                onChange={() => handleRadioChange(value.id, value.companyEmail)}
              />
            </div>
            <div style={styles.jdContent}>
              <span style={styles.jdDiscription}>
                {value.summary}
              </span>
            </div>
            <div style={styles.jdSkillContent}>
              {skillsArray.length > 0 ? (
                skillsArray.map((skill, skillIndex) => (
                  <span key={skillIndex} style={styles.jdSkill}>{skill}</span>
                ))
              ) : (
                <span>No skills available</span>
              )}
            </div>
            <div style={styles.jdSkillContent}>
              {languagesArray.length > 0 ? (
                languagesArray.map((language, langIndex) => (
                  <span key={langIndex} style={styles.jdSkill}>{language}</span>
                ))
              ) : (
                <span>No languages available</span>
              )}
            </div>
            <div style={styles.iconContainer}>
              <button style={styles.iconButton}>🔗</button>
              <button style={styles.CloseIconButton}>✖️</button>
            </div>
            <button
              onClick={() => handleSelectJD(value.id, value.companyEmail)}
              className="p-2 rounded-lg"
              disabled={!isSelected} // Disable the button if no job description is selected
            >
              Select
            </button>
          </div>
        );
      })}
    </div> */}

                  </>
                )}

                {activeTab === "Resume" && (
                  <>
                    <div style={styles.jdUpload}>
                      {/* <button style={styles.uploadButton}>
                      583 Upload Resume ➕
                    </button> */}
                      <button
                        className="px-4 py-2 text-white bg-blue-500 hover:bg-blue-600 rounded"
                        onClick={openResumeModal}
                      >
                        Upload Resume ➕
                      </button>

                      {isResumeModalOpen && (
                        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50"
                          style={{}}
                        >
                          <div className="p-6 rounded shadow-lg text-center"
                            style={{ background: 'linear-gradient(168deg, rgb(39, 44, 88), rgb(65, 36, 94))' }}>
                            <h2 className="text-xl font-semibold mb-4">Upload Your Resume</h2>
                            <input
                              type="file"
                              multiple
                              // onChange={handleFileChange}
                              accept=".pdf, .doc, .docx"
                              onChange={handleResumeFileChange}
                              className="mb-4 block mx-auto"
                            />
                            <div>
                              <button
                                className="px-4 py-2 text-white bg-blue-500 hover:bg-blue-600 rounded mr-2"
                                onClick={handleResumeUpload}
                                disabled={isResumeUploading}
                              >
                                {isResumeUploading ? 'Uploading...' : 'Upload'}
                              </button>
                              <button
                                className="px-4 py-2 text-white bg-red-500 hover:bg-red-600 rounded"
                                onClick={closeResumeModal}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      )}


                    </div>
                    {/* <div style={styles.jdList}>
                    {[...Array(6)].map((_, index) => (
                      <div style={styles.jdCard} key={index}>
                        <div style={styles.jdContent}>
                          <div>Resume {index + 1}</div>
                          <input
                            type="radio"
                            name="select-resume"
                            style={styles.radio}
                          />
                        </div>
                        <div style={styles.iconContainer}>
                          <button style={styles.iconButton}>✖️</button>
                          <button style={styles.iconButton}>🔗</button>
                        </div>
                      </div>
                    ))}
                  </div> */}
                  </>
                )}

                {/* Outrich code start */}

                {activeTab === "Outrich select" ? (
                  <div className="p-4 overflow-y-auto max-h-[600px]">
                    {/* Grid container for the candidate cards */}
                    {/* <h1> 1034from Outrich</h1> */}

                    <button
                      onClick={() => setShowSelectionOptions(!showSelectionOptions)}
                      className="bg-blue-500 text-white px-4 py-2 rounded-md mb-4 items-center justify-center"
                    >
                      {showSelectionOptions ? 'Hide Selection Options' : 'Show Selection Options'}
                    </button>


                  </div>
                ) : (
                  <div>
                  </div>
                )}

                {/* outrich code end */}

                {/* <button style={styles.addButton} onClick={handleChatboxToggle}>
            🧜‍♂️Ask Ai
          </button> */}
              </div>
            )}
            <img
              onClick={handleBackCloseClick}
              src={backclose}
              alt="icon"
              style={styles.icon}
            />
            {/* Conditional Rendering for Candidate Section or Profile View */}
            {selectedCandidate ? (
              <div style={styles.profileView}>
                <button style={styles.closeButton} onClick={handleCloseProfile}>
                  ✖️
                </button>
                <div style={styles.profileImage}>👤</div>
                <div style={styles.profileName}>{selectedCandidate.name}</div>

                <div style={styles.profileAbout}>
                  About {selectedCandidate.name}
                </div>
              </div>
            ) : (

              <div style={styles.candidateSection}>
                {/* for  outrich purpose start */}
                {
                  activeTab === "Resume" ? (

                    <div style={styles.tabs}>
                      <button
                        style={
                          activeCandidateTab === "AI Picked"
                            ? styles.activeTab
                            : styles.tab
                        }
                        onClick={() => handleCandidateTabClick("AI Picked")}
                      >
                        AI Picked
                      </button>
                      <button
                        style={
                          activeCandidateTab === "Your Candidate"
                            ? styles.activeTab
                            : styles.tab
                        }
                        onClick={() => handleCandidateTabClick("Your Candidate")}
                      >
                        Your Candidate
                      </button>
                      {/* <button
                  style={
                    activeCandidateTab === "Database"
                      ? styles.activeTab
                      : styles.tab
                  }
                  onClick={() => handleCandidateTabClick("Database")}
                >
                  Database
                </button> */}
                    </div>

                  ) : (
                    <div></div>
                  )
                }


                {/*  for outrich purpose end */}



                {/* original code of Ai Picked and  Your Candidate start*/}

                {/* <div style={styles.tabs}>
                <button
                  style={
                    activeCandidateTab === "AI Picked"
                      ? styles.activeTab
                      : styles.tab
                  }
                  onClick={() => handleCandidateTabClick("AI Picked")}
                >
                  AI Picked
                </button>
                <button
                  style={
                    activeCandidateTab === "Your Candidate"
                      ? styles.activeTab
                      : styles.tab
                  }
                  onClick={() => handleCandidateTabClick("Your Candidate")}
                >
                  Your Candidate
                </button>
                <button
                  style={
                    activeCandidateTab === "Database"
                      ? styles.activeTab
                      : styles.tab
                  }
                  onClick={() => handleCandidateTabClick("Database")}
                >
                  Database
                </button>
              </div> */}
                {/* original code of Ai Picked and  Your Candidate end*/}


                {/* Conditional Rendering for Candidate Section or Table View */}
                {showTable ? (
                  <div style={styles.tableContainer}>
                    {/* table outravh code start */}
                    {/* <table style={styles.table}>
                    <thead>
                      <tr>
                        <th style={styles.tableHeader}>Name</th>
                        <th style={styles.tableHeader}>Email</th>
                        <th style={styles.tableHeader}>Profile Image</th>
                        <th style={styles.tableHeader}>Phone Number</th>
                        <th style={styles.tableHeader}>Position</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[...Array(6)].map((_, index) => (
                        <tr
                          key={index}
                          style={styles.tableRow}
                          onClick={() =>
                            handleCandidateClick({
                              profileImage: `https://via.placeholder.com/50`,
                              name: `Name ${index + 1}`,
                              email: `name${index + 1}@example.com`,
                              phone: `123-456-789${index}`,
                              position: `Position ${index + 1}`,
                            })
                          }
                        >
                          <td style={styles.tableCell}>
                            <img
                              src="https://via.placeholder.com/50"
                              alt="Profile"
                              style={styles.profileImageTable}
                            />
                          </td>
                          <td style={styles.tableCell}>Name {index + 1}</td>
                          <td style={styles.tableCell}>
                            name8888{index + 1}@example.com
                          </td>
                          <td style={styles.tableCell}>123-456-789{index}</td>
                          <td style={styles.tableCell}>Position {index + 1}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table> */}
                    {/* table ourach code end */}

                    {/* main original resume ai picked data code start */}

                    {/* <div className="p-4 overflow-y-auto max-h-[600px]">

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                      {serverEmails.map((arr, index) => (
                        <div
                          className="text-black p-4 border border-gray-300 rounded-lg cursor-pointer"
                          key={index}
                          onClick={(e) => {
                            if (e.target.tagName !== "INPUT") {
                              handleCandidateClick({ name: `Name ${index + 1}` });
                            }
                          }}
                        >
                          <div className="mb-2">
                            <img
                              src={arr.photoURL || profile} // Use default image if photoURL is null or undefined
                              alt={arr.name || profile}
                              className="w-24 h-24 rounded-full object-cover" // Adjust dimensions as needed
                            />
                          </div>
                          <div className="mb-2">
                            Name 768: {arr.name} {index + 1}
                          </div>
                          <div className="flex items-center space-x-2">
                            <button className="p-2 border border-gray-400 rounded">🔗</button>
                            <input
                              type="checkbox"
                              name={`select-resume-${index}`}
                              className="cursor-pointer"
                              onClick={(e) => e.stopPropagation()} // Prevents checkbox from triggering parent onClick
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div> */}

                    {/* main original resume ai picked data code end */}

                    {/* outrich code start */}

                    <div>
                      {activeCandidateTab === "Outrich select" ? (
                        <div className="p-4 overflow-y-auto max-h-[1200px]">
                          {/* Grid container for the candidate cards */}

                          {/* <h1>1278 from out rich candidate tab.....</h1> */}


                          {/* <button
                            onClick={() => setShowSelectionOptions(!showSelectionOptions)}
                            className="bg-blue-500 text-white px-4 py-2 rounded-md mb-4 items-center justify-center"
                          >
                            {showSelectionOptions ? 'Hide Selection Options' : 'Show Selection Options'}
                          </button> */}

                          {/* {showSelectionOptions && (
                            <>
                              <div className="flex justify-between mb-4">
                                <button
                                  onClick={() => setSelectedActiveTab('database')} // renamed variable
                                  className={`px-4 py-1 rounded ${selectedActiveTab === 'database' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`} // renamed variable
                                >
                                  Database Candidates
                                </button>
                                <button
                                  onClick={() => setSelectedActiveTab('ai')} // renamed variable
                                  className={`px-4 py-1 rounded ${selectedActiveTab === 'ai' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`} // renamed variable
                                >
                                  AI Resumes
                                </button>
                              </div>

                              <div className="flex justify-between mb-4">
                                <div>
                                  {selectedActiveTab === 'database' ? ( // renamed variable
                                    <div>
                                      <label className="mr-2">Select Top (Database):</label>
                                      <div className="flex items-center space-x-2">
                                        {['5', '10', '20', '30', '50'].map((value) => (
                                          <button
                                            key={value}
                                            onClick={() => handleDatabaseSelectionCountChange({ target: { value } })}
                                            className={`px-2 py-1 rounded ${databaseSelectionCount === value ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                                          >
                                            {value}
                                          </button>
                                        ))}
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <label className="mr-2">Select Top (AI Resumes):</label>
                                      <div className="flex items-center space-x-2">
                                        {['5', '10', '20', '30', '50'].map((value) => (
                                          <button
                                            key={value}
                                            onClick={() => handleAiSelectionCountChange({ target: { value } })}
                                            className={`px-3 py-1 rounded ${aiSelectionCount === value ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                                          >
                                            {value}
                                          </button>
                                        ))}
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <button onClick={toggleSelectAll} className="bg-blue-500 text-white px-4 py-2 rounded">
                                  {selectedActiveTab === 'database' && selectedDatabaseCandidates.length === serverEmails.length // renamed variable
                                    ? 'Deselect All'
                                    : selectedActiveTab === 'ai' && selectedAiResumes.length === aiResumesResponce.length // renamed variable
                                      ? 'Deselect All'
                                      : 'Select All'}
                                </button>
                              </div>

                              {renderContent()}

                              <div className="mt-4 flex justify-end">
                                <button onClick={saveSelectedCandidates} className="bg-green-500 text-white px-4 py-2 rounded">
                                  Save
                                </button>
                              </div>
                            </>
                          )} */}




                          <div className="p-4">
                            <div className="mb-4">
                              <button
                                className={`mr-2 px-4 py-2 rounded ${showOutreachProfiles ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'}`}
                                style={showOutreachProfiles ? styles.jdSkill : {}}
                                onClick={() => setShowOutreachProfiles(true)}
                              >
                                Show Selected Profiles
                              </button>
                              <button
                                className={`px-4 py-2 rounded ${!showOutreachProfiles ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'}`}
                                style={!showOutreachProfiles ? styles.jdSkill : {}}
                                onClick={() => setShowOutreachProfiles(false)}
                              >
                                Show Selected Resume Candidates
                              </button>
                            </div>

                            <div className="mt-4">
                              {showOutreachProfiles ? (
                                <ul className="space-y-4">
                                  {selectedProfiles.map((profile) => (
                                    <li key={profile.id} className="bg-white shadow-md rounded p-4">
                                      <h3 className="text-lg font-bold text-gray-800">{profile.fullName}</h3>
                                      <p className="text-gray-600">{profile.description.length > 110 ? `${profile.description.substring(0, 110)}...` : profile.description}</p>
                                      <div className="mt-2">
                                        {/* <strong>Skills:</strong> {profile.skills.slice(0, 3).join(', ')} */}
                                      </div>
                                      <div className="mt-2">
                                        {/* <strong>Fit Score:</strong> {profile.fitScore} */}
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <ul className="space-y-4">
                                  {selectedResumeCandidates.map((candidate) => (
                                    <li key={candidate.id} className="bg-white shadow-md rounded p-4">
                                      <h3 className="text-lg font-bold text-gray-800">{candidate.name}</h3>
                                      <p className="text-gray-600">{candidate.description.length > 110 ? `${candidate.description.substring(0, 110)}...` : candidate.description}</p>
                                      <div className="mt-2">
                                        {/* <strong>Skills:</strong> {candidate.skills.slice(0, 3).join(', ')} */}
                                      </div>
                                      <div className="mt-2"
                                     
                                      >
                                        <strong  style={styles.jdSkill} >Fit Score: {candidate.fitScore}</strong>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>

                            {
                              !showOutreachProfiles && (
                                <div className="mt-4">
                                  <div>
                                    {!emailSent ? (
                                      <button
                                        className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                                        onClick={handleSendMail}
                                      >
                                        Send Mail for Interview
                                      </button>
                                    ) : (
                                      <p className="text-green-600">Email sent successfully!</p>
                                    )}
                                  </div>
                                  <div className="mt-2">
                                    <button
                                      className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                      style={styles.jdSkill}
                                      onClick={() => { navigate("/SpotGPT") }}
                                    >
                                      Open GPT
                                    </button>
                                  </div>

                                </div>
                              )
                            }

                            {/* <div className="mt-4">
                              <div>
                                {!emailSent ? (
                                  <button
                                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                                    onClick={handleSendMail}
                                  >
                                    Send Mail for Interview
                                  </button>
                                ) : (
                                  <p className="text-green-600">Email sent successfully!</p>
                                )}
                              </div>
                              <div className="mt-2">
                                <button
                                  className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                  
                                >
                                  Open GPT
                                </button>
                              </div>
                            </div> */}


                          </div>




                        </div>
                      ) : (
                        <div>
                        </div>
                      )}
                    </div>

                    {/* outrich code end */}

                    <div>
                      {activeCandidateTab === "AI Picked" ? (
                        // <div className="p-4 overflow-y-auto max-h-[600px]">
                        //   {
                        //     serverEmails && serverEmails.length > 0 ? (
                        //       <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                        //         {serverEmails.map((arr, index) => (
                        //           <div
                        //             className="text-black p-4 border border-gray-300 rounded-lg cursor-pointer"
                        //             key={index}
                        //             onClick={(e) => {
                        //               if (e.target.tagName !== "INPUT") {
                        //                 handleCandidateClick({ name: `Name ${index + 1}` });
                        //               }
                        //             }}
                        //           >
                        //             <div className="mb-2">
                        //               <img
                        //                 src={arr.photoURL || profile} // Use default image if photoURL is null or undefined
                        //                 alt={arr.name || profile}
                        //                 className="w-24 h-24 rounded-full object-cover" // Adjust dimensions as needed
                        //               />
                        //             </div>
                        //             <div className="mb-2">
                        //               Name 1546: {arr.name} {index + 1}
                        //             </div>
                        //             <div className="flex items-center space-x-2">
                        //               <button className="p-2 border border-gray-400 rounded">🔗</button>
                        //               <input
                        //                 type="checkbox"
                        //                 name={`select-resume-${index}`}
                        //                 className="cursor-pointer"
                        //                 onClick={(e) => e.stopPropagation()} // Prevents checkbox from triggering parent onClick
                        //               />
                        //             </div>
                        //           </div>
                        //         ))}
                        //       </div>
                        //     ) : (
                        //       <h1>Please AI candidate shortlisting wat minute</h1>
                        //     )
                        //   }

                        // </div>

                        // original working code start

                        // <div>
                        //   {
                        //     serverEmails && serverEmails.length > 0 ? (
                        //       <div style={styles.candidates} className="candidates">
                        //         {serverEmails.map((arr, index) => (
                        //           <div
                        //             style={styles.candidateCard}
                        //             key={index}
                        //             onClick={(e) => {
                        //               // Prevent triggering the profile view if the radio button is clicked
                        //               if (e.target.tagName !== "INPUT") {
                        //                 handleCandidateClick({ name: `Name ${index + 1}` });
                        //               }
                        //             }}
                        //           >
                        //             <div
                        //               style={{
                        //                 display: 'flex',
                        //                 alignItems: 'center',
                        //                 justifyContent: 'center',
                        //               }} >
                        //               <img
                        //                 src={
                        //                   arr.photoURL?.includes('staging')
                        //                     ? profile // Show local image if photoURL contains 'staging'
                        //                     : arr.photoURL?.includes('devget')
                        //                       ? arr.photoURL // Show URL image if photoURL contains 'dev'
                        //                       : profile // Otherwise, show local image
                        //                 }

                        //                 alt={arr.name || profile}
                        //                 className="w-24 h-24 rounded-full object-cover items-center" // Adjust dimensions as needed
                        //                 style={styles.candidateImage}
                        //               />
                        //             </div>
                        //             <div style={styles.candidateName} className="text-black">
                        //              1593 {arr.name}
                        //             </div>
                        //             <span style={styles.jdDiscription} className="text-black">
                        //               {
                        //                 (arr.description.split(' ').length > 20
                        //                   ? arr.description.split(' ').slice(0, 15).join(' ') + '...'
                        //                   : arr.description
                        //                 )
                        //               }
                        //             </span>
                        //             <div style={styles.iconContainer}>

                        //               <div style={styles.jdSkillContent2}>
                        //                 {arr.keySkills.split(',').slice(0, 3).map((skill, index) => (
                        //                   <span
                        //                     key={index}
                        //                     style={styles.jdSkill}
                        //                     className="flex-none"

                        //                   >
                        //                     {skill.trim()}
                        //                     {index < 2 ? ', ' : ''} {/* Add a comma between skills except for the last one */}
                        //                   </span>
                        //                 ))}
                        //                 {arr.keySkills.split(',').length > 3 && '...'} {/* Show ellipsis if more than 3 skills */}
                        //               </div>
                        //             </div>
                        //           </div>
                        //         ))}
                        //       </div>
                        //     ) : (
                        //       <div>
                        //         <img src={aicharmingdata} alt="Cooking Database GIF" />
                        //       </div>
                        //     )
                        //   }
                        // </div>

                        // original working code end

                        // candidate prepare code start 

                        <div>
                          {/* Button section for selecting top profiles */}
                          {combinedProfilesWithEvaluations && combinedProfilesWithEvaluations.length > 0 &&
                            (

                              <div className="mb-4 space-x-2">
                                Select Top :
                                <button
                                  style={styles.jdSkill}
                                  // className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-200"
                                  onClick={() => selectTopProfiles(5)}
                                >
                                  5
                                </button>
                                <button
                                  style={styles.jdSkill}
                                  // className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-200"
                                  onClick={() => selectTopProfiles(10)}
                                >
                                  10
                                </button>
                                <button
                                  style={styles.jdSkill}
                                  // className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-200"
                                  onClick={() => selectTopProfiles(20)}
                                >
                                  20
                                </button>
                                <button
                                  style={styles.jdSkill}
                                  // className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-200"
                                  onClick={() => selectTopProfiles(30)}
                                >
                                  30
                                </button>
                                <button
                                  style={styles.jdSkill}
                                  // className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-200"
                                  onClick={deselectAllProfiles}
                                >
                                  Deselect All
                                </button>
                              </div>

                            )
                          }


                          <div>
                            {
                              combinedProfilesWithEvaluations && combinedProfilesWithEvaluations.length > 0 ? (
                                <div style={styles.candidates} className="candidates">
                                  {combinedProfilesWithEvaluations.map((arr, index) => (
                                    <div
                                      style={styles.candidateCard} // No highlighting applied
                                      key={index}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                        }}>
                                        <img
                                          src={
                                            arr.photoURL?.includes('staging')
                                              ? profile
                                              : arr.photoURL?.includes('devget')
                                                ? arr.photoURL
                                                : profile
                                          }
                                          alt={arr.name || profile}
                                          className="w-24 h-24 rounded-full object-cover items-center"
                                          style={styles.candidateImage}
                                        />
                                      </div>
                                      <div style={styles.candidateName} className="text-black">
                                        {arr.fullName}
                                      </div>
                                      <span style={styles.jdDiscription} className="text-black">
                                        {
                                          (arr.description.split(' ').length > 20
                                            ? arr.description.split(' ').slice(0, 15).join(' ') + '...'
                                            : arr.description
                                          )
                                        }
                                      </span>
                                      <div style={styles.iconContainer}>
                                        <div style={styles.jdSkillContent2}>
                                          {arr.keySkills.split(',').slice(0, 3).map((skill, index) => (
                                            <span
                                              key={index}
                                              style={styles.jdSkill}
                                              className="flex-none"
                                            >
                                              {skill.trim()}
                                              {index < 2 ? ', ' : ''} {/* Add a comma between skills except for the last one */}
                                            </span>
                                          ))}
                                          {arr.keySkills.split(',').length > 3 && '...'} {/* Show ellipsis if more than 3 skills */}
                                          <div style={{ marginTop: '10px' }}>
                                            <input
                                              type="checkbox" // Using checkbox instead of radio button for multi-select
                                              value={arr.fullName}
                                              checked={selectedProfiles.includes(arr)} // Checked if profile is selected
                                              onChange={() => handleProfileSelection(arr)} // Toggle selection on change
                                            />
                                            {/* <span style={{ marginLeft: '8px' }}>Select Profile</span> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div>
                                  <img src={aicharmingdata} alt="Cooking Database GIF" />
                                </div>
                              )
                            }

                            {/* Save button */}
                            {
                              combinedProfilesWithEvaluations && combinedProfilesWithEvaluations.length > 0 && (

                                <button
                                  className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200"
                                  // className="mt-4"
                                  style={styles.jdSkill}
                                  onClick={handleSaveSelectedProfiles}
                                >
                                  Save Database Candidates
                                </button>

                              )
                            }

                          </div>
                        </div>

                        // candidate preapare code end


                      ) : (
                        <div>
                        </div>
                      )}
                    </div>

                    <div>
                      {
                        activeCandidateTab === "Your Candidate" ? (
                          <div className="p-4 overflow-y-auto max-h-[600px]">

                            {
                              aiResumesResponce && aiResumesResponce.length > 0 ? (

                                // <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                                //   {aiResumesResponce.map((arr, index) => (
                                //     <div
                                //       className="text-black p-4 border border-gray-300 rounded-lg cursor-pointer"
                                //       key={index}
                                //       onClick={(e) => {
                                //         if (e.target.tagName !== "INPUT") {
                                //           handleCandidateClick({ name: `Name ${index + 1}` });
                                //         }
                                //       }}
                                //     >
                                //       {/* <div className="mb-2">
                                //         <img
                                //           src={arr.photoURL || profile} // Use default image if photoURL is null or undefined
                                //           alt={arr.name || profile}
                                //           className="w-24 h-24 rounded-full object-cover" // Adjust dimensions as needed
                                //         />
                                //       </div> */}
                                //       <div className="mb-2">
                                //         <div><strong>Name:</strong> {arr.name}</div>
                                //         <div><strong>Key Skills:</strong> {arr.keySkills}</div>
                                //         <div><strong>Fit Score:</strong> {arr.fitScore}</div>
                                //         <div><strong>Email:</strong> {arr.email}</div>
                                //         <div><strong>Comment:</strong> {arr.comment}</div>
                                //         <div><strong>Resume URL:</strong> <a href={arr.resumeURL} target="_blank" rel="noopener noreferrer">View Resume</a></div>
                                //       </div>
                                //       {/* <div className="flex items-center space-x-2">
                                //         <button className="p-2 border border-gray-400 rounded">🔗</button>
                                //         <input
                                //           type="checkbox"
                                //           name={`select-resume-${index}`}
                                //           className="cursor-pointer"
                                //           onClick={(e) => e.stopPropagation()} // Prevents checkbox from triggering parent onClick
                                //         />
                                //       </div> */}
                                //     </div>
                                //   ))}
                                // </div>

                                // <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                                //   {aiResumesResponce.map((arr, index) => (
                                //     <div
                                //       className="text-black p-4 border border-gray-300 rounded-lg cursor-pointer"
                                //       key={index}
                                //       onClick={(e) => {
                                //         if (e.target.tagName !== "INPUT") {
                                //           handleCandidateClick({ name: `Name ${index + 1}` });
                                //         }
                                //       }}
                                //     >
                                //       <div className="mb-2">
                                //         <img
                                //           src={arr.photoURL || profile}
                                //           alt={arr.name || profile}
                                //           className="w-24 h-24 rounded-full object-cover"
                                //         />
                                //       </div>
                                //       <div className="mb-2">
                                //         <div><strong>Name:</strong> {arr.name}</div>
                                //         <div><strong>Key Skills:</strong> {arr.keySkills}</div>
                                //         <div><strong>Fit Score:</strong> {arr.fitScore}</div>
                                //         <div><strong>Email:</strong> {arr.email}</div>
                                //         <div><strong>Comment:</strong> {arr.comment}</div>
                                //         <div><strong>Resume URL:</strong> <a href={arr.resumeURL} target="_blank" rel="noopener noreferrer">View Resume</a></div>
                                //       </div>
                                //       <div className="flex items-center space-x-2">
                                //         <button className="p-2 border border-gray-400 rounded">🔗</button>
                                //         <input
                                //           type="checkbox"
                                //           name={`select-resume-${index}`}
                                //           className="cursor-pointer"
                                //           onClick={(e) => e.stopPropagation()}
                                //         />
                                //       </div>
                                //     </div>
                                //   ))}
                                // </div>

                                //  working code preaper resume belwo

                                // <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                                //   {aiResumesResponce.map((arr, index) => (
                                //     <div
                                //       className="bg-white shadow-md p-4 border border-gray-200 rounded-lg cursor-pointer transition-transform transform hover:scale-105"
                                //       key={index}
                                //       onClick={(e) => {
                                //         if (e.target.tagName !== "INPUT") {
                                //           handleCandidateClick({ name: `Name ${index + 1}` });
                                //         }
                                //       }}
                                //     >
                                //       <div className="flex flex-col items-center">
                                //         {/* Image Section */}
                                //         <img
                                //           src={arr.photoURL || profile}
                                //           alt={arr.name || 'Profile'}
                                //           className="w-32 h-32 rounded-full object-cover border border-gray-300 mb-4"
                                //         />
                                //         {/* Data Section */}
                                //         <div className="text-center">
                                //           <div className="text-lg font-semibold mb-2 text-black" >
                                //             1760{arr.name}
                                //           </div>
                                //           <div className="text-sm text-gray-700 mb-2">
                                //             {/* <div style={styles.jdSkill}><strong>Key Skills:</strong> {arr.keySkills}</div> */}

                                //             <div className="mb-2">
                                //               <strong>Comment:</strong> {arr.comment.length > 20 ? arr.comment.substring(0, 40) + "..." : arr.comment}
                                //             </div>

                                //             <div className="flex flex-wrap gap-2">
                                //               {/* Remove comments or add if needed */}
                                //               {arr.keySkills
                                //                 .split(',')
                                //                 .map((skill, index) => skill.trim())
                                //                 .filter(skill => skill.length > 0)
                                //                 .slice(0, 3)
                                //                 .map((skill, index) => (
                                //                   <span
                                //                     key={index}
                                //                     // className="bg-red-200 text-red-800 px-2 py-1 rounded"
                                //                     style={styles.jdSkill}
                                //                   >
                                //                     {skill}
                                //                   </span>
                                //                 ))
                                //                 .reduce((prev, curr) => [prev, '', curr])}
                                //               {arr.keySkills
                                //                 .split(',')
                                //                 .map(skill => skill.trim())
                                //                 .filter(skill => skill.length > 0)
                                //                 .length > 3 ? '...' : ''}
                                //             </div>


                                //             <div style={{
                                //               display: "flex",
                                //               alignItems: "center",
                                //               width: "45%",
                                //               padding: "4px 0px",
                                //               background: "#ddc8ffd6",
                                //               borderRadius: "6px",
                                //               height: "2rem",
                                //               top: "2rem",
                                //               margin: "10px 0px",
                                //               justifyContent: "space-around",
                                //               position: "sticky",
                                //               color: "#fff"
                                //             }} className="mt-3"><strong>Fit Score:</strong> {arr.fitScore}</div>
                                //             {/* <div className="mb-2">
                                //             <strong>Comment:</strong> {arr.comment.length > 20 ? arr.comment.substring(0, 20) + "..." : arr.comment}
                                //           </div> */}

                                //             <div className="items-center text-center ">
                                //               {/* <strong>Resume URL:</strong> */}
                                //               <a
                                //                 href={arr.resumeURL}
                                //                 target="_blank"
                                //                 rel="noopener noreferrer"
                                //                 className="text-blue-500 underline flex items-center space-x-2"
                                //               >
                                //                 <DownloadOutlined className="text-blue-500" /> {/* Add the download icon */}
                                //                 <span>View Resume</span>
                                //               </a>
                                //             </div>

                                //           </div>
                                //         </div>
                                //       </div>
                                //     </div>
                                //   ))}
                                // </div>

                                // working code prapare resume  above

                                // prepare selcting ai candidate code start

                                <div>
                                  {/* Buttons to select top 5, 10, 20, or 30 candidates */}
                                  <div className="mb-4">
                                    Select Top :
                                    {[5, 10, 20, 30].map((count) => (
                                      <button
                                        key={count}
                                        style={styles.jdSkill}
                                        className="bg-blue-500 text-white py-2 px-4 rounded mx-2 hover:bg-blue-600 transition duration-200"
                                        onClick={() => handleSelectTop(count)}
                                      >
                                        {count}
                                      </button>
                                    ))}
                                  </div>

                                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                                    {aiResumesResponce.map((arr, index) => (
                                      <div
                                        className="bg-white shadow-md p-4 border border-gray-200 rounded-lg transition-transform transform hover:scale-105"
                                        key={index}
                                      >
                                        <div className="flex flex-col items-center">
                                          {/* Image Section */}
                                          <img
                                            src={arr.photoURL || profile} // Ensure profile is defined
                                            alt={arr.name || 'Profile'}
                                            className="w-32 h-32 rounded-full object-cover border border-gray-300 mb-4"
                                          />
                                          {/* Data Section */}
                                          <div className="text-center">
                                            <div className="text-lg font-semibold mb-2 text-black">
                                               {arr.name}
                                            </div>
                                            <div className="text-sm text-gray-700 mb-2">
                                              <div className="mb-2">
                                                <strong>Comment:</strong> {arr.comment.length > 20 ? arr.comment.substring(0, 40) + "..." : arr.comment}
                                              </div>

                                              <div className="flex flex-wrap gap-2">
                                                {arr.keySkills
                                                  .split(',')
                                                  .map((skill, index) => skill.trim())
                                                  .filter(skill => skill.length > 0)
                                                  .slice(0, 3)
                                                  .map((skill, index) => (
                                                    <span
                                                      key={index}
                                                      className="bg-gray-200 text-gray-800 px-2 py-1 rounded"
                                                    >
                                                      {skill}
                                                    </span>
                                                  ))}
                                                {arr.keySkills.split(',').length > 3 && '...'}
                                              </div>

                                              <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                width: "45%",
                                                padding: "4px 0px",
                                                background: "#ddc8ffd6",
                                                borderRadius: "6px",
                                                height: "2rem",
                                                margin: "10px 0px",
                                                justifyContent: "space-around",
                                                color: "#fff"
                                              }} className="mt-3">
                                                <strong>Fit Score:</strong> {arr.fitScore}
                                              </div>

                                              <div className="items-center text-center">
                                                <a
                                                  href={arr.resumeURL}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="text-blue-500 underline flex items-center space-x-2"
                                                >
                                                  <DownloadOutlined className="text-blue-500" /> {/* Add the download icon */}
                                                  <span>View Resume</span>
                                                </a>
                                              </div>
                                            </div>
                                            {/* Checkbox for selecting candidate */}
                                            <div className="mt-2">
                                              <input
                                                type="checkbox"
                                                checked={selectedResumeCandidates.includes(arr)} // Checked if candidate is selected
                                                onChange={() => handleResumeCandidateSelection(arr)} // Toggle selection on change
                                              />
                                              <span className="ml-2">Select Resume Candidate</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>

                                  {/* Save button */}
                                  <button
                                    className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200"
                                    style={styles.jdSkill}
                                    onClick={handleSaveSelectedResumeCandidates}
                                  >
                                    Save Selected Resume Candidates
                                  </button>
                                </div>

                                // prepare selecting ai candidate code end

                              ) : (
                                <div className="">
                                  <img src={aicharmingdata} alt="Cooking Database GIF" />
                                </div>
                              )
                            }

                          </div>
                        ) : (
                          // <h1>Resume Shortlisting wait</h1>
                          <div></div>
                        )
                      }
                    </div>

                  </div>
                ) : currentScreen === "candidates" ? (
                  // <div style={styles.candidates} className="candidates">
                  //   {array.map((arr, index) => (
                  //     <div
                  //       style={styles.candidateCard}
                  //       key={index}
                  //       onClick={(e) => {
                  //         // Prevent triggering the profile view if the radio button is clicked
                  //         if (e.target.tagName !== "INPUT") {
                  //           handleCandidateClick({ name: `Name ${index + 1}` });
                  //         }
                  //       }}
                  //     >
                  //       <div style={styles.candidateImage}>👤</div>
                  //       <div style={styles.candidateName}>
                  //         {arr.name} {index + 1}
                  //       </div>
                  //       <span style={styles.jdDiscription}>
                  //         Lorem Ipsum is simply dummy text of the printing and
                  //         typesetting industry.
                  //       </span>

                  //       <div style={styles.iconContainer}>
                  //         <div style={styles.jdSkillContent2}>
                  //           <span style={styles.jdSkill}>HTML</span>
                  //           <span style={styles.jdSkill}>CSS</span>
                  //           <span style={styles.jdSkill}>JS</span>
                  //         </div>
                  //         <div>
                  //           <button style={styles.iconButton}>🔗</button>
                  //           <input
                  //             type="checkbox"
                  //             name={`select-resume-${index}`}
                  //             style={styles.checkbox}
                  //             onClick={(e) => e.stopPropagation()} // Prevents checkbox from triggering parent onClick
                  //           />
                  //         </div>
                  //       </div>
                  //     </div>
                  //   ))}
                  // </div>

                  // <div style={styles.candidates} className="candidates">
                  //   {serverEmails.map((arr, index) => (
                  //     <div
                  //       className="text-black"
                  //       style={styles.candidateCard}
                  //       key={index}
                  //       onClick={(e) => {
                  //         // Prevent triggering the profile view if the radio button is clicked
                  //         if (e.target.tagName !== "INPUT") {
                  //           handleCandidateClick({ name: `Name ${index + 1}` });
                  //         }
                  //       }}
                  //     >
                  //       {/* <div style={styles.candidateImage}>{arr.photoURL}</div> */}
                  //       <div style={styles.candidateImage}>
                  //         <img
                  //           src={arr.photoURL || profile } // Use default image if photoURL is null or undefined
                  //           alt={arr.name || profile}
                  //           style={{ width: '100px', height: '100px' }} // Adjust dimensions as needed
                  //           className="rounded-full"
                  //         />
                  //       </div>
                  //       <div style={styles.candidateName}>
                  //         Name 1068 :{arr.name} {index + 1}
                  //       </div>
                  //       <div style={styles.iconContainer}>
                  //         <button style={styles.iconButton}>🔗</button>
                  //         <input
                  //           type="checkbox"
                  //           name={`select-resume-${index}`}
                  //           style={styles.checkbox}
                  //           onClick={(e) => e.stopPropagation()} // Prevents checkbox from triggering parent onClick
                  //         />
                  //       </div>
                  //     </div>
                  //   ))}
                  // </div>

                  // specify code start

                  activeTab === "JD" && (
                    <div className="p-4 overflow-y-auto max-h-[600px]">

                      {/* original job card slected show job detail code start */}

                      {/* {isJobCardModalOpen && selectedJobCard ? (
                      <div >
                        <div className=" rounded-lg shadow-lg p-6 max-w-3xl mx-auto mt-2 "
                          style={{ background: 'linear-gradient(135deg, rgb(102, 126, 234), rgb(136, 89, 184))', }}
                        >
                          <h2 className="text-3xl font-bold mb-6">{selectedJobCard.jdName}</h2>
                          <p className="text-gray-800 mb-6">{selectedJobCard.summary}</p>

                          <div className="mb-6">
                            <h3 className="text-xl font-semibold text-gray-900 mb-2">Skills:</h3>
                            <p className="text-black">
                              {(() => {
                                const skillsArray = typeof selectedJobCard.skills === 'string'
                                  ? selectedJobCard.skills
                                    .replace(/[\[\]"]+/g, '') // Remove square brackets and quotes
                                    .split(',')              // Split by comma
                                    .map(skill => skill.trim()) // Trim whitespace from each skill
                                  : selectedJobCard.skills || []; // Use existing array if it's already an array

                                return skillsArray.length > 0
                                  ? skillsArray.join(', ') // Join skills with comma and space
                                  : 'No skills available'; // Default message if no skills
                              })()}
                            </p>
                          </div>

                          <div className="mb-6">
                            <div className=" items-center mb-2">
                              <h3 className="text-xl font-semibold text-gray-900">Work Category:</h3>
                              <p className="text-gray-700 ml-4 ">{selectedJobCard.workCategory}</p>
                            </div>
                          </div>

                          <div className="mb-6">
                            <div className=" items-center mb-2">
                              <h3 className="text-xl font-semibold text-gray-900">Work Mode:</h3>
                              <p className="text-gray-700 ml-4 ">{selectedJobCard.workMode}</p>
                            </div>
                          </div>

                          <button
                            onClick={handleJobCardModalClose}
                            className="bg-blue-500 text-white py-2 px-6 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 transition"
                          >
                            Close
                          </button>
                        </div>

                      </div>
                    ) : (
                      <div>
                        <img src={SpotAnimation} alt="Loading animation" />
                      </div>
                    )
                    } */}

                      {/* original job card slected show job detail code end */}

                      {/* testing code start if job selected job details show if jo create selected form will come up */}

                      {
                        (isJobCardModalOpen && selectedJobCard ? (
                          <div >
                            <div className=" rounded-lg shadow-lg p-6 max-w-3xl mx-auto mt-2 "
                              style={{ background: 'linear-gradient(135deg, rgb(102, 126, 234), rgb(136, 89, 184))', }}
                            >
                              <h2 className="text-3xl font-bold mb-6">{selectedJobCard.jdName}</h2>
                              <p className="text-gray-800 mb-6">{selectedJobCard.summary}</p>

                              <div className="mb-6">
                                <h3 className="text-xl font-semibold text-gray-900 mb-2">Skills:</h3>
                                <p className="text-black">
                                  {(() => {
                                    const skillsArray = typeof selectedJobCard.skills === 'string'
                                      ? selectedJobCard.skills
                                        .replace(/[\[\]"]+/g, '') // Remove square brackets and quotes
                                        .split(',')              // Split by comma
                                        .map(skill => skill.trim()) // Trim whitespace from each skill
                                      : selectedJobCard.skills || []; // Use existing array if it's already an array

                                    return skillsArray.length > 0
                                      ? skillsArray.join(', ') // Join skills with comma and space
                                      : 'No skills available'; // Default message if no skills
                                  })()}
                                </p>
                              </div>

                              <div className="mb-6">
                                <div className=" items-center mb-2">
                                  <h3 className="text-xl font-semibold text-gray-900">Work Category:</h3>
                                  <p className="text-gray-700 ml-4 ">{selectedJobCard.workCategory}</p>
                                </div>
                              </div>

                              <div className="mb-6">
                                <div className=" items-center mb-2">
                                  <h3 className="text-xl font-semibold text-gray-900">Work Mode:</h3>
                                  <p className="text-gray-700 ml-4 ">{selectedJobCard.workMode}</p>
                                </div>
                              </div>

                              <button
                                onClick={handleJobCardModalClose}
                                className="bg-blue-500 text-white py-2 px-6 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 transition"
                              >
                                Close
                              </button>
                            </div>

                          </div>
                        ) : (jdIsModalOpen ? (
                          <div>
                            <div className="p-4 overflow-y-auto max-h-[600px] bg-gray-100 rounded-lg shadow-md">
                              <h2 className="text-2xl font-semibold mb-6 text-center text-black">Basic Details Form</h2>

                              <div className="mb-6">
                                <label htmlFor="job-title" className="block text-gray-700 mb-2">
                                  * Job Title
                                </label>
                                <input
                                  type="text"
                                  id="job-title"
                                  placeholder="Enter job title"
                                  value={jobTitle}
                                  onChange={(e) => setJobTitle(e.target.value)}
                                  className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
                                />
                              </div>

                              <div className="mb-6">
                                <label htmlFor="job-category" className="block text-gray-700 mb-2">
                                  * Job Category
                                </label>
                                <div className="flex flex-wrap gap-2">
                                  {renderCategoryButtons()}
                                </div>
                              </div>

                              <div className="mb-6">
                                <label className="block text-gray-700 mb-2">* Work Mode</label>
                                <div className="flex flex-wrap gap-2">
                                  {renderWorkModeOptions()}
                                </div>
                              </div>

                              <div className="mb-6">
                                <label className="block text-gray-700 mb-2">Experience Level</label>
                                <div className="flex justify-between p-2 border border-gray-300 rounded">
                                  {renderExperienceButtons()}
                                </div>
                              </div>

                              <div className="mb-6">
                                <label htmlFor="skill" className="block text-gray-700 mb-2">
                                  * Skill
                                </label>
                                <input
                                  type="text"
                                  id="skill"
                                  placeholder="Enter skills separated by comma"
                                  value={skill}
                                  onChange={(e) => setSkill(e.target.value)}
                                  className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
                                />
                              </div>

                              <div className="mb-6">
                                <label htmlFor="job-description" className="block text-gray-700 mb-2">
                                  * Job Description
                                </label>
                                <textarea
                                  id="job-description"
                                  placeholder="Enter job description"
                                  rows="4"
                                  value={jobDescription}
                                  onChange={(e) => setJobDescription(e.target.value)}
                                  className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
                                ></textarea>
                              </div>

                              <div ref={compensationRef}>
                                <div className="mb-6">
                                  <label htmlFor="compensation" className="block text-gray-700 mb-2">
                                    Compensation
                                  </label>
                                  <input
                                    type="text"
                                    id="compensation"
                                    placeholder="eg : 6LPA-12LPA"
                                    value={compensation}
                                    onChange={(e) => setCompensation(e.target.value)}
                                    className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
                                  />
                                </div>

                                <div className="mb-6">
                                  <label htmlFor="benefits" className="block text-gray-700 mb-2">
                                    Benefits
                                  </label>
                                  <textarea
                                    id="benefits"
                                    placeholder="Enter benefits details"
                                    rows="4"
                                    value={benefits}
                                    onChange={(e) => setBenefits(e.target.value)}
                                    className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
                                  ></textarea>
                                </div>

                                <div className="flex justify-end">
                                  <button
                                    type="button"
                                    // onClick={() => navigate("/Spotsearch")} // Navigate to 
                                    style={styles.navButton}
                                    onClick={() => { setJdIsModalOpen(false) }}
                                    className="px-4 py-2 mr-2 text-white bg-red-600 rounded hover:bg-red-500"
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="submit"
                                    onClick={handleSubmit}
                                    style={styles.navButton}
                                    disabled={createjdIsApiWorking}
                                    className="px-4 py-2 text-white bg-green-600 rounded hover:bg-green-500"
                                  >
                                    {/* Submit */}
                                    {createjdIsApiWorking ? 'Loading...' : 'Create JD'}
                                  </button>
                                </div>
                              </div>
                            </div>

                          </div>
                        ) : (
                          <div>
                            <img src={SpotAnimation} alt="Loading animation" />
                          </div>
                        )))
                      }

                      {/* testing code end testing code start if job selected job details show if jo create selected form will come up */}

                    </div>

                  )

                  // specify code end

                  // <h1>1118SPOT</h1>

                ) : targetTable ? (
                  <div style={styles.candidates} className="candidates">
                    {array.map((arr, index) => (
                      <div
                        style={styles.candidateCard2}
                        key={index}
                        onClick={(e) => {
                          // Prevent triggering the profile view if the radio button is clicked
                          if (e.target.tagName !== "INPUT") {
                            handleCandidateClick({ name: `Name ${index + 1}` });
                          }
                        }}
                      >
                        <div style={styles.candidateImage}>👤</div>
                        <div style={styles.candidateName}>
                          {arr.name} {index + 1}
                        </div>
                        <div style={styles.candidateName}>
                          <h1 style={styles.candidateJob}> Job Title</h1>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}

                {currentScreen === "profile" && selectedCandidate && (
                  <div>
                    <h2>Profile of {selectedCandidate.name}</h2>
                    <p>Details about {selectedCandidate.name}...</p>
                  </div>
                )}
                <div style={styles.navigation}>
                  <button style={styles.navButton}>More</button>
                  <div style={styles.navigationsub}>
                    <button
                      style={getNavButtonStyle(currentScreen)}
                      onClick={handleBackClick}
                    >
                      Back
                    </button>
                    {/* <button style={styles.navButton}  onClick={handleNextClick}>
                    Next
                  </button> */}
                    {/* <button style={styles.navButton} onClick={handleNextClick}>
                    Next
                  </button> */}
                    {selectedJDId !== null && (
                      <button style={styles.navButton} onClick={handleNextClick}>
                        Next
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* activate tab Outrich select testing start*/}


          {/* Chatbox */}
          {isChatboxOpen && (
            <div style={styles.chatbox}>
              <div style={styles.chatboxHeader}>
                <span>Chat with AI</span>
                <button style={styles.chatboxClose} onClick={handleChatboxToggle}>
                  ✖️
                </button>
              </div>
              <div style={styles.chatboxContent}>
                <p>Hi! How can I assist you today?</p>
              </div>
              <div style={styles.chatboxInputContainer}>
                <input
                  type="text"
                  placeholder="Enter your prompt..."
                  style={styles.chatboxInput}
                />
                <button style={styles.searchButton}>Search</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
// Internal CSS styles as JavaScript objects

const styles = {
  body: {
    background: "linear-gradient(135deg, #667eea, #8859b8)",
    // height: "97vh",
    // display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px",
  },
  container: {
    overflow: "hidden",
  },
  mainContent: {
    // transition: "transform 0.5s ease-in-out, opacity 0.5s ease-in-out",
    // transform: "isSectionVisible" ? "translateX(0)" : "translateX(-100%)",
    // opacity: "isSectionVisible" ? 1 : 0,
  },
  blogcontainer: {
    display: "flex",
  },
  blog: {
    fontSize: "8px",
    background: "linear-gradient(135deg, #667eea, #8859b8)",
    color: "#fff",
    padding: "4px",
    margin: "4px",
    borderRadius: "4px",
  },
  container: {
    maxWidth: "100%",
    margin: "43px 20px",
    // padding: "20px",
    backgroundColor: "#ffffff",
    // boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    fontFamily: "Arial, sans-serif",
    position: "relative",
    // background: "linear-gradient(135deg, #667eea, #8859b8)",
    background: "transparent",
    borderRadius: "8px",
    // height: "73vh",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    background: "linear-gradient(168deg, #272c58, #41245e)",
    padding: " 22px",
    borderRadius: "4px",
  },
  username2: {
    fontSize: "20px",
    width: "15%",
    height: "10%",
  },
  username: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#ffffff",
    display: "math",
    width: "10%",
    height: "10%",
    border: "1px solid #fff",
    padding: "11px 7px",
    borderRadius: "35px",
  },
  steps: {
    fontSize: "18px",
    color: "#ffffff",
    alignSelf: "center",
  },
  SpanSteps: {
    background: "linear-gradient(135deg, #667eea, #8859b8)",
    fontWeight: "bold",
    color: "#ffffff",
    padding: "5px 10px",
    borderRadius: "4px",
    margin: "4px",
    // fontSize: "12px",
  },
  mainContent: {
    display: "flex",
    // gap: "20px",
  },
  jdSection: {
    // width: "25%",
    background: "#ddc8ffd6",
    padding: "13px",
    borderRadius: "12px",
  },
  jdUpload: {
    marginBottom: "10px",
    display: "flex",
  },
  uploadButton: {
    width: "70%",
    padding: "15px 10px",
    // backgroundColor: "#007bff",
    background: "linear-gradient(135deg, #667eea, #8859b8)",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "20px",
    margin: "10px",
  },
  jdCreation: {
    width: "20%",
    padding: "15px 10px",
    // backgroundColor: "#007bff",
    background: "linear-gradient(135deg, #667eea, #8859b8)",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "20px",
    margin: "10px",
  },
  Target: {
    width: "100%",
    padding: "8px 4px",
    // backgroundColor: "#007bff",
    background: "linear-gradient(135deg, #667eea, #8859b8)",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "20px",
    margin: "1rem",
    marginLeft: "2rem",
  },
  jdList: {
    // display: "flex",
    flexWrap: "wrap",

    // height: "63vh",
    height: "60vh",
    overflowY: "auto", // Enable vertical scrolling
    scrollbarWidth: "none",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },
  jdCard: {
    width: "fit-content", // Adjusted for 2 JD boxes in a row
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    backgroundColor: "#f9f9f9",
    textAlign: "center",
    gap: "0rem",
    // display: "grid",
    margin: "4px",

    alignItems: "flex-end",
  },
  jdContent: {
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  candidteContent: {
    marginBottom: "10px",
    display: "flex",
    // alignItems: "start",
    justifyContent: "space-between",
  },
  jdDiscription: {
    textAlign: "start",
    fontSize: "12px",
  },
  candidteDiscription: {
    textAlign: "start !important",
    fontSize: "12px",
  },
  jdSkillContent: {
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    justifyContent: "flex-start",
    fontSize: "12px",
  },
  jdSkillContent2: {
    margin: "10px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    justifyContent: "flex-start",
    fontSize: "12px",
  },
  jdSkill: {
    background: "linear-gradient(135deg, #667eea, #8859b8)",
    color: "white",
    border: "none",
    borderRadius: "4px",
    padding: "5px 10px",
  },
  radio: {
    margin: "10px",
  },
  icon: {
    width: " 1%",
    left: "-12px",
    position: "relative",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    background: "#ddc8ffd6",

    borderRadius: "6px",
    height: "2rem",
    top: " 2rem",

    position: "sticky",
  },

  iconButton: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    fontSize: "18px",
  },
  CloseIconButton: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    fontSize: "18px",

    border: "1px solid rgba(221, 200, 255, 0.84)",
    borderRadius: "20px",
    padding: "3px",
    background: "rgba(221, 200, 255, 0.84)",
  },
  addButton: {
    width: "100%",
    padding: "10px",
    border: "none",
    borderRadius: "4px",
    // backgroundColor: "#007bff",
    background: "linear-gradient(135deg, #667eea, #8859b8)",
    color: "white",
    cursor: "pointer",
    marginTop: "1rem",
  },
  candidateSection: {
    // width: "74%",
    background: "#ccbbfd",
    padding: "19px",
    borderRadius: "10px",
    width: "-webkit-fill-available",
  },
  tabs: {
    display: "flex",
    gap: "10px",
    marginBottom: "10px",
  },
  tab: {
    flex: 1,
    padding: "10px",
    backgroundColor: "#e1e1e1",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  activeTab: {
    flex: 1,
    padding: "10px",
    // backgroundColor: "#007bff",
    background: "linear-gradient(135deg, #667eea, #8859b8)",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  candidates: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "20px",
  },
  candidateCard: {
    padding: "15px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    backgroundColor: "#f9f9f9",
    textAlign: "center",
    cursor: "pointer",
    transition: "transform 0.2s",
  },
  candidateCard2: {
    padding: "15px",
    // border: "1px solid #ddd",
    borderRadius: "4px",
    background:
      "linear-gradient(135deg, rgb(102, 126, 234), rgb(136, 89, 184))",

    color: "#fff",
    textAlign: "center",
    cursor: "pointer",
    transition: "transform 0.2s",
  },
  candidateCardHover: {
    transform: "scale(1.05)",
  },
  candidateImage: {
    fontSize: "40px",
    marginBottom: "10px",
  },
  candidateName: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  navigation: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  navigationsub: {
    display: "flex",
    justifyContent: "space-between",
  },
  navButton: {
    padding: "10px 20px",
    // backgroundColor: "#007bff",
    background: "linear-gradient(135deg, #667eea, #8859b8)",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  // navButton2: {
  //   padding: "10px 20px",
  //   // backgroundColor: "#007bff",
  //   background:
  //     "linear-gradient(135deg, rgb(102 126 234 / 52%), rgb(136 89 184 / 52%))",
  //   color: "white",
  //   border: "none",
  //   borderRadius: "4px",
  //   cursor: "pointer",
  //   marginRight: "4px",
  //   backgroundColor: currentScreen === "candidates" ? "gray" : "#007bff", // Change background color based on screen
  // },
  profileView: {
    width: "68%",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    backgroundColor: "#f9f9f9",
    textAlign: "center",
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "transparent",
    border: "none",
    fontSize: "20px",
    cursor: "pointer",
  },
  profileImage: {
    fontSize: "50px",
    marginBottom: "10px",
  },
  profileName: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  profileAbout: {
    fontSize: "16px",
    color: "#666",
  },
  chatbox: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    width: "300px",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    overflow: "hidden",
  },
  chatboxHeader: {
    // backgroundColor: "#007bff",
    background: "linear-gradient(135deg, #667eea, #8859b8)",
    color: "white",
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  chatboxClose: {
    backgroundColor: "transparent",
    border: "none",
    color: "white",
    fontSize: "18px",
    cursor: "pointer",
  },
  chatboxContent: {
    padding: "10px",
  },
  chatboxInputContainer: {
    display: "flex",
    padding: "10px",
    borderTop: "1px solid #ddd",
  },
  chatboxInput: {
    flex: 1,
    padding: "8px",
    border: "1px solid #ddd",
    borderRadius: "4px",
  },
  searchButton: {
    padding: "8px 10px",
    // backgroundColor: "#007bff",
    background: "linear-gradient(135deg, #667eea, #8859b8)",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginLeft: "10px",
  },
  jdToggle: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  jdToggleButton: {
    flex: 1,
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    backgroundColor: "#f9f9f9",
    cursor: "pointer",
    transition: "background-color 0.2s",
    marginRight: "10px",
    textAlign: "center",
  },
  activeToggleButton: {
    flex: 1,
    padding: "10px",
    // backgroundColor: "#007bff",
    background: "linear-gradient(135deg, #667eea, #8859b8)",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    textAlign: "center",
    marginRight: "10px",
  },
  tableContainer: {
    width: "100%",
    overflowY: "auto", // Enable vertical scrolling
    scrollbarWidth: "none",
    height: "55vh",
    borderRadius: "8px",
    border: "1px solid rgba(221, 200, 255, 0.84)",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    backgroundColor: "#ffffff",
  },
  tableHeader: {
    background: "linear-gradient(168deg, #272c58, #41245e)",
    color: "#ffffff",
    padding: "10px",
    position: "sticky",
  },
  tableCell: {
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "10px",
  },
  profileImageTable: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
  },
  spot: {
    // background: "#34295d",
    width: "5%",
    /* display: contents; */
    // padding: "2px 8px",
    borderRadius: "3px",
    fontSize: "20px",
    // color: "#34295d",
    color: "#bc97ff",
  },
  spot2: {
    // background: "linear-gradient(135deg, #667eea, #8859b8)",
    width: "5%",
    /* display: contents; */
    // padding: "2px 8px",
    borderRadius: "3px",
    // margin: "2px",
    color: "#bc97ff",
    fontSize: "20px",
  },
  /* Styles for circular checkboxes */
  checkbox: {
    // appearance: "none",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    border: "2px solid #007BFF",
    outline: "none",
    cursor: "pointer",
    position: "relative",
    marginRight: "10px",
  },
  checkboxChecked: {
    backgroundColor: "#007BFF",
    border: "2px solid #007BFF",
  },
  checkboxCheckedAfter: {
    content: '""',
    width: "10px",
    height: "10px",
    backgroundColor: "white",
    borderRadius: "50%",
    position: "absolute",
    top: "3px",
    left: "3px",
  },
  candidateJob: {
    fontSize: "16px",
  },
};

export default SpotSearch;

