import React, { useState, useRef, useEffect, useContext } from "react";
import { API, Storage, graphqlOperation } from "aws-amplify";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import gif from "../../assets/SpotAnimation.gif"; // Import the SVG image
import Search from "../../assets/search.svg"; // Import the SVG image
import Job from "../../assets/job.svg"; // Import the SVG image
import Linear from "../../assets/linear.svg"; // Import the SVG image
import AiLogo from "../../assets/AiLogo.svg"; // Import the SVG image
import AiLogo2 from "../../assets/AiLogo2.svg"; // Import the SVG image
import Searchbar from "../../assets/searchbar.svg"; // Import the SVG image
import Searchbar2 from "../../assets/searchbar2.svg"; // Import the SVG image
import spotlogo from "../../assets/spotlogo.svg"; // Import the SVG image
import CreateJd from "../../assets/system-uicons_create.svg"; // Import the SVG image
import UploadJd from "../../assets/hugeicons_file-upload.svg"; // Import the SVG image

import JdPanal from "../../assets/Jd panal.svg"; // Import the SpotPlusCard component
import SpotPlusJd from "./SpotPlusJd"; // Import the SpotPlusJd component
import SpotPlusYourJd from "./SpotPlusYourJd"; // Import the SpotPlusJd component
import SpotPlusProfile from "../Company/SpotPlusProfile";
import SpotPlusCard from "../Company/SpotPlusCard";
import SpotPlusSearch from "../Company/SpotPlusSearch";
import SpotPlusAskAi from "../Company/SpotPlusAskAi";
import specify from "../../assets/spacify.png";
import prepare from "../../assets/prepare.png";
import outreach from "../../assets/outreach.png";
import target from "../../assets/target.png";
import { createSpotResume } from "../../graphql/mutations";
import { listCandidateProfiles, listInterviewEvaluations, listSpotJDS } from "../../graphql/queries";
import { SpotContext } from "../../context/SpotContext";
import SpotPlusCardPrepare from "./SpotPlusCardPrepare";
import SpotPlusCardOutreach from "./SpotPlusCardOutreach";
import SpotPlusCardOutreachResume from "./SpotPlusCardOutreachResume";
import Header from "../../components/header/Header";
import SpotPlusSendMailPopup from "./SpotPlusSendMailPopup";
import loadinganimation2 from '../../assets/loadinganimation.gif'; // Adjust the path based on your folder structure
import SpotPlusTargetResume from "./SpotPlusTargetResume";

function SpotPlusCandidte() {
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isSearchBarActive, setIsSearchBarActive] = useState(false);
  const [isAskAiActive, setIsAskAiActive] = useState(false);
  const [selectedWorkTypes, setSelectedWorkTypes] = useState([]);
  const [cardsToShowAI, setCardsToShowAI] = useState(6);
  const [cardsToShowResume, setCardsToShowResume] = useState(6);
  const [isJdPanelOpen, setIsJdPanelOpen] = useState(false);
  const boxRef = useRef(null);
  // State to manage the currently displayed card
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [showSelectedCard, setShowSelectedCard] = useState(false);

  const [showProfile, setShowProfile] = useState(false);
  const [companyEmail, setCompanyEmail] = useState("");

  // Store code start

  const {
    setSpotprocessCompanyJDList,
    spotprocessSelectedJD,
    setSpotprocessAiResumeSlCandidates,
    setSpotprocessSelectedResume,
    // below using for Jd upload atomatic data will render code start
    setSpotprocessSelectedJDID,
    setSpotprocessSelectedJD,
    setSpotprocessAiJDSlcandidates,
    // above using for Jd upload atomatic data will render code end

    // prepare AI and Resume loading animation code
    loadingPrepareAIData,
    setLoadingPrepareAIData,
    setLoadingPrepareAIResumeData,
    loadingPrepareAIResumeData,
    spotprocessAiResumeSlCandidates,
    spotprocessAiJDSselectedCandidates,
    spotprocessAiResumeselectedCandidates,

    // spot process ending all data will clear code start
    setSpotprocessAiJDSselectedCandidates,
    setSpotprocessAiResumeselectedCandidates

  } = useContext(SpotContext);

  // Store code end

  // SPOT Animation code start
  const [spacifyActivated, setSpacifyActivated] = useState(false); // Show Spacify first
  const [prepareActivated, setPrepareActivated] = useState(true);
  const [outreachActivated, setOutreachActivated] = useState(false);
  const [targetActivated, setTargetActivated] = useState(false);

  // SPOT Animation code end

  // SPOT resume upload code start

  const [isResumeUploadModalVisible, setResumeUploadModalVisibility] =
    useState(false);
  const [selectedResumeFiles, setSelectedResumeFiles] = useState([]);
  const [resumeUploadErrors, setResumeUploadErrors] = useState([]);
  const [isResumesUploading, setIsResumesUploading] = useState(false);
  const [uploadedResumeData, setUploadedResumeData] = useState([]); // Array to hold resume data
  const [activeTab, setActiveTab] = useState("Video"); // New state for active tab
  const [activeTabOutreach, setActiveTabOutreach] = useState("Database"); // New state for active tab

  //  SPOT resume upload code end


  //  Selected from upload spotplusaskai Jd shorlist code start

  const isMounted = useRef(false);
  const [combinedProfilesWithEvaluations, setCombinedProfilesWithEvaluations] = useState([]);
  const [isFetchingEvaluations, setIsFetchingEvaluations] = useState(false);
  const [evaluations, setEvaluations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);

  // Selected from upload spotplusaskai Jd shorlist code end


  // State to manage the visibility of the Send Mail popup
  const [isSendMailPopupVisible, setIsSendMailPopupVisible] = useState(false);
  // State to manage the visibility of the Send Mail popup

  // ... other functions

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleTabClickOutreach = (tab) => {
    setActiveTabOutreach(tab);
  }

  // Candidate Profile and evaution making combination code start

  useEffect(() => {
    if (profiles.length) {
      const combinedProfiles = profiles.map((profile, index) => {
        // Find matching evaluations by candidateEmail and profile.email
        const matchedEvaluations = evaluations.filter(
          evaluation => evaluation.candidateEmail === profile.email
        );

        // Prepare evaluation fields directly in the profile
        const evaluationData = {};
        matchedEvaluations.forEach((evaluation, idx) => {
          evaluationData[`evaluation${idx + 1}Email`] = evaluation.candidateEmail;
          evaluationData[`evaluation${idx + 1}Transcript`] = evaluation.transcript;
          evaluationData[`evaluation${idx + 1}VideoURL`] = evaluation.videoURL;
          evaluationData[`evaluation${idx + 1}evaluation`] = evaluation.evaluation;
        });

        // Return the profile with merged evaluation data (if any)
        return {
          customId: index + 1,  // New custom ID
          ...profile,           // Existing profile fields (including original id)
          ...evaluationData,    // Add each evaluation field directly to the profile
        };
      });

      setCombinedProfilesWithEvaluations(combinedProfiles);
      setSpotprocessAiJDSlcandidates(combinedProfiles)
    }
  }, [profiles, evaluations]);

  //  Candidate Profile and evaution making combination code end

  useEffect(() => {


    if (isMounted.current) {
      return; // Skip execution if the component is already mounted
    }
    isMounted.current = true; // Mark the component as mounted


    const companyEmailID = localStorage.getItem("CompanyEmailID");

    setCompanyEmail(companyEmailID);

    if (!companyEmailID) {
      console.error("CompanyEmailID not found in local storage");
      return;
    }


    // fetching incompleted jd code start

    const fetchSpotJDs = async () => {
      let spotJDItemsList = [];
      let currentNextToken = null;

      try {
        const companyEmailID = localStorage.getItem("CompanyEmailID");

        setCompanyEmail(companyEmailID);

        if (!companyEmailID) {
          console.error("CompanyEmailID not found in local storage");
          return;
        }

        do {
          const response = await API.graphql(
            graphqlOperation(listSpotJDS, {
              filter: { companyEmail: { eq: companyEmailID } }, // Filter by email
              nextToken: currentNextToken, // Pagination token
            })
          );

          // console.log("GraphQL response for Spot JDs++++++++++++++++++++++++++:", response.data.listSpotJDs.items);
          console.log(
            "GraphQL response for Spot JDs++++++++++++++++++++++++++:",
            response.data.listSpotJDS.items
          );

          // Ensure we're accessing the correct data structure
          const spotJDsData = response.data.listSpotJDS || {};
          const items = spotJDsData.items || [];

          // Set currentNextToken for pagination
          currentNextToken = spotJDsData.nextToken;

          // Append the fetched items to the list
          spotJDItemsList = [...spotJDItemsList, ...items];
        } while (currentNextToken);

        // Set state with the fetched Spot JDs
        // setSpotprocessCompanyJDList(spotJDItemsList)
        // console.log(
        //   "Spot JDs State Set:------------------------",
        //   spotJDItemsList
        // );

        // filter code start

        const filteredAndSortedItems = spotJDItemsList
          .filter(item =>
            !item.filterCandidate || item.filterCandidate.trim() === '' ||
            !item.selectedCandidate || item.selectedCandidate.trim() === ''
          ) // Only include items that do not have candidates
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Sort by createdAt in descending order

        console.log("Filtered and sorted items7777777777:", filteredAndSortedItems);
        setSpotprocessCompanyJDList(filteredAndSortedItems)

      } catch (error) {
        console.error(
          "Error fetching Spot JDs:**************************",
          error
        );
      }
    };

    fetchSpotJDs();

    //  fecthing incompleted jd code end



    // spotplusaskai jd upload get recent id code start


    const storedData = localStorage.getItem('RecentJDIdAfterJDupload');

    try {
      if (storedData) {
        const updatedJdDetails = JSON.parse(storedData);
        console.log(";;;;;;;;;;;;;;;;;;;;;;", updatedJdDetails);

        const getJDDataAndCandidateSl = async (jd) => {

          const { jdName, id, companyEmail } = jd

          console.log("jdName", jdName)
          console.log("id", jd.id)
          console.log("companyEmail", companyEmail)


          // jd Sl code start

          setSpotprocessSelectedJDID(jd.id)
          setSpotprocessSelectedJD(jd)

          const fetchShortlistCandidate = async (jobid) => {

            setLoadingPrepareAIData(true)

            try {

              const data = {
                id: jobid,
              };

              // Make the POST request
              const res = await fetch('https://jdwithai-e9e5hke8crfvcrg4.canadacentral-01.azurewebsites.net/shortlist-candidates', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
              });


              // Check if the request was successful
              if (!res.ok) {
                throw new Error('Network response was not ok');
              }

              // Parse the JSON response
              const result = await res.json();
              console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeee", result)
              // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeee", result.detailed_profiles)
              // setServerEmails(result.detailed_profiles)
              // setEmails(result.emails)


              const { emails } = result;

              //   fetching profile and evaution code start

              const fetchCandidateProfiles = async (nextToken = null) => {
                const limit = 100; // Adjust limit as needed

                // Create the filter to match any of the emails
                const filter = {
                  or: emails.map(email => ({ email: { eq: email } })),
                };

                try {
                  const response = await API.graphql(
                    graphqlOperation(listCandidateProfiles, {
                      filter,
                      limit,
                      nextToken,
                    })
                  );

                  const { items, nextToken: newNextToken } = response.data.listCandidateProfiles;

                  // Update profiles state with fetched items
                  setProfiles(prev => [...prev, ...items]);

                  if (newNextToken) {
                    // Fetch more items if there is a nextToken
                    await fetchCandidateProfiles(newNextToken);
                  }
                } catch (error) {
                  console.error('Error fetching candidate profiles:', error);
                }
              };

              const handleFetch = async () => {
                setIsLoading(true);
                setProfiles([]); // Reset profiles on new fetch
                await fetchCandidateProfiles();
                setIsLoading(false);
              };


              // const [isFetchingEvaluations, setIsFetchingEvaluations] = useState(false);
              // const [evaluations, setEvaluations] = useState([]);

              const fetchInterviewEvaluations = async (nextToken = null) => {
                const limit = 100; // Adjust limit as needed

                // Create the filter to match any of the candidate emails
                const filter = {
                  or: emails.map(email => ({ candidateEmail: { eq: email } })),
                };

                try {
                  const response = await API.graphql(
                    graphqlOperation(listInterviewEvaluations, {
                      filter,
                      limit,
                      nextToken,
                    })
                  );

                  const { items, nextToken: newNextToken } = response.data.listInterviewEvaluations;

                  // Update evaluations state with fetched items
                  setEvaluations(prev => [...prev, ...items]);

                  if (newNextToken) {
                    // Fetch more items if there is a nextToken
                    await fetchInterviewEvaluations(newNextToken);
                  }
                } catch (error) {
                  console.error('Error fetching interview evaluations:', error);
                }
              };

              const handleEvalutionFetch = async () => {
                setIsFetchingEvaluations(true);
                setEvaluations([]); // Reset evaluations on new fetch
                await fetchInterviewEvaluations();
                setIsFetchingEvaluations(false);
              };

              await handleFetch()

              await new Promise(resolve => setTimeout(resolve, 10000));

              await handleEvalutionFetch()

              setLoadingPrepareAIData(false)


            } catch (error) {

            }

          }

          await fetchShortlistCandidate(jd.id)



          // jd Sl code end


        }


        getJDDataAndCandidateSl(updatedJdDetails)

      } else {
        console.log("No data found in localStorage.");
      }
    } catch (error) {
      console.error("Error parsing JSON from localStorage:", error);
    }






    // spotplusaskai jd upload get recent id code end


  }, []);

  // Function to handle card selection

  // Function to handle "Back" button click (modified)
  const handleBackClick = () => {
    setShowProfile(false); // Hide profile on back click
    setShowSelectedCard(false);
    setCurrentCardIndex(0);
  };

  const handleCloseSearch = () => {
    setIsSearchBarActive(false);
  };

  // ... other functions

  // Function to handle card selection
  // const handleCardSelect = (cardId) => {

  //   const selectedIndex = cards.findIndex((card) => card.id === cardId);
  //   setCurrentCardIndex(selectedIndex);
  // };

  // Function to handle "Next" button click

  //  spot animation code start

  const handleNextClick = () => {
    // If we're not at the last card, increment the index
    // if (currentCardIndex < cardsToShow - 1) {
    //   setCurrentCardIndex(currentCardIndex + 1);
    // } else {
    //   // If we're at the last card, show the selected card view
    //   setShowSelectedCard(true);
    // }

    if (spacifyActivated) {
      setSpacifyActivated(false);
      setPrepareActivated(true);
    } else if (prepareActivated) {
      setPrepareActivated(false);
      setOutreachActivated(true);
    } else if (outreachActivated) {
      // If needed, you can loop back to the first section or add more logic here
      // setOutreachActivated(false); // or set any default
      // setSpacifyActivated(true);


      setOutreachActivated(false);
      setTargetActivated(true)
      // setOutreachActivated(true);
    } else if (targetActivated) {
      setTargetActivated(true);
    }
  };

  const handleBackClickAnimation = () => {
    if (targetActivated) {
      setTargetActivated(false);
      setOutreachActivated(true);
    } else if (outreachActivated) {
      setOutreachActivated(false);
      setPrepareActivated(true);
    } else if (prepareActivated) {
      setPrepareActivated(false);
      setSpacifyActivated(true);
    }
    // No need for else case for "Back" as we don't loop backward automatically
  };

  // spot animation code end

  const handleToggle = () => {
    setIsSearchActive(!isSearchActive);
  };
  const handleSearchBarToggle = () => {
    setIsSearchBarActive(!isSearchBarActive);
  };

  const handleAskAi = () => {
    setIsAskAiActive(!isAskAiActive);
  };

  const handleWorkTypeClick = (workType) => {
    setSelectedWorkTypes((prevTypes) => {
      if (prevTypes.includes(workType)) {
        return prevTypes.filter((type) => type !== workType);
      } else {
        return [...prevTypes, workType];
      }
    });
  };

  const handleFilterChange = (numCards) => {
    setCardsToShowAI(numCards);
  };

  const handleFilterChangeResume = (numCards) => {
    setCardsToShowResume(numCards);

  };

  console.log("888888888888888888", cardsToShowAI);
  console.log("999999999999999999", cardsToShowResume);


  const handleJdPanelToggle = () => {
    setIsJdPanelOpen(!isJdPanelOpen);

    if (boxRef.current) {
      boxRef.current.style.width = isJdPanelOpen ? "100%" : "100%"; // Adjust width based on panel state
    }
  };

  // Function to handle "Back" button click
  // const handleBackClick = () => {
  //   setShowSelectedCard(false);
  //   setCurrentCardIndex(0);
  // };

  // Function to handle "Send Mail" button click
  const handleSendMailClick = () => {
    // Add your send mail logic here
    console.log("Send mail for card:");
  };

  const navigate = useNavigate(); // Initialize useNavigate

  const handleIconClick = (route) => {
    navigate(route);
  };

  // SPOT resume upload code start

  const openResumeUploadModal = () => {
    setResumeUploadModalVisibility(true);
  };

  const closeResumeUploadModal = () => {
    setResumeUploadModalVisibility(false);
    resetResumeFileUploadStates();
  };

  const resetResumeFileUploadStates = () => {
    setSelectedResumeFiles([]);
    setUploadedResumeData([]);
    setResumeUploadErrors([]);
    setIsResumesUploading(false);
  };

  const handleResumeFileSelection = (event) => {
    const files = Array.from(event.target.files);
    setSelectedResumeFiles(files);
  };

  const handleResumeUpload = async () => {
    if (selectedResumeFiles.length === 0) {
      setResumeUploadErrors(["Please select at least one file to upload."]);
      return;
    }

    setLoadingPrepareAIResumeData(true)

    setIsResumesUploading(true);
    setResumeUploadErrors([]);
    const resumeDataArray = [];

    try {
      for (const file of selectedResumeFiles) {
        // Generate unique filename using timestamp

        const folder = "SpotResume/"; // Folder name in S3
        const yourS3BucketName = "getscreenedstoragebucketf24ed-devget";

        const timestamp = Date.now();
        const uniqueFileName = `${timestamp}_${file.name}`;
        const s3Key = `${folder}${uniqueFileName}`; // Combine folder and file name

        // Upload to S3
        const uploadResult = await Storage.put(s3Key, file, {
          contentType: file.type,
        });

        // Get file URL
        const fileUrl =
          await `https://${yourS3BucketName}.s3.amazonaws.com/public/${uploadResult.key}`;

        // Add to resume data array
        // resumeDataArray.push({ fileName: file.name, fileUrl });
        resumeDataArray.push(fileUrl);
      }

      console.log("resume array", resumeDataArray);

      const jsonData = JSON.stringify({ resumes: resumeDataArray }, null, 2);

      // Save resume data as JSON to DynamoDB using createResume mutation
      // const input = { resumeURLs: JSON.stringify(resumeDataArray) }; // Save as JSON string

      const input = {
        companyEmail: companyEmail,
        spotJdID: spotprocessSelectedJD.id,
        resumeURLs: jsonData,
      }; // Save as JSON string

      const response = await API.graphql(
        graphqlOperation(createSpotResume, {
          input,
        })
      );

      console.log("createResume response:", response);
      setResumeUploadModalVisibility(false);
      // alert("All resumes uploaded successfully!");
      setSelectedResumeFiles([]);

      setSpotprocessSelectedResume(response.data.createSpotResume)

      // Fetch Resume Shorlisted candidate code start

      const { data: { createSpotResume: { id } } } = response;


      //  fetching the create resume shortlisting request for ai cdde  

      const ResumeShortlistReqApi = async (id) => {
        if (!id) {
          return
        }

        const input = {
          resume_id: id
        };

        try {
          // Replace with your API endpoint
          const response = await fetch('https://resumeai-budueug9cybsgbaj.canadacentral-01.azurewebsites.net/process_resume', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(input),
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          console.log("951595159515951595159515951595159", data)
          // console.log("951595159515951595159515951595159", data.detailed_profiles)
          // setAiResumesResponce(data.detailed_profiles)
          setSpotprocessAiResumeSlCandidates(data.detailed_profiles)
          // setServerEmails(data);
          setLoadingPrepareAIResumeData(false)
        } catch (error) {
          // setError(error.message);
          console.log(error.message)
        } finally {
          // setLoading(false);
        }

      }

      ResumeShortlistReqApi(id)

      // Fetch Resume Shorlisted candidate code end



    } catch (error) {
      console.error("Error uploading resumes:", error);
      setResumeUploadErrors([
        "Error occurred while uploading resumes. Please try again.",
      ]);
    } finally {
      setIsResumesUploading(false);
    }
  };

  // SPOT resume upload code end

  // target no back button next button code start

  const handleNextClickTarget = () => {

    setOutreachActivated(false);
    setTargetActivated(true);

  }

  // target no back button next button code end

  // spot send mail popup code start

  const openSendMailPopup = () => {
    setIsSendMailPopupVisible(true);
  };

  // Function to close the Send Mail popup
  const closeSendMailPopup = () => {
    setIsSendMailPopupVisible(false);
  };

  // spot send mail popup code end

  // spot process ending code start

  const SpotProcessEnd = async () => {

    const resetStore = () => {

      setSpotprocessCompanyJDList([]);
      setSpotprocessSelectedJDID("");
      setSpotprocessSelectedJD({});
      setSpotprocessSelectedResume({});
      setSpotprocessAiJDSlcandidates([]);
      setSpotprocessAiResumeSlCandidates([]);
      setSpotprocessAiJDSselectedCandidates([]);
      setSpotprocessAiResumeselectedCandidates([]);
      setLoadingPrepareAIData(false);
      setLoadingPrepareAIResumeData(false);

    }

    const resetLocalStorage = () => {

      localStorage.removeItem('RecentJDIdAfterJDupload');

      navigate("/spotplusaskai")

    }

    await resetStore();
    await resetLocalStorage()

  }

  // spot process ending code end 


  console.log("++++++++++++", loadingPrepareAIData);
  console.log("------------", loadingPrepareAIResumeData);

  return (
    <div>
      <Header />
      <div style={styles.mainContainer}>

        <div
          style={
            isSearchBarActive
              ? { ...styles.maindiv, display: "none" }
              : styles.maindiv
          }
        >
          <div
            ref={boxRef}
            style={{
              ...styles.box,
              width: isJdPanelOpen ? "100%" : "100%",
              transition: "width 0.3s ease",
              position: "absolute"
            }}
          >
            {" "}
            {/* Add position: relative */}
            {/* ... (Your existing content within the box div) */}
            {/* Button on the right side, middle */}
            {/* <img src={JdPanal} style={styles.rightButton} /> */}
            <div style={styles.header}>
              <div style={styles.container}>
                {/* SVG Card */}

                <div style={styles.breadmain}>
                  <svg
                    style={styles.svg}
                    viewBox="0 0 165 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M27.5381 47.4547C40.2072 46.9562 50.325 36.528 50.325 23.7367C50.325 10.9454 40.2072 0.517216 27.5381 0.0186562V0H164.261V20.8883C164.261 35.5708 152.359 47.4734 137.676 47.4734H27.5381V47.4547Z"
                      // fill="#DDC8FF"
                      fill={
                        spacifyActivated ? "#DDC8FF" : "rgba(217, 217, 217, 0.45)"
                      }
                    />
                    <rect
                      x="0.25"
                      y="0.25"
                      width="46.9734"
                      height="46.9734"
                      rx="23.4867"
                      fill="url(#paint0_linear_125_1385)"
                    />
                    <g transform="translate(23.7367, 23.7367)">
                      <image
                        href={specify}
                        width={24}
                        height={18}
                        x={-12}
                        y={-9}
                      />
                    </g>
                    <rect
                      x="0.25"
                      y="0.25"
                      width="46.9734"
                      height="46.9734"
                      rx="23.4867"
                      stroke="#DDC8FF"
                      strokeWidth="0.5"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_125_1385"
                        x1="4.5"
                        y1="7"
                        x2="41.5"
                        y2="42"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#667EEA" />
                        <stop offset="1" stopColor="#8859B8" />
                      </linearGradient>
                    </defs>
                  </svg>
                  {/* <img src={specify} style={styles.spacify} /> */}

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="6"
                    viewBox="0 0 22 6"
                    fill="none"
                  >
                    <path
                      d="M0.415372 3L3.15625 5.74088L5.89713 3L3.15625 0.259122L0.415372 3ZM21.0886 3L18.3477 0.259122L15.6069 3L18.3477 5.74088L21.0886 3ZM3.15625 3.47473H5.05519V2.52527H3.15625V3.47473ZM8.85306 3.47473H12.6509V2.52527H8.85306V3.47473ZM16.4488 3.47473H18.3477V2.52527H16.4488V3.47473Z"
                      fill="#D9D9D9"
                    />
                  </svg>
                  {/* Centered Text */}

                  <div style={styles.textselect}>Spacify</div>
                  {/* SVG Card */}
                </div>

                <div style={styles.breadmain}>
                  <svg
                    style={styles.svg}
                    viewBox="0 0 165 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M27.5381 47.4547C40.2072 46.9562 50.325 36.528 50.325 23.7367C50.325 10.9454 40.2072 0.517216 27.5381 0.0186562V0H164.261V20.8883C164.261 35.5708 152.359 47.4734 137.676 47.4734H27.5381V47.4547Z"
                      // fill="rgba(217, 217, 217, 0.45)"
                      fill={
                        prepareActivated ? "#DDC8FF" : "rgba(217, 217, 217, 0.45)"
                      }
                      filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08))"
                    />
                    <rect
                      x="0.25"
                      y="0.25"
                      width="46.9734"
                      height="46.9734"
                      rx="23.4867"
                      fill="rgba(217, 217, 217, 0.24)"
                    />
                    <g transform="translate(23.7367, 23.7367)">
                      <image
                        href={prepare}
                        width={24}
                        height={24}
                        x={-12}
                        y={-12}
                      />
                    </g>
                    <rect
                      x="0.25"
                      y="0.25"
                      width="46.9734"
                      height="46.9734"
                      rx="23.4867"
                      stroke="#DDC8FF"
                      strokeWidth="0.5"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_125_1385"
                        x1="4.5"
                        y1="7"
                        x2="41.5"
                        y2="42"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#667EEA" />
                        <stop offset="1" stopColor="#8859B8" />
                      </linearGradient>
                    </defs>
                  </svg>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="6"
                    viewBox="0 0 22 6"
                    fill="none"
                  >
                    <path
                      d="M0.415372 3L3.15625 5.74088L5.89713 3L3.15625 0.259122L0.415372 3ZM21.0886 3L18.3477 0.259122L15.6069 3L18.3477 5.74088L21.0886 3ZM3.15625 3.47473H5.05519V2.52527H3.15625V3.47473ZM8.85306 3.47473H12.6509V2.52527H8.85306V3.47473ZM16.4488 3.47473H18.3477V2.52527H16.4488V3.47473Z"
                      fill="#D9D9D9"
                    />
                  </svg>
                  {/* Centered Text */}
                  <div style={styles.text}>Prepare</div>
                  {/* SVG Card */}
                </div>
                <div style={styles.breadmain}>
                  <svg
                    style={styles.svg}
                    viewBox="0 0 165 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M27.5381 47.4547C40.2072 46.9562 50.325 36.528 50.325 23.7367C50.325 10.9454 40.2072 0.517216 27.5381 0.0186562V0H164.261V20.8883C164.261 35.5708 152.359 47.4734 137.676 47.4734H27.5381V47.4547Z"
                      // fill="rgba(217, 217, 217, 0.45)"
                      fill={
                        outreachActivated
                          ? "#DDC8FF"
                          : "rgba(217, 217, 217, 0.45)"
                      }
                      filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08))"
                    />
                    <rect
                      x="0.25"
                      y="0.25"
                      width="46.9734"
                      height="46.9734"
                      rx="23.4867"
                      fill="rgba(217, 217, 217, 0.24)"
                    />
                    <g transform="translate(23.7367, 23.7367)">
                      <image
                        href={outreach}
                        width={24}
                        height={24}
                        x={-12}
                        y={-12}
                      />
                    </g>
                    <rect
                      x="0.25"
                      y="0.25"
                      width="46.9734"
                      height="46.9734"
                      rx="23.4867"
                      stroke="#DDC8FF"
                      strokeWidth="0.5"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_125_1385"
                        x1="4.5"
                        y1="7"
                        x2="41.5"
                        y2="42"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#667EEA" />
                        <stop offset="1" stopColor="#8859B8" />
                      </linearGradient>
                    </defs>
                  </svg>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="6"
                    viewBox="0 0 22 6"
                    fill="none"
                  >
                    <path
                      d="M0.415372 3L3.15625 5.74088L5.89713 3L3.15625 0.259122L0.415372 3ZM21.0886 3L18.3477 0.259122L15.6069 3L18.3477 5.74088L21.0886 3ZM3.15625 3.47473H5.05519V2.52527H3.15625V3.47473ZM8.85306 3.47473H12.6509V2.52527H8.85306V3.47473ZM16.4488 3.47473H18.3477V2.52527H16.4488V3.47473Z"
                      fill="#D9D9D9"
                    />
                  </svg>

                  {/* Centered Text */}
                  <div style={styles.text}>Outreach</div>
                  {/* SVG Card */}
                </div>
                <div style={styles.breadmain}>
                  <svg
                    style={styles.svg}
                    viewBox="0 0 165 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M27.5381 47.4547C40.2072 46.9562 50.325 36.528 50.325 23.7367C50.325 10.9454 40.2072 0.517216 27.5381 0.0186562V0H164.261V20.8883C164.261 35.5708 152.359 47.4734 137.676 47.4734H27.5381V47.4547Z"
                      // fill="rgba(217, 217, 217, 0.45)"
                      fill={
                        targetActivated
                          ? "#DDC8FF"
                          : "rgba(217, 217, 217, 0.45)"
                      }
                      filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08))"
                    />
                    <rect
                      x="0.25"
                      y="0.25"
                      width="46.9734"
                      height="46.9734"
                      rx="23.4867"
                      fill="rgba(217, 217, 217, 0.24)"
                    />
                    <g transform="translate(23.7367, 23.7367)">
                      <image
                        href={target}
                        width={24}
                        height={24}
                        x={-12}
                        y={-12}
                      />
                    </g>
                    <rect
                      x="0.25"
                      y="0.25"
                      width="46.9734"
                      height="46.9734"
                      rx="23.4867"
                      stroke="#DDC8FF"
                      strokeWidth="0.5"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_125_1385"
                        x1="4.5"
                        y1="7"
                        x2="41.5"
                        y2="42"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#667EEA" />
                        <stop offset="1" stopColor="#8859B8" />
                      </linearGradient>
                    </defs>
                  </svg>

                  {/* Centered Text */}
                  <div style={styles.text}>Target</div>
                  {/* SVG Card */}
                </div>
              </div>
              <div style={styles.leftcontainer}>
                <img src={spotlogo} style={styles.spotlogo} />
              </div>
            </div>
            <div
              className="maindiv"
              style={
                showProfile
                  ? { ...styles.rectanglesplit, display: "none" }
                  : styles.rectanglesplit
              }
            >
              {" "}
              {/* Conditionally hide maindiv */}
              <div style={styles.rectangle}>
                <div style={styles.internalheader}>
                  <h3 style={styles.jobtitle}>{spotprocessSelectedJD.jdName}</h3>

                  {/* prepare Database multiple select */}

                  {
                    prepareActivated && activeTab === "Transcript" && (

                      <div style={styles.middiv}>
                        <div style={styles.filter}>
                          {[5, 10, 15, 20].map((numCards) => (
                            <div
                              key={numCards}
                              style={
                                cardsToShowAI === numCards // Check if the current number is selected
                                  ? { ...styles.frame, ...styles.selectedFrame } // Apply selected styles
                                  : styles.frame
                              }
                              onClick={() => handleFilterChange(numCards)}
                            >
                              <div style={styles.textWrapper}>{numCards}</div>
                            </div>
                          ))}
                        </div>
                      </div>

                    )
                  }



                  {/* prepare Database multiple select */}

                  {/* prepare Resume multiple select */}

                  {
                    prepareActivated && activeTab === "Video" && (
                      <div style={styles.middiv}>
                        <div style={styles.filter}>
                          {[5, 10, 15, 20].map((numCards) => (
                            <div
                              key={numCards}
                              style={
                                cardsToShowResume === numCards // Check if the current number is selected
                                  ? { ...styles.frame, ...styles.selectedFrame } // Apply selected styles
                                  : styles.frame
                              }
                              onClick={() => handleFilterChangeResume(numCards)}
                            >
                              <div style={styles.textWrapper}>{numCards}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                  }


                  {/* prepare Resume multiple select */}



                  <div style={styles.righttoggle}>

                    {
                      spotprocessSelectedJD.id && prepareActivated ? (

                        <div style={styles.sidebar}>
                          <div>
                            <img
                              src={CreateJd}
                              alt="AI Logo"
                              style={styles.createjd}
                            />
                          </div>

                          {/* <div style={styles.box2}>
                    <img src={UploadJd} alt="AI Logo" style={styles.uploadjd} />
                  </div> */}

                          <div
                            style={styles.createjdbutton}
                            onClick={openResumeUploadModal}
                          >
                            Resume Uploads
                          </div>

                        </div>

                      ) : (
                        <></>
                      )
                    }

                    {/* preapare tab */}

                    {
                      prepareActivated && (
                        <div style={styles.togglediv}>
                          <div
                            style={
                              activeTab === "Video"
                                ? {
                                  ...styles.toggleinterviewdetailsof,
                                  ...styles.activeTab,
                                }
                                : styles.toggleinterviewdetailsof
                            }
                            onClick={() => handleTabClick("Video")}
                          >
                            Ai DataBase
                          </div>
                          <div
                            style={
                              activeTab === "Transcript"
                                ? {
                                  ...styles.toggleinterviewdetailsof,
                                  ...styles.activeTab,
                                }
                                : styles.toggleinterviewdetailsof
                            }
                            onClick={() => handleTabClick("Transcript")}
                          >
                            Resume DataBase
                          </div>
                        </div>
                      )
                    }

                    {/* outreach Tab */}

                    {
                      outreachActivated && (
                        <div style={styles.togglediv}>
                          <div
                            style={
                              activeTabOutreach === "Database"
                                ? {
                                  ...styles.toggleinterviewdetailsof,
                                  ...styles.activeTab,
                                }
                                : styles.toggleinterviewdetailsof
                            }
                            onClick={() => handleTabClickOutreach("Database")}
                          >
                            Ai DataBase
                          </div>
                          <div
                            style={
                              activeTabOutreach === "Resume"
                                ? {
                                  ...styles.toggleinterviewdetailsof,
                                  ...styles.activeTab,
                                }
                                : styles.toggleinterviewdetailsof
                            }
                            onClick={() => handleTabClickOutreach("Resume")}
                          >
                            Resume DataBase
                          </div>
                        </div>
                      )
                    }

                    {/* target phase */}
                    {
                      targetActivated && (
                        <div style={styles.middiv} className="">
                          <h4 style={styles.filter}>Final Selection: Candidates for Screening Round</h4>

                        </div>
                      )
                    }

                  </div>
                </div>

                {isResumeUploadModalVisible && (
                  <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-8 rounded-lg w-full max-w-md shadow-lg transform transition-all duration-300 scale-95 hover:scale-100">
                      {/* Modal Header */}
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-2xl font-semibold text-black">
                          Upload Multiple Resumes
                        </h2>
                        <button
                          onClick={closeResumeUploadModal}
                          className="text-gray-500 hover:text-gray-700 text-2xl transition-all duration-300"
                        >
                          &times;
                        </button>
                      </div>

                      {/* File Input */}
                      <input
                        type="file"
                        multiple
                        onChange={handleResumeFileSelection}
                        className="block w-full px-4 py-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                      />

                      {/* Selected Files */}
                      {selectedResumeFiles.length > 0 && (
                        <div className="mb-4">
                          <h3 className="text-lg font-semibold">
                            Selected Files:
                          </h3>
                          <ul>
                            {selectedResumeFiles.map((file, index) => (
                              <li key={index} className="text-sm text-gray-600">
                                {file.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}

                      {/* Error Messages */}
                      {resumeUploadErrors.length > 0 && (
                        <div className="text-red-600 mb-4">
                          {resumeUploadErrors.map((error, index) => (
                            <p key={index}>{error}</p>
                          ))}
                        </div>
                      )}

                      {/* Upload Button or Spinner */}
                      <div className="text-center">
                        {isResumesUploading ? (
                          <div className="animate-pulse text-blue-600">
                            Uploading...
                          </div>
                        ) : (
                          <button
                            onClick={handleResumeUpload}
                            className="px-6 py-3 bg-green-600 text-white rounded-md hover:bg-green-700 transition ease-in-out duration-300"
                          >
                            Upload Resumes
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <hr style={styles.hr} />
                <div style={styles.miidlebox}>
                  <div style={styles.leftmidbox}>
                    {/* Conditionally render the toggle divs */}
                    {isSearchActive ? (
                      <div className="toggle1" style={styles.toggle}>
                        <img
                          src={AiLogo2}
                          style={styles.searchbar}
                          onClick={handleToggle}
                        />
                        <img
                          src={Searchbar2}
                          style={styles.ailogo}
                          onClick={handleSearchBarToggle}
                        />
                      </div>
                    ) : (
                      <div className="toggle2" style={styles.toggle}>
                        <img
                          src={Searchbar}
                          style={styles.searchbar}
                          onClick={handleToggle}
                        />
                        <img
                          src={AiLogo}
                          style={styles.ailogo}
                          onClick={handleAskAi}
                        />
                      </div>
                    )}

                    <div style={styles.sidenav}>
                      <img
                        src={Search}
                        onClick={() => handleIconClick("/SpotPlusCampain")}
                        alt="Search"
                      />{" "}
                      {/* Add onClick and alt */}
                      <img
                        src={Job}
                        onClick={() => handleIconClick("/job")}
                        alt="Job"
                      />{" "}
                      {/* Add onClick and alt */}
                      <img
                        src={Linear}
                        onClick={() => handleIconClick("/linear")}
                        alt="Linear"
                      />{" "}
                      {/* Add onClick and alt */}
                    </div>
                  </div>
                  <div style={styles.rightmidbox}>
                    {/* Use grid for responsive card layout */}
                    <div style={styles.cardstructure}>

                      {/*  Main Display */}


                      {/* Modified card rendering logic */}
                      {/* {!showProfile &&
                      !showSelectedCard && ( // Only render cards if profile is not shown
                        <SpotPlusCard />
                      )}

                    {!showProfile && showSelectedCard && <SpotPlusCard />} */}


                      {
                        spacifyActivated && (
                          <h1>specify</h1>
                        )
                      }
                      {
                        prepareActivated && (
                          <>
                            {
                              activeTab === "Video" && (
                                <>
                                  {spotprocessSelectedJD && Object.keys(spotprocessSelectedJD).length > 0 ? (
                                    loadingPrepareAIData ? (
                                      // <p>Loading...</p> // Show loading message if data is loading
                                      <div className="" style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '50vh',
                                      }}>
                                        <img src={loadinganimation2} class alt="Loading Animation"
                                          style={{
                                            borderRadius: '100%',
                                            width: '10%',
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <SpotPlusCard /> // Show SpotPlusCard if data is loaded 
                                    )
                                  ) : (
                                    <p style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      height: '50vh',
                                    }} className="font-medium" >Select a Job Description</p> // Prompt to select JD if not selected
                                  )}

                                </>
                              )
                            }

                            {/* resume below */}

                            {
                              activeTab === "Transcript" && (
                                <>
                                  {loadingPrepareAIResumeData ? (
                                    // Show loading animation while the resume data is loading
                                    <div style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      height: '50vh',
                                    }}>
                                      <img
                                        src={loadinganimation2}
                                        alt="Loading Animation"
                                        style={{
                                          borderRadius: '100%',
                                          width: '10%',
                                        }}
                                      />
                                    </div>
                                  ) : spotprocessAiResumeSlCandidates && Object.keys(spotprocessAiResumeSlCandidates).length > 0 ? (
                                    // Show SpotPlusCardPrepare if data is loaded and resumes are available
                                    <SpotPlusCardPrepare />
                                  ) : (
                                    // Prompt to upload resume if no resumes are available
                                    <p style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      height: '50vh',
                                    }} className="font-medium">
                                      Upload Resume
                                    </p>
                                  )}
                                </>
                              )
                            }

                          </>
                        )
                      }
                      {
                        outreachActivated && !isSendMailPopupVisible && (
                          <>
                            {/* {
                              activeTabOutreach === "Database" && (
                                <SpotPlusCardOutreach />
                              )
                            } */}

                            {
                              activeTabOutreach === "Database" && (
                                spotprocessAiJDSselectedCandidates && Object.keys(spotprocessAiJDSselectedCandidates).length > 0 ? (
                                  <SpotPlusCardOutreach />
                                ) : (
                                  <p style={{
                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '50vh',
                                  }}>No candidates selected. Please select a candidate.</p> // Message when no candidates are selected
                                )
                              )
                            }



                            {/* {
                              activeTabOutreach === "Resume" && (
                                <SpotPlusCardOutreachResume />
                              )
                            } */}

                            {
                              activeTabOutreach === "Resume" && (
                                spotprocessAiResumeselectedCandidates && Object.keys(spotprocessAiResumeselectedCandidates).length > 0 ? (
                                  <SpotPlusCardOutreachResume />
                                ) : (
                                  <p style={{
                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '50vh',
                                  }}>No candidates selected. Please select a candidate.</p> // Message when no candidates are selected
                                )
                              )
                            }

                          </>
                        )
                      }
                      {
                        outreachActivated && isSendMailPopupVisible && (

                          <div className="flex justify-center items-center ">


                            {/* Conditionally render the SendMailPopup */}
                            <SpotPlusSendMailPopup onClose={closeSendMailPopup} onTargetActive={handleNextClickTarget} />
                          </div>
                        )
                      }
                      {
                        targetActivated && (

                          // dummy mail send code start


                          <div className="h-[60vh] flex flex-row items-center justify-center bg-red text-black">
                            <SpotPlusTargetResume />
                          </div>

                          // dummy send mail code end

                        )
                      }





                      {/* Main screen */}
                    </div>

                    {/* Button Container */}
                    <div style={styles.buttonContainer}>
                      {showSelectedCard ? (
                        <>
                          <button
                            style={styles.backButton}
                            onClick={handleBackClick}
                          >
                            Back
                          </button>
                          <button
                            style={styles.sendMailButton}
                            onClick={handleSendMailClick}
                          >
                            Send Mail
                          </button>
                        </>
                      ) : (
                        <div className="flex gap-4">
                          {
                            !targetActivated && (
                              <button
                                style={styles.nextButton}
                                onClick={handleBackClickAnimation}
                                className="p-3 bg-red-600 "
                              >
                                Back
                              </button>
                            )
                          }

                          {
                            !outreachActivated && !targetActivated && (
                              <button
                                style={styles.nextButton}
                                onClick={handleNextClick}
                                className="p-3 bg-red-600 "
                              >
                                Next
                              </button>
                            )
                          }
                          {
                            outreachActivated && !isSendMailPopupVisible && (
                              <button
                                style={styles.nextButton}
                                // onClick={handleNextClick}
                                onClick={openSendMailPopup}
                                className="p-3 bg-red-600 "
                              >
                                Send Mail
                              </button>
                            )
                          }
                          {
                            targetActivated && (
                              <button
                                style={styles.nextButton}
                                onClick={() => SpotProcessEnd()}
                                className="p-3 bg-red-600 "
                              >
                                SPOT GPT
                              </button>
                            )
                          }

                        </div>
                      )}
                    </div>
                  </div>
                  {
                    prepareActivated && (

                      <img
                        src={JdPanal}
                        style={{
                          ...styles.rightButton,
                          right: isJdPanelOpen ? "27.30%" : "-16px",
                          transition: "right 0.3s ease",
                        }} // Update button position
                        onClick={handleJdPanelToggle}
                      />

                    )
                  }

                </div>
              </div>
              {/* JD Card Panel */}
              {
                prepareActivated && (
                  <div
                    style={{
                      ...styles.jdPanel,
                      width: isJdPanelOpen ? "43%" : "0%",
                      transition: "width 0.3s ease",
                    }}
                  >
                    <SpotPlusYourJd />
                  </div>
                )
              }
              {/* <div
                style={{
                  ...styles.jdPanel,
                  width: isJdPanelOpen ? "43%" : "0%",
                  transition: "width 0.3s ease",
                }}
              >
                <SpotPlusYourJd />
              </div> */}



            </div>
            {/* Button to toggle JD Panel */}
            {/* Conditionally render SpotPlusProfile */}
            {showProfile && (
              <SpotPlusProfile
                onBack={handleBackClick} // Pass the back function as a prop
              />
            )}
          </div>
        </div>
        {/* Conditionally render the search component */}
        {isSearchBarActive && <SpotPlusSearch onClose={handleCloseSearch} />}
        {isAskAiActive && <SpotPlusAskAi />}
      </div>
    </div>
  );
}

// Internal CSS
const styles = {
  rectanglesplit: {
    display: "flex",
  },
  // Main container to hold both box and JD panel
  mainContainer: {
    display: "flex",
    height: "100vh",
    position: "relative"
  },

  // JD Card Panel styles
  jdPanel: {
    // backgroundColor: "#f0f0f0",
    overflowY: "scroll", // Add scroll for content overflow
    position: "relative", // Allow absolute positioning of children
    display: "grid",
    justifyContent: "center",
    paddingTop: "16px",
    gap: "16px",
    // height: "100vh",
    // left: "32px",
    border: "1px solid rgba(217, 217, 217, 0.48)",
    borderRadius: "16px",
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px",
    margin: "14px 0px",
    height: "88vh",
  },
  body: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
  },
  box: {
    height: " 100vh",
    width: "100%",
    // Add position: relative to allow absolute positioning of the button
    position: "relative",
  },

  // Style for the right-side button
  rightButton: {
    position: "absolute",
    top: "50%", // Position at 50% from the top
    // right: "-16px",
    // Adjust distance from the right edge as needed
    transform: "translateY(-50%) rotate(0deg)", // Add rotation
    padding: "10px 20px",

    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    zIndex: "1",
  },
  header: {
    borderBottom: "1px solid rgba(217, 217, 217, 0.48)",
    width: "100%",
    // height: "12%",
    display: "flex",
    alignItems: "center",
  },
  //   hr: {
  //     width: "inherit",
  //   },
  rectangle: {
    backgroundColor: "#d9d9d91a",
    border: " 1px solid #d9d9d97a",
    borderRadius: "16px",
    boxShadow: "0px 4px 4px #00000040",
    // height: "80%",
    left: "0",

    top: " 0",
    width: "98%",
    margin: "14px",
  },
  internalheader: {
    // background: "#000",
    width: "100%",
    // height: "12%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "1rem"
  },
  jobtitle: {
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    // width: "50%",
    paddingLeft: "10px",
  },
  miidlebox: {
    // background: "#000",
    // width: "100%",
    // height: "90%",
    // marginTop: "10px",
    display: "flex",
    margin: "1% 2%",
    gap: "56px",
  },
  leftmidbox: {
    backgroundColor: "transparent",

    // width: "10%",
    // height: "92%",
    display: "grid",
    // alignItems: "center",
    justifyContent: "center",
    // position: "absolute",
    // gap: "82px",
  },
  sidenav: {
    backgroundColor: "#ffffff1a",
    border: "1px solid #ffffff33",
    borderRadius: "20px",
    width: "65px",
    height: "278px",
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer", // Make the whole area look clickable
  },
  rightmidbox: {
    background: "transparent",
    width: "100%",
    alignItems: "start",
    justifyContent: "start",
    position: "relative",
    borderRadius: "8px",
    height: "63vh",
    overflowX: "hidden",
  },
  cardstructure: {
    display: "grid", // Use grid layout
    gridTemplateColumns: "repeat(auto-fit, minmax(400px, 3fr))", // Responsive columns
    gap: "24px",
    padding: "10px", // Add padding for better spacing
  },
  toggle: {
    width: "100%",
    // height: "15vh",
    position: "relative",
  },
  icon: {
    width: "65px", // Default size for the big icon
    position: "absolute",
    cursor: "pointer",
  },
  iconHidden: {
    width: "32px", // Size for the small icon
    position: "absolute",
    top: "40px", // Adjust position to match your design
    left: "44px", // Adjust position to match your design
    cursor: "pointer",
  },

  ailogo: {
    width: "65px",
    position: "absolute",
  },
  searchbar: {
    width: "32px",
    position: "absolute",
    top: "40px",
    left: "44px",
    zIndex: "1",
  },
  container: {
    position: "relative",
    display: "inline-block",
    padding: "10px",
    // width: "10%",
    display: "flex",
    width: "50%",
    // gap: "16px",
  },
  leftcontainer: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  svg: {
    width: "100%",
  },
  textselect: {
    position: "absolute",
    fontSize: "14px",
    fontWeight: "bold",
    background: "linear-gradient(180deg, #667EEA 0%, #8859B8 100%)", // Added gradient background
    backgroundClip: "text", // Added for gradient clipping
    WebkitBackgroundClip: "text", // Added for WebKit browsers
    WebkitTextFillColor: "transparent", // Make text transparent for gradient to show
    pointerEvents: "none",
    paddingLeft: "0px",
  },
  text: {
    position: "absolute",
    // top: "45%",
    // left: "18%",

    // transform: "translate(-50%, -50%)",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#000",
    pointerEvents: "none", // Prevents text from interfering with SVG interactions
    paddingLeft: "0px",
  },
  breadmain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sidebar: {
    width: "98px",
    height: "73px",
    backgroundColor: "#ffffff1a",
    border: " 1px solid #ffffff33",
    position: "relative",
    borderRadius: "20px",
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
    marginTop: "20px",
    marginBottom: "0px",
    marginRight: "20px",
  },
  sidebarnavigation: {
    width: "98px",
    height: "179px",
    backgroundColor: "#ffffff1a",
    border: " 1px solid #ffffff33",
    borderRadius: "20px",
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
  },
  createjdbutton: {
    backgroundColor: "rgba(255, 255, 255, 1.0)",
    borderTop: " 1px solid rgba(255, 255, 255, 0.4)",

    width: "98px",
    height: "20px",
    borderRadius: "0px 0px 20px 20px",
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    fontWeight: "100",
    color: "#fff",
  },
  createjd: {
    top: "13px",
    position: "absolute",
    left: "28px",
  },
  uploadjd: {},
  main: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
  },
  createjd: {
    top: "13px",
    position: "absolute",
    left: "28px",
  },
  uploadjd: {},
  main: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
  },
  box2: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    border: "1px solid #ffffff7a",

    borderRadius: "18px",
    height: "24px",
    width: "24px",
    position: "absolute",
    top: "-13px",
    left: "81px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  spotanimationlogo: {
    width: "50%",
  },
  inputdiv: {
    width: "98%",

    // position: "absolute",
    margin: "4px 12px",
  },
  formdiv: {
    border: "1px solid #fff",
    width: "98%",
    height: "5vh",
    // position: "absolute",
    margin: "8px 0px",
    borderRadius: "4px",

    background: "transparent",
  },

  description: {
    border: "1px solid #fff",
    width: "98%",
    height: "80px",
    // position: "absolute",
    margin: "8px 0px",
    borderRadius: "4px",
    background: "transparent",
  },

  Experincetitle: {
    appearance: "none", // Hide default arrow
    padding: "8px 12px", // Adjust padding to give room for the icon
    borderRadius: "4px",
    border: "1px solid #ccc",
    backgroundColor: "#fffefe", // Solid background for dropdown
    backgroundImage: `
      linear-gradient(45deg, transparent 50%, rgb(102, 126, 234) 50%), 
      linear-gradient(135deg, rgb(136, 89, 184) 50%, transparent 50%)
    `, // Gradient-colored arrow
    backgroundPosition: `
      calc(100% - 15px) calc(1em + 2px), 
      calc(100% - 10px) calc(1em + 2px)
    `,
    backgroundSize: "5px 5px, 5px 5px",
    backgroundRepeat: "no-repeat",
    color: "#333", // Optional text color for dropdown text
    width: "34%",
  },
  form: {
    position: "absolute",
    width: "98%",
    display: "flex",
    flexDirection: "column", // Align items in a column
    alignItems: "flex-end",
  },
  Experince: {
    display: "flex",
    alignItems: "center",
    marginTop: "12px",
    justifyContent: "space-around",
  },
  underExperince: {
    width: "61%",
  },
  title: {
    margin: "10px",
  },
  worktype: {
    display: "flex",
    margin: "12px",
    alignItems: "center",
    gap: "12px",
    marginTop: "12px",
  },
  worktypename: {
    border: "1px solid #fff",
    // width: "260px",
    // height: "10px",
    padding: "5px 20px",
    borderRadius: "4px",
  },

  // Style for selected work type
  selectedWorkType: {
    backgroundColor: "#667EEA", // Example: Highlight selected type
    color: "#fff",
  },
  submit: {
    border: "1px solid #fff",
    padding: "5px 20px",
    borderRadius: "4px",
    margin: "10px 40px",
  },
  frame: {
    alignItems: "center",
    // backgroundColor: "#ddc8ff",
    border: "0.5px solid",
    borderColor: "#d9d9d952",
    borderRadius: "32px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    height: "50px",
    justifyContent: "center",
    padding: "11px 18px",
    position: "relative",
    width: "50px",
    cursor: "pointer", // Add cursor pointer to indicate clickability
  },

  // Style for the selected frame
  selectedFrame: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)", // Example: Highlight selected frame
  },
  // Nested style for .frame .text-wrapper
  textWrapper: {
    color: "#ffffff",
    fontFamily: '"Roboto-Regular", Helvetica',
    fontSize: "22.8px",
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: "normal",
    position: "relative",
    whiteSpace: "nowrap",
    width: "fit-content",
  },
  filter: {
    display: "flex",
    gap: "12px",
    justifyContent: "center",
  },
  nextButton: {
    background: "rgb(91, 12, 162)",
    borderRadius: "8px",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    paddingRight: "32px",
  },
  togglediv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "32px",
    margin: "16px",
    background: "#DDC8FF",
    padding: "6px 16px",
    borderRadius: "24px",
  },
  toggleinterviewdetailsof: {
    // Styles for INACTIVE tabs
    padding: "4px 32px",
    borderRadius: "22px",
    color: "#000", // Or a lighter color that contrasts with the active tab's background
    background: "transparent", // Or a subtle background color if you prefer
    border: "1px solid #DDC8FF", // Optional thin border
    cursor: "pointer", // Indicate clickability
  },
  viewdetails: {
    backgroundColor: "#000",
    height: "100vh", //after adding contain remove height
    borderRadius: "8px",
  },
  activeTab: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    color: "#fff", // White text for better contrast
    // Add any other styles you want for the active tab, e.g., border, box-shadow, etc.
  },
  middiv: {
    padding: "10px",
  },
  righttoggle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default SpotPlusCandidte;
