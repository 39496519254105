import React, { useState, useEffect, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  listCandidateProfiles,
  listInterviewEvaluations,
  listAdminAddCandidateContexts,
  listProfileVisits,
} from "../../graphql/queries"; // Make sure this import is correct
import { useLocation } from "react-router-dom";
import Header from "../../components/header/Header"; // Assuming you have a Header component
import profilephoto from "../../assets/profile.png"; // Replace with your actual import
import { createProfileVisit } from "../../graphql/mutations";

const ShowMySkills = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get("email");

  const [candidateProfile, setCandidateProfile] = useState(null);
  const [privateCandidate, setPrivateCandidate] = useState(false);
  const [interviewEvaluations, setInterviewEvaluations] = useState([]);
  const [profiles, setProfiles] = useState([]);


  useEffect(() => {
    const fetchCandidateProfile = async () => {
      let nextToken = null;
      let candidateProfileItem = null;

      try {
        do {
          const candidateProfileResponse = await API.graphql(
            graphqlOperation(listCandidateProfiles, {
              filter: { email: { eq: email } },
              limit: 100,
              nextToken: nextToken,
            })
          );

          const items =
            candidateProfileResponse.data.listCandidateProfiles.items;

          if (items.length > 0) {
            candidateProfileItem = items[0];
            break;
          } else {
            nextToken =
              candidateProfileResponse.data.listCandidateProfiles.nextToken;
          }
        } while (nextToken);

        setCandidateProfile(candidateProfileItem);
        setPrivateCandidate(candidateProfileItem?.private_Candidate);

        if (candidateProfileItem) {
          fetchInterviewEvaluations(candidateProfileItem.email);
        }
      } catch (error) {
        console.error("Error fetching candidate profile:", error);
      }
    };

    const fetchInterviewEvaluations = async (candidateProfileEmailId) => {
      let nextToken = null;
      let interviewEvaluationsItems = [];

      try {
        do {
          const interviewEvaluationsResponse = await API.graphql(
            graphqlOperation(listInterviewEvaluations, {
              filter: {
                candidateEmail: { eq: candidateProfileEmailId },
              },
              limit: 100,
              nextToken: nextToken,
            })
          );

          interviewEvaluationsItems = [
            ...interviewEvaluationsItems,
            ...interviewEvaluationsResponse.data.listInterviewEvaluations.items,
          ];
          nextToken =
            interviewEvaluationsResponse.data.listInterviewEvaluations
              .nextToken;
        } while (nextToken);

        setInterviewEvaluations(interviewEvaluationsItems);
      } catch (error) {
        console.error("Error fetching interview evaluations:", error);
      }
    };

    const fetchAdminContextProfiles = async () => {
      let nextToken = null;
      const adminContextItems = [];

      try {
        do {
          const adminContextResponse = await API.graphql(
            graphqlOperation(listAdminAddCandidateContexts, {
              filter: {
                candidateEmail: { eq: email },
              },
              limit: 100,
              nextToken: nextToken,
            })
          );

          const adminContextPageItems =
            adminContextResponse.data.listAdminAddCandidateContexts.items;
          adminContextItems.push(...adminContextPageItems);
          nextToken =
            adminContextResponse.data.listAdminAddCandidateContexts.nextToken;
        } while (nextToken);

        const filteredProfiles = adminContextItems.filter(
          (profile) => profile.candidateEmail === email
        );
        setProfiles(filteredProfiles);
      } catch (error) {
        console.error("Error fetching admin context profiles:", error);
      }
    };

    if (email) {
      fetchCandidateProfile();
      fetchAdminContextProfiles();
    }


  }, [email]);


  // useEffect for view and seen code start

  const hasRun = useRef(false);

  useEffect(() => {

    if (hasRun.current) return; // Prevent re-running
    hasRun.current = true;

    const fetchingView = async () => {

      let nextToken = null;
      let profileVisitItems = [];
      let isVisitorPresent

      const Profile = localStorage.getItem('candidateProfile');
      const company = localStorage.getItem('CompanyProfile')

      const profilefromlocal = JSON.parse(Profile || company);

      try {
        do {
          const profileVisitResponse = await API.graphql(
            graphqlOperation(listProfileVisits, {
              filter: { visitedEmail: { eq: email } }, // Adjust field name as per your schema
              limit: 100,
              nextToken: nextToken,
            })
          );

          const items = profileVisitResponse.data.listProfileVisits.items;

          profileVisitItems = [...profileVisitItems, ...items];
          nextToken = profileVisitResponse.data.listProfileVisits.nextToken;

        } while (nextToken);


        isVisitorPresent = profileVisitItems.some(item => item.visitorEmail === profilefromlocal.email);

        // console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%",isVisitorPresent)
      } catch (error) {
        console.error("Error fetching profile visits:", error);
      }

      // if (profileVisitItems.length > 0 && isVisitorPresent === true || profilefromlocal.email === email) {
      if (!profileVisitItems.length > 0 && profilefromlocal.email === email) {
        console.log("Already data is there")
      }
      else {

        const mutateProfileVisit = async (visitedEmail, visitorEmail) => {


          const savedProfile = localStorage.getItem('candidateProfile');
          const company = localStorage.getItem('CompanyProfile')

          const profile = JSON.parse(savedProfile || company);


          let nextToken = null;
          let candidateProfiles = [];
          let isCandidateProfilePresent;

          try {



            do {
              const candidateProfileResponse = await API.graphql(
                graphqlOperation(listCandidateProfiles, {
                  filter: { email: { eq: email } },  // Filtering by email
                  // limit: 100,
                  nextToken: nextToken,
                })
              );
          
              const items = candidateProfileResponse.data.listCandidateProfiles.items;
          
              candidateProfiles = [...candidateProfiles, ...items];
              nextToken = candidateProfileResponse.data.listCandidateProfiles.nextToken;
          
            } while (nextToken);
          
            // Check if the specific candidate profile exists
            isCandidateProfilePresent = candidateProfiles.length > 0;  // Since it's filtered by email, if profiles are returned, it's present.
          

            // console.log("Fetched Candidate Profiles:[[[[[[[[[[[[[[[[[[[[", candidateProfiles[0]);

            const input = {
              visitedEmail: email,
              visitorEmail: profile.email,
              visitedProfile:JSON.stringify(candidateProfiles[0]),
              visitorProfile:JSON.stringify(profile),
              visitedTime: new Date().toISOString(),
              isViewed:false
              // Add any additional fields required by your schema here
            };

            const response = await API.graphql(
              graphqlOperation(createProfileVisit, { input })
            );

          } catch (error) {
            console.error("Error mutating profile visit:", error);
          }
        };

        mutateProfileVisit()


      }

    }

    fetchingView()

  }, [])


  // useEffect for view and seen code end


  return (
    <div>
      {/* Header Section */}
      <Header />

      {/* Container for centering content */}
      <div className="container" style={{ color: "#fff", width: "80%" }}>
        {/* Candidate Profile Details */}
        {candidateProfile && (
          <div className="card" style={{ marginTop: "50px" }}>
            <div className="card-body">
              <div className="profile-header">
                <img
                  src={candidateProfile.photoURL || profilephoto}
                  alt="Profile"
                  className="profile-img"
                />
                <div>
                  <h5 className="card-title" style={{ color: "#fff" }}>
                    {candidateProfile.fullName}
                  </h5>
                  <strong style={{ color: "#fff" }}>
                    Work Status: {candidateProfile.workStatus}
                  </strong>
                  <br></br>
                  <strong style={{ color: "#fff" }}>
                    {" "}
                    Job Position : {candidateProfile.roleApplyFor}
                  </strong>


                  {candidateProfile.linkedinURL && (
                    <a className="ml-32" href={candidateProfile.linkedinURL} target="_blank" rel="noopener noreferrer">
                      <i className="fa-brands fa-linkedin" style={{ fontSize: '30px', color: '#fff', cursor: 'pointer' }} />
                    </a>
                  )}
                  {candidateProfile.githubURL && (
                    <a className="ml-3" href={`${candidateProfile.githubURL}`} target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-github" style={{ fontSize: '30px', color: '#fff', cursor: 'pointer' }} />
                    </a>
                  )}
                  {/* href={`https://${candidateProfile.linkedinURL}`}  */}

                </div>
              </div>
              <br />


              <p className="card-text" style={{ color: "#fff" }}>
                {candidateProfile.description}
              </p>
              <div className="skills items-center gap-1 mt-10">
                <strong style={{ color: "#fff" }}>Skills:</strong>
                <br />
                {candidateProfile.keySkills &&
                  candidateProfile.keySkills
                    .split(",")
                    .map((skill, skillIndex) => (
                      <span key={skillIndex} className="skill-tag">
                        {skill.trim()}
                      </span>
                    ))}
              </div>
            </div>
          </div>
        )}

        {/* Interview Evaluations */}


        {(profiles && profiles.length > 0) ||
          (interviewEvaluations && interviewEvaluations.length > 0) ? (
          <div className="container" style={{ color: "#fff", width: "80%" }}>
            {" "}
            {/* {interviewEvaluations &&  interviewEvaluations.length >= 0 && 
          interviewEvaluations.map((evaluation, index) => ( */}
            {/*  */}
            <div className="card" style={{ marginTop: "50px" }}>
              <div className="card-body">
                {/*  */}
                {/* <div className="profile-header">
                  <img
                    src={photoURL}
                    alt="sachin"
                    className="profile-img"
                  />
                  <div>
                    <h5 className="card-title" style={{ color: "#fff" }}>
                      
                     Name : {fullName}
                    </h5>
                    <strong style={{ color: "#fff" }}>
                      {" "}
                    
                      Work Status : {workStatus}
                    </strong>
                    <br />
                  </div>
                </div>
                <br />
                <p className="card-text" style={{ color: "#fff" }}>
                Description : {description}
                </p>
                <div className="skillss">
                  
                  Skill :
                  {keySkills &&
                    keySkills
                      .split(",")
                      .map((skill, skillIndex) => (
                        <span key={skillIndex} className="skill-tag">
                          {skill.trim()}
                        </span>
                      ))}


                </div> */}

                {
                  privateCandidate !== true ? (
                    <>
                      {profiles.map((profile, index) => (
                        <div key={index}>
                          <h5
                            className="card-title"
                            style={{ color: "#fff", marginTop: "40px" }}
                          >
                            Interview Video ++
                          </h5>
                          <video
                            controls
                            style={{ width: "100%", border: "2px solid #ccc" }}
                            controlsList="nodownload" // Add controlsList attribute here
                          >
                            <source src={profile.candidateVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                          <div className="transcript-container">
                            <CustomizedResumeText
                              candidateContextTextFile={
                                profile.candidateContextTextFile
                              }
                            />
                          </div>
                          <div className="transcript-container">
                            <CustomizedResumeRating
                              candidateContextTextFile={
                                profile.candidateContextTextFile
                              }
                            />
                          </div>
                        </div>
                      ))}

                      {/*  */}
                      {interviewEvaluations.map((evaluation, index) => (
                        <div key={index}>
                          <h5
                            className="card-title"
                            style={{ color: "#fff", marginTop: "40px" }}
                          >
                            Interview Video
                          </h5>
                          {/* <video
                    controls
                    style={{ width: "100%", border: "2px solid #ccc" }}
                  >
                    <source src={evaluation.videoURL} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video> */}
                          {evaluation.videoURL ? (
                            <video
                              controls
                              style={{ width: "100%", border: "2px solid #ccc" }}
                              controlsList="nodownload" // Add controlsList attribute here
                            >
                              <source src={evaluation.videoURL} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <p>No video available</p>
                          )}
                          <h5
                            className="card-title"
                            style={{ color: "#fff", margin: "1rem" }}
                          >
                            Interview Transcript
                          </h5>
                          <div className="transcript-container">
                            {evaluation.transcript &&
                              JSON.parse(evaluation.transcript).map((item, idx) => (
                                <div key={idx}>
                                  <p>
                                    <strong>{item.speaker}:</strong> {item.message}
                                  </p>
                                </div>
                              ))}
                          </div>
                          <h5
                            className="card-title"
                            style={{ color: "#fff", margin: "1rem" }}
                          >
                            Interview Evaluation
                          </h5>
                          <div className="transcript-container">
                            {evaluation.evaluation &&
                              Object.entries(JSON.parse(evaluation.evaluation)).map(
                                ([criterion, data], idx) => (
                                  <div key={idx}>
                                    <p>
                                      <strong>{criterion}:</strong>{" "}
                                      {data.score ? data.score : "N/A"} /{" "}
                                      {data.max_score ? data.max_score : "N/A"}
                                    </p>
                                    <p>
                                      <strong>Comments:</strong>{" "}
                                      {data.comments ? data.comments : "N/A"}
                                    </p>
                                  </div>
                                )
                              )}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div>
                      <h1 className="text-center text-red-700">Private Candidate</h1>
                    </div>
                  )
                }


              </div>
            </div>
            {/*  */}
            {/* ))} */}
          </div>
        ) : (
          <div>
            <h1 className="text-center text-red-700">Not yet Screened</h1>
          </div>
        )}
      </div>
    </div>
  );
};

// Component to fetch and display candidate resume text and rating
const CustomizedResumeText = ({ candidateContextTextFile }) => {
  const [resumeText, setResumeText] = useState("");
  const [filteredResumeText, setFilteredResumeText] = useState("");
  const [candidateRating, setCandidateRating] = useState("");

  useEffect(() => {
    const fetchResumeText = async () => {
      try {
        const response = await fetch(candidateContextTextFile);
        const text = await response.text();
        setResumeText(text);

        // Extracting required sections from the resume text
        const start = text.indexOf("Full Interview TranScript:");
        const end =
          text.indexOf(
            "******************************************Candidate Reting:***"
          ) || text.length; // Set end to text length if rating section not found
        const filteredText = text.substring(start, end);
        setFilteredResumeText(filteredText);

        // Extracting candidate rating
        const ratingStart = text.indexOf(
          "******************************************Candidate Reting:***"
        );
        if (ratingStart !== -1) {
          const filteredText = text.substring(ratingStart);
          setCandidateRating(filteredText);
        }
      } catch (error) {
        console.error("Error fetching resume text:", error);
      }
    };

    fetchResumeText();
  }, [candidateContextTextFile]);

  return (
    <>
      <div>
        <h5 style={{ color: "#9900ff" }}>TranScript</h5>
        <pre>{filteredResumeText}</pre>
      </div>
    </>
  );
};

// Component to fetch and display candidate resume text and rating
const CustomizedResumeRating = ({ candidateContextTextFile }) => {
  const [resumeText, setResumeText] = useState("");
  const [filteredResumeText, setFilteredResumeText] = useState("");
  const [candidateRating, setCandidateRating] = useState("");

  useEffect(() => {
    const fetchResumeText = async () => {
      try {
        const response = await fetch(candidateContextTextFile);
        const text = await response.text();
        setResumeText(text);

        // Extracting required sections from the resume text
        const start = text.indexOf("Full Interview TranScript:");
        const end =
          text.indexOf(
            "******************************************Candidate Reting:***"
          ) || text.length; // Set end to text length if rating section not found
        const filteredText = text.substring(start, end);
        setFilteredResumeText(filteredText);

        // Extracting candidate rating
        const ratingStart = text.indexOf(
          "******************************************Candidate Reting:***"
        );
        if (ratingStart !== -1) {
          const filteredText = text.substring(ratingStart);
          setCandidateRating(filteredText);
        }
      } catch (error) {
        console.error("Error fetching resume text:", error);
      }
    };

    fetchResumeText();
  }, [candidateContextTextFile]);

  return (
    <>
      <div>
        <h5 style={{ color: "#9900ff" }}>Evaluation</h5>
        <pre>{candidateRating}</pre>
      </div>
    </>
  );
};
export default ShowMySkills;
