import React, { useContext, useState } from "react";
import { API, Storage, graphqlOperation } from "aws-amplify";
import Edit from "../../assets/hugeicons_mail-edit-01.svg"; // Import the SVG image
import Send from "../../assets/iconoir_send.svg"; // Import the SVG image
import close from "../../assets/carbon_close-outline.svg"; // Import the SVG image
import { SpotContext } from "../../context/SpotContext";
import { createSpotCandidateStatus, updateSpotJD, updateSpotResume } from "../../graphql/mutations";

function SpotPlusSendMailPopup({ onClose,onTargetActive }) {

  // Store code start

  const {
    spotprocessSelectedJD,
    spotprocessSelectedResume,
    spotprocessAiJDSlcandidates,
    spotprocessAiResumeSlCandidates,
    spotprocessAiJDSselectedCandidates,
    spotprocessAiResumeselectedCandidates

  } = useContext(SpotContext);

  // Store code end

  console.log(1, spotprocessSelectedJD);
  console.log(2, spotprocessSelectedResume);
  console.log(3, spotprocessAiJDSlcandidates);
  console.log(4, spotprocessAiResumeSlCandidates);
  console.log(5, spotprocessAiJDSselectedCandidates);
  console.log(6, spotprocessAiResumeselectedCandidates);


  const [isEditable, setIsEditable] = useState(false);
  // Define your email template with placeholders

  const emailTemplate = `Subject: Congratulations! You’ve Been Shortlisted for the AI Interview

Dear [Candidate's Name],

I hope this email finds you well.

We are pleased to inform you that you have been shortlisted for the next stage of the interview process for the AI Engineer role at TechCorp.

After reviewing your application and qualifications, we believe that your skills and experiences align well with what we are looking for in this role.

Interview Details:

Date: January 10, 2025
Interview Type: Virtual
Location/Platform: Zoom link: https://zoom.us/j/123456789
The interview will primarily focus on your knowledge and expertise in artificial intelligence, as well as how your experiences can contribute to TechCorp. It will also provide an opportunity for you to share your thoughts on how AI can shape the future at TechCorp.

Please confirm your availability for the interview by replying to this email at your earliest convenience.

Should you have any questions or need any additional information prior to the interview, feel free to reach out to us.

We are excited to learn more about you and discuss how you can be a great addition to our team.

Best regards,

[Your Name]
`;

  // const emailTemplate = `
  //        Subject: Congratulations! You’ve Been Shortlisted for the AI Interview

  //     Dear [Candidate's Name],

  //     After reviewing your application and qualifications, we believe that your skills and experiences align well with what we are looking for in this role.

  //     Interview Details:
  //     - Date: January 10, 2025
  //     - Interview Type: Virtual
  //     - Location/Platform: Zoom link: https://zoom.us/j/123456789

  //     Please confirm your availability for the interview by replying to this email at your earliest convenience.

  //     Best regards,
  //     [Your Name]
  //     `;

  // Set the email template as the initial content
  const [content, setContent] = useState(emailTemplate);

  const handleEditClick = () => {
    setIsEditable(true);
  };

  // const handleSaveClick = () => {
  //   setIsEditable(false);
  // };

  // const convertToHTML = (text) => {
  //   // Convert line breaks to <br /> for email formatting
  //   let htmlContent = text.replace(/\n/g, "<br />");

  //   // Replace subject line and other placeholders with proper HTML
  //   htmlContent = htmlContent.replace(/Subject: /g, "<p><strong>Subject:</strong> ");
  //   htmlContent = htmlContent.replace(/Dear /g, "<p>Dear ");
  //   htmlContent = htmlContent.replace(/Best regards,/g, "<p><strong>Best regards,</strong>");

  //   // Replace each line with <p> tags for proper email formatting
  //   htmlContent = htmlContent.replace(/([^\n]+)/g, "<p>$1</p>");

  //   return htmlContent;
  // };

  const [formattedHTMLContent, setFormattedHTMLContent] = useState(content);

  const handleSaveClick = () => {
    setIsEditable(false);
    // When saving, convert plain text into HTML format
    // const htmlContent = convertToHTML(content);
    // setContent(htmlContent);


    const htmlContent = `
<html>
  <body>
    ${content
        .split("\n")
        .map((line) => {
          if (line.trim() === "") return ""; // Skip empty lines
          if (line.startsWith("-")) return `<li>${line.substring(1).trim()}</li>`; // Bullet points
          return `<p>${line.trim()}</p>`; // Wrap other lines in <p>
        })
        .join("\n")}
  </body>
</html>
    `;

    setFormattedHTMLContent(htmlContent.trim());

  };

  // const handleSendMailClick = () => {
  //   alert("Mail is send...");
  //   onClose();
  // };

  //  sending Email code start


  // Dummy candidates data
  // const candidates = [
  //   {
  //     email: "john.doe@example.com",
  //     fullName: "John Doe1",
  //     comment: "John has excellent experience with machine learning and data analysis.",
  //     currentCity: "New York",
  //     description: "A seasoned AI engineer with a focus on deep learning.",
  //     fitScore: 85,
  //     keySkills: "Python, TensorFlow, Keras, Natural Language Processing",
  //     resumeURL: "https://example.com/resumes/john-doe",
  //   },
  //   {
  //     email: "jane.smith@example.com",
  //     fullName: "Jane Smith1",
  //     comment: "The candidate, Jane Smith, has a solid programming background with experience in front-end and back-end development. She is proficient in Python and has worked with AI frameworks like TensorFlow. However, her skills are limited to Scikit-learn for AI, which may not be sufficient for the deep learning, NLP, and generative AI requirements of this role. Hence, she is not an ideal fit for the AI Intern position at Varahi Technologies.",
  //     currentCity: "Not specified",
  //     description: "AI-driven software engineer with experience in front-end and back-end development, data analysis, and marketing coordination. Proficient in various programming languages and AI frameworks.",
  //     fitScore: 60,
  //     keySkills: "Front-end development: HTML, CSS, JavaScript, Back-end development: Node.js, Python, AI frameworks: TensorFlow, Scikit-learn",
  //     resumeURL: "https://example.com/resumes/jane-smith",
  //   },
  // ];

  const [candidates,setCandidates]=useState(spotprocessAiResumeselectedCandidates)

  // Mock API function to send emails
  const sendEmailAPI = async (candidate, subject, body) => {
    // try {
    //   const response = await fetch("https://mockapi.io/sendEmail", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ to: candidate.Email, subject, body }),
    //   });

    //   if (!response.ok) {
    //     throw new Error(`Failed to send email to ${email}`);
    //   }
    //   const result = await response.json();
    //   console.log(`Email sent successfully to ${email}:`, result);
    // } catch (error) {
    //   console.error(`Error sending email to ${email}:`, error.message);
    // }

    
          const input = {
            companyEmail: spotprocessSelectedJD.companyEmail, // Only email
            spotResumeID:spotprocessSelectedResume.id,
            spotJdID:spotprocessSelectedJD.id,
            candidateEmail: candidate.email,
            roundStatus:"firstRound",
            isEmailsend:"",
            candidateFrom:"Resume",
            selectionFlag:""
          };
    
    
          const response = await API.graphql(graphqlOperation(createSpotCandidateStatus, { input }));
          console.log(response);
          onTargetActive()

  };

  const handleSendMailClick = async () => {

    if (
      !spotprocessSelectedJD || typeof spotprocessSelectedJD !== 'object' ||
      !spotprocessSelectedResume || typeof spotprocessSelectedResume !== 'object' ||
      !spotprocessAiJDSlcandidates || !Array.isArray(spotprocessAiJDSlcandidates) || spotprocessAiJDSlcandidates.length === 0 ||
      !spotprocessAiResumeSlCandidates || !Array.isArray(spotprocessAiResumeSlCandidates) || spotprocessAiResumeSlCandidates.length === 0 ||
      !spotprocessAiJDSselectedCandidates || !Array.isArray(spotprocessAiJDSselectedCandidates) || spotprocessAiJDSselectedCandidates.length === 0 ||
      !spotprocessAiResumeselectedCandidates || !Array.isArray(spotprocessAiResumeselectedCandidates) || spotprocessAiResumeselectedCandidates.length === 0
    ) {
      alert("Please complete the 'SPOT' process first before proceeding with sending emails."); // Alert the user
      return; // Exit the function
    }


    const updatespotJD = async () => {


      const selectedCandidateforSpot = JSON.stringify(spotprocessAiJDSselectedCandidates)
      const filterCandidateforSpot = JSON.stringify(spotprocessAiJDSlcandidates)


      const input = {
        // companyEmail: companyEmail,
        id: spotprocessSelectedJD.id,
        selectedCandidate: selectedCandidateforSpot,
        filterCandidate: filterCandidateforSpot,
      };
      const responseJD = await API.graphql(graphqlOperation(updateSpotJD, {
        input
      }));

      console.log(responseJD)

    }

    updatespotJD()

    const updateResume = async () => {

      const filterCandidateforResume = JSON.stringify(spotprocessAiResumeSlCandidates)
      const selectedCandidateforResume = JSON.stringify(spotprocessAiResumeselectedCandidates)


      const input = {
        // companyEmail: companyEmail,
        id: spotprocessSelectedResume.id,
        selectedCandidate: selectedCandidateforResume,
        filterCandidate: filterCandidateforResume
      };
      const responseResume = await API.graphql(graphqlOperation(updateSpotResume, {
        input
      }));

      console.log(responseResume)

    }

    updateResume()


    const subject = "Congratulations! You’ve Been Shortlisted for the AI Interview";

    for (const candidate of candidates) {
      const personalizedContent = formattedHTMLContent
        .replace("[Candidate's Name]", candidate.name)
        .replace("[Position Name]", "AI Engineer") // Example
        .replace("[Company Name]", "TechCorp") // Example
        .replace("[Insert Date]", "January 10, 2025") // Example
        .replace("[Virtual/In-person]", "Virtual") // Example
        .replace(
          "[Insert Platform Link or Physical Address]",
          "Zoom link: https://zoom.us/j/123456789" // Example
        );

      console.log("uuuuuuuuuuuuu", personalizedContent);


      await sendEmailAPI(candidate, subject, personalizedContent);
    }

    alert("Emails have been sent successfully!");
    // onClose();
  };



  //  sending Email code end

  return (
    <div style={styles.container}>
      <div style={styles.canvas}>
        <button onClick={onClose} >
          <img src={close} />
        </button>
        <div style={styles.editButtoncontainer}>
          <img onClick={handleEditClick} src={Edit} />

          <img onClick={handleSendMailClick} src={Send} />
        </div>
        <textarea
          style={{ ...styles.textarea, display: isEditable ? "block" : "none" }}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          readOnly={!isEditable}
        />

        {/* <p
          style={{
            // ...styles.textContent,
            ...styles.textarea,
            display: !isEditable ? "block" : "none",
          }}
        >
           {content} 
        </p> */}

        <p
          style={{
            ...styles.textarea,
            display: !isEditable ? "block" : "none",
          }}
          dangerouslySetInnerHTML={{
            // __html: content, // Render content as text
            __html: formattedHTMLContent, // Render content as text
          }}
        />

        <div style={styles.actionButtonContainer}>
          {/* Always show the Send Mail button */}
          {!isEditable && (
            <button style={styles.actionButton}
              //  onClick={handleSendMailClick}
              onClick={handleSendMailClick}
            >
              Send Mail
            </button>
          )}

          {isEditable && (
            <button style={styles.actionButton} onClick={handleSaveClick}>
              Save
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "center",
    // height: "100vh",
    height: "54vh",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    zIndex: "12",
    width: "100%",
  },
  canvas: {
    position: "relative",
    width: "80%",
    // maxWidth: "600px",
    // minHeight: "70vh",
    height: "70vh",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    background: "#ddc8ff",
    display: "grid",
    // marginTop: "8rem",
    overflowY: "scroll"
  },
  textarea: {
    width: "100%",
    minHeight: "100vh",
    fontSize: "16px",
    lineHeight: "1.5",
    padding: "10px",
    border: "none",
    outline: "none",
    resize: "none",
    fontFamily: "inherit",
    color: "#000",
    borderRadius: "8px",
  },
  textContent: {
    width: "100%",
    minHeight: "40vh",
    fontSize: "16px",
    lineHeight: "1.5",
    color: "#333",
    padding: "3% 1%",
  },
  editButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "transparent",
    border: "none",
    fontSize: "18px",
    cursor: "pointer",
  },
  actionButton: {
    // position: "absolute",
    bottom: "10px",
    left: "10px",
    padding: "8px 16px",
    fontSize: "14px",
    color: "#fff",
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    display: "grid",
  },
  editButtoncontainer: {
    display: "flex",
    border: "1px solid #243642",
    background: "#ffffffad",
    width: "fit-content",
    height: "38px",
    padding: "4px 8px",
    gap: "16px",
    borderRadius: "4px",
    justifySelf: "end",
  },
  actionButtonContainer: {
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
  },
};
export default SpotPlusSendMailPopup;
