import React, { useState, useRef, useContext } from "react";
import checkbox from "../../assets/checkbox.svg"; // Import the SVG image
import select from "../../assets/checkbox-select.svg"; // Import the SVG image
import { SpotContext } from "../../context/SpotContext";

// Inline CSS with improved media queries for small screens
const candidatesData = [
  {
    id: 1,
    name: "Sachin Gadekar",
    fitScore: "65/100",
    experience: "5 Years of Experience",
    position: "Remote",
    description:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in 45 BC, making it over 2000 years old.",
    video: "your-video-source1.mp4",
    skills: ["HTML", "CSS", "JS", "Artificial Intelligence"],
    isChecked: false,
    isSelected: false,
  },
  {
    id: 2,
    name: "Priya Sharma",
    fitScore: "78/100",
    experience: "3 Years of Experience",
    position: "Onsite",
    description:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    video: "your-video-source2.mp4",
    skills: ["Python", "React", "Node.js"],
    isChecked: false,
    isSelected: false,
  },
  {
    id: 3,
    name: "Rohit Mehta",
    fitScore: "85/100",
    experience: "7 Years of Experience",
    position: "Hybrid",
    description:
      "It has survived not only five centuries but also the leap into electronic typesetting.",
    video: "your-video-source3.mp4",
    skills: ["Java", "Spring Boot", "Microservices"],
    isChecked: false,
    isSelected: false,
  },
  {
    id: 4,
    name: "Anjali Verma",
    fitScore: "72/100",
    experience: "4 Years of Experience",
    position: "Remote",
    description:
      "Anjali specializes in building responsive, high-performing web applications.",
    video: "your-video-source4.mp4",
    skills: ["React", "Redux", "TypeScript", "Node.js"],
    isChecked: false,
    isSelected: false,
  },
  {
    id: 5,
    name: "Vikram Singh",
    fitScore: "80/100",
    experience: "6 Years of Experience",
    position: "Onsite",
    description:
      "Vikram has a strong background in cloud solutions and scalable architecture.",
    video: "your-video-source5.mp4",
    skills: ["AWS", "Docker", "Kubernetes", "Python"],
    isChecked: false,
    isSelected: false,
  },
  {
    id: 6,
    name: "Meera Nair",
    fitScore: "90/100",
    experience: "8 Years of Experience",
    position: "Hybrid",
    description:
      "Meera is a seasoned AI/ML engineer with experience in NLP and computer vision.",
    video: "your-video-source6.mp4",
    skills: ["AI", "Machine Learning", "TensorFlow", "Python"],
    isChecked: false,
    isSelected: false,
  },
];

const styles = {
  container: {
    justifyContent: "center",
    alignItems: "center",
    // minHeight: "100vh",
    // backgroundColor: "#F5F5F5",
    padding: "10px",
    width: "100%",
    gap: "10px",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(400px, 3fr))",
  },
  svgContainer: {
    position: "relative",
    width: "100%",
    maxWidth: "400px", // Limit card width on large screens
    aspectRatio: "374 / 358", // Maintain SVG aspect ratio
  },
  cardContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
    width: "90%",
    color: "#333",
    // textAlign: "center",
  },
  title: {
    fontSize: "22px",
    fontWeight: "500",
    margin: "0 0 0 0",
  },
  subtitle: {
    fontSize: "1rem",
    color: "#30313A",
    margin: "0",
  },
  interviewvideo: {
    background: "#000",
    width: "inherit",
    margin: "10px 0px",
    height: "16vh",
  },
  description: {
    fontSize: "0.9rem",
    color: "#555",
    margin: "10px 10px",
  },
  skillsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "6px",
    marginBottom: "10px",
  },
  skill: {
    backgroundColor: "#E7E0FF",
    padding: "6px 10px",
    borderRadius: "8px",
    fontSize: "0.85rem",
    color: "#333",
  },
  button: {
    marginTop: "16px",
    padding: "12px",
    backgroundColor: "#6A0DAD",
    color: "white",
    border: "none",
    borderRadius: "10px",
    cursor: "pointer",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.3s",
  },
  buttonHover: {
    backgroundColor: "#5B0CA2",
  },
  checkbox: {
    marginLeft: "8px",
    backgroundColor: "transparent", // Corrected typo
    border: "1px solid #fff", // Added border property
    borderRadius: "4px",
  },
  fitScore: {
    backgroundColor: "#E0E7FF",
    color: "#333",
    padding: "5px 10px",
    borderRadius: "4px",
    fontSize: "0.85rem",
    marginLeft: "10px",
    width: "72%",
    textAlign: "center",
  },
  titleDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    marginRight: "76px",
  },
  Score: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    padding: "4px 8px",
    borderRadius: "2px",
    padding: "3px 8px",
    color: "#FFFFFF",
  },
  position: {
    background:
      "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
    WebkitBackgroundClip: "text", // Camel case for -webkit-background-clip
    backgroundClip: "text",
    color: "transparent",
    padding: "0px 8px",
  },
  textWrapper: {
    color: "#FBFBFB",
    fontFamily: '"Roboto-Regular", Helvetica',
    fontSize: "22px",
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: "normal",
    position: "relative",
    whiteSpace: "nowrap",
    width: "fit-content",
  },
  descriptionContainer: {
    width: "100%",
    height: "auto",
    margin: "10px 10px",
    overflow: "hidden", // Hide content that overflows
  },
  description: {
    fontSize: "0.9rem",
    color: "#555",
    // transition: "opacity 0.3s ease, transform 0.3s ease",
  },
  interviewvideo: {
    background: "#000",
    width: "inherit",
    height: "16vh",
    // transition: "opacity 0.3s ease, transform 0.3s ease",
    // opacity: 0,
    // transform: "translateY(100%)",
  },
  // Media Queries for better responsiveness
  "@media (max-width: 768px)": {
    svgContainer: {
      maxWidth: "300px",
    },
    title: {
      fontSize: "20px",
    },
    subtitle: {
      fontSize: "0.9rem",
    },
    description: {
      fontSize: "0.85rem",
    },
    skill: {
      fontSize: "0.8rem",
      padding: "5px 8px",
    },
  },
  "@media (max-width: 480px)": {
    svgContainer: {
      maxWidth: "100%",
    },
    title: {
      fontSize: "18px",
    },
    subtitle: {
      fontSize: "0.85rem",
    },
    description: {
      fontSize: "0.75rem",
    },
    button: {
      padding: "10px",
    },
    titleDiv: {
      flexDirection: "column",
      marginRight: "0",
      gap: "4px",
    },
    fitScore: {
      marginLeft: "0",
      marginTop: "5px",
    },
  },
  "@media (max-width: 400px) to  @media (max-width: 349px)": {
    svgContainer: {
      maxWidth: "90%",
      aspectRatio: "1 / 1",
    },
    title: {
      fontSize: "16px",
    },
    description: {
      fontSize: "0.75rem",
    },
    button: {
      padding: "8px",
    },
  },
  "@media (max-width: 350px)": {
    svgContainer: {
      maxWidth: "95%",
      aspectRatio: "1 / 1",
    },
    title: {
      fontSize: "14px", // Adjusted for extra-small screens
    },
    subtitle: {
      fontSize: "0.75rem",
    },
    description: {
      fontSize: "0.7rem",
    },
    skill: {
      fontSize: "0.75rem",
      padding: "4px 6px",
    },
    button: {
      padding: "6px", // Reduced padding for smaller screens
    },
  },
};
function SpotPlusTargetResume({
  card,
  isSelected,
  onSelect,
  onVideoClick,
}) {
  const [hover, setHover] = React.useState(false);
  const videoRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const handleHover = (index) => setHoveredIndex(index);
  const handleLeave = () => setHoveredIndex(null);
  const handleSelect = (index) => setSelectedIndex(index);


    // store code start
  
    const {
      spotprocessAiResumeselectedCandidates,
      setSpotprocessAiResumeselectedCandidates
    } = useContext(SpotContext);

    const [candidatefromstore, setCandidatefromstore] = useState(spotprocessAiResumeselectedCandidates);
  
    // store code end


  const handleCheckboxClick = (candidate) => {
    setIsChecked(!isChecked);

    const updatedSelectedIds = candidatefromstore.filter((user) => user.id !== candidate.id);

    // Update local state
    setCandidatefromstore(updatedSelectedIds);
    setSpotprocessAiResumeselectedCandidates(updatedSelectedIds);


  };
  const handleCardClick = () => {
    onSelect(card.id);
  };
  const handleVideoClick = (event) => {
    event.stopPropagation(); // Prevent the card click event from firing
    if (onVideoClick) {
      onVideoClick(card.id); // Or any other relevant data
    }
    //onSelect(card.id);
  };
  const handleMouseEnter = () => {
    setHover(true);
    if (videoRef.current) {
      videoRef.current.style.opacity = 1;
      videoRef.current.style.transform = "translateY(0)";
      // Ensure video takes up space even if paragraph is removed
      videoRef.current.style.height = "auto";
      videoRef.current.style.visibility = "visible";
    }
  };
  const handleMouseLeave = () => {
    setHover(false);
    if (videoRef.current) {
      videoRef.current.style.opacity = 0;
      videoRef.current.style.transform = "translateY(100%)";
      // Collapse video height for smooth transition
      videoRef.current.style.height = "0px";
      videoRef.current.style.visibility = "hidden";
    }
  };


  console.log("candidatefromstore", candidatefromstore);
  console.log("spotprocessAiResumeselectedCandidates", spotprocessAiResumeselectedCandidates);  

  return (
    // <div
    //   style={isSelected ? styles.selectedCard : styles.card}
    //   onClick={() => onSelect(card.id)} // This is correct!
    // >
    <div style={styles.container}>
      {candidatefromstore.map((candidate) => (
        <div key={candidate.id} style={styles.svgContainer}>
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 374 358"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid slice"
          >
            <path
              d="M367.161 42.451C367.161 61.1313 352.018 76.2747 333.338 76.2747C314.657 76.2747 299.514 61.1313 299.514 42.451C299.514 23.7707 314.657 8.62737 333.338 8.62737C352.018 8.62737 367.161 23.7707 367.161 42.451Z"
              fill="url(#pattern0)"
              stroke="white"
              strokeWidth="0.856"
            />
            <path
              d="M0 28.0139C0 12.648 12.4565 0.191406 27.8224 0.191406H260.742C271.269 0.191406 295.422 15.1059 295.422 47.9588C295.422 58.0381 305.698 86.9615 338.665 81.2644C352.654 78.847 375.486 93.0967 373.773 112.817V329.709C373.773 345.075 361.316 357.531 345.951 357.531H27.8224C12.4565 357.531 0 345.075 0 329.709V28.0139Z"
              fill="#DDC8FF"
            />
            <defs>
              <pattern id="pattern0" width="1" height="1">
                <use xlinkHref="#image0" transform="scale(0.00390625)" />
              </pattern>
              <img
                id="image0"
                width="256"
                height="256"
                href="https://upload.wikimedia.org/wikipedia/commons/4/47/PNG_transparency_demonstration_1.png"
              />
            </defs>
          </svg>
          <div style={styles.cardContent}>
            <div>
              <div style={styles.titleDiv}>
                <h2 style={styles.title}>{candidate.name}</h2>
                <div style={styles.fitScore}>
                  Fit Score:{" "}
                  <span style={styles.Score}>{candidate.fitScore}</span>{" "}
                </div>
              </div>
            </div>
            <div
              style={styles.descriptionContainer}
              onMouseLeave={handleMouseLeave}
            >
              {/* Conditionally render the paragraph or video */}
              <p style={styles.description} onMouseEnter={handleMouseEnter}>
                {candidate.comment.split(' ').slice(0, 30).join(' ')}{candidate.comment.split(' ').length > 30 ? '...' : ''}
              </p>
            </div>
            <div style={styles.skillsContainer}>
              {candidate.keySkills.split(',').slice(0, 4).map((skill, i) => (
                <span key={i} style={styles.skill}>
                  {skill.trim()}
                </span>
              ))}
            </div>
            {/* <button
              style={
                hover
                  ? { ...styles.button, ...styles.buttonHover }
                  : styles.button
              }

              onClick={() => handleCheckboxClick(candidate)} 
            >
              <div className="text-wrapper">Select Candidate</div>
              <div
                style={isSelected ? styles.selectedCard : styles.card}
                onClick={handleCardClick}
              >
              </div>
              {isChecked ? (
                <img
                  className="fluent-select-all-on"
                  alt="Fluent select all on"
                  src={select}
                />
              ) : (
                <img
                  className="fluent-select-all-on"
                  alt="Fluent select all on"
                  src={checkbox}
                />
              )}
            </button> */}
          </div>
        </div>
      ))}
    </div>
  );
}
export default SpotPlusTargetResume;
